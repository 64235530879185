import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  Link,
  Grid,
  Divider,
  Snackbar,
  Chip,
} from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useDispatch, useSelector } from 'react-redux';
import { StoreRootState } from '../../../stores/stores';
import { convertCreditsToUserText } from '../../../utils/creditUtils';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import FlareIcon from '@mui/icons-material/Flare';
import {
  sendGetRequest,
  sendPostRequest,
} from '../../../requests/sendRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import BillingStatusCard from '../BillingStatusCard';
import { updateRemainingCredits } from '../../../stores/creditsSlice';
import { APPS_TAB_PATH, TERMS_OF_SERVICE_URL } from '../../../data/pageLinks';
import {
  logClientError,
  useLogUserEvent,
} from '../../../logging/useLogUserEvent';
import { UserEventTypes } from '../../../logging/UserEventTypes';
import CreditsAssignmentWarningCard from '../CreditsAssignmentWarningCard';
import PricingBox from '../../pricing/PricingBox';
import { SubscriptionType } from '../../../types/SubscriptionType';
import SubscriptionStatusCard from '../SubscriptionStatusCard';

const BillingOverviewTab = React.memo(() => {
  const isLoadingCredits = useSelector(
    (state: StoreRootState) => !state.credits.creditsLoaded,
  );
  const remainingCredits = useSelector(
    (state: StoreRootState) => state.credits.remainingCredits,
  );
  const subscriptionPrices = useSelector(
    (state: StoreRootState) => state.product.subscriptionPrices,
  );
  const activeSubscriptions = useSelector(
    (state: StoreRootState) => state.subscription.aciveSubscriptions,
  );
  const subscriptionPortalUrl = useSelector(
    (state: StoreRootState) => state.subscription.subscriptionPortalUrl,
  );

  const freeVoiceClones = useSelector(
    (state: StoreRootState) => state.user.freeVoiceClonesRemaining,
  );

  const isSpeechToTextSubscriptionActive = useMemo(() => {
    return activeSubscriptions[SubscriptionType.UNLIMITED_SPEECH_TO_TEXT]
      ? true
      : false;
  }, [activeSubscriptions]);

  const isVoiceCloneAndTextToSpeechSubscriptionActive = useMemo(() => {
    return activeSubscriptions[
      SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
    ]
      ? true
      : false;
  }, [activeSubscriptions]);

  const navigate = useNavigate();

  const searchQueries = useLocation().search;
  const billingStatus = useMemo(
    () => new URLSearchParams(searchQueries).get('billingStatus'),
    [searchQueries],
  );
  const subscriptionStatus = useMemo(
    () => new URLSearchParams(searchQueries).get('subscriptionStatus'),
    [searchQueries],
  );

  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState<
    'success' | 'warning' | 'danger'
  >('danger');

  const showSnackMessage = useCallback((message: string, isError: boolean) => {
    setSnackBarContent(message);
    setSnackbarOpen(true);
    setSnackBarColor(isError ? 'danger' : 'success');
  }, []);

  const logUserEvent = useLogUserEvent();

  // const [isSpecialUser, setIsSpecialUser] = useState(false);

  // const checkSpecialUser = useCallback(async () => {
  //   const { username, userId } = await getCurrentUser();

  //   if (!userId && !username) {
  //     return;
  //   }

  //   setIsSpecialUser(
  //     userId === '78c13340-e0e1-7035-6bd5-a013846140f1' ||
  //       username === '78c13340-e0e1-7035-6bd5-a013846140f1',
  //   );
  // }, []);

  // useEffect(() => {
  //   checkSpecialUser();
  // }, [checkSpecialUser]);

  // If user has successfully added credits, refresh the credits
  const dispatch = useDispatch();
  useEffect(() => {
    if (billingStatus === 'success' || subscriptionStatus === 'success') {
      const queryRemainingCredits = async () => {
        try {
          const response = await sendGetRequest({
            requestPath: '/user/credits',
            queryParams: {},
          });

          const { remainingCredits } = response as { remainingCredits: number };
          dispatch(updateRemainingCredits(remainingCredits));
        } catch (e: any) {
          logClientError(
            'Failed to load remaining credits in BillingOverviewTab: ',
            e,
          );
        }
      };

      queryRemainingCredits();
    }
  }, [billingStatus, dispatch, subscriptionStatus]);

  const createAndOpenCheckoutSession = useCallback(
    async (amount: number) => {
      logUserEvent(
        {
          event: UserEventTypes.ADD_CREDITS_SESSION_START,
          amountToAdd: amount,
        },
        {
          logImmediately: true,
          logBrowerserFingerPrint: true,
          logUserLocation: true,
        },
      );

      const response = await sendPostRequest({
        requestPath: '/payment/checkout_session',
        payload: {
          amount,
        },
      });

      const { checkout_session_url } = response as {
        checkout_session_url: string;
      };

      window.location.href = checkout_session_url;

      // requestCreated = response as SpeechToTextEntry;
    },
    [logUserEvent],
  );

  const createAndOpenSubscriptionSession = useCallback(
    async (subscription: SubscriptionType) => {
      logUserEvent(
        {
          event: UserEventTypes.SUBSCRIPTION_SESSION_START,
          subscription,
        },
        {
          logImmediately: true,
          logBrowerserFingerPrint: true,
          logUserLocation: true,
        },
      );

      const response = await sendPostRequest({
        requestPath: '/payment/subscription_checkout_session',
        payload: {
          subscription,
        },
      });

      const { checkout_session_url, already_exist } = response as {
        checkout_session_url: string;
        already_exist: boolean;
      };

      if (already_exist) {
        showSnackMessage(
          'You already subscribed to this plan. Please refresh the page to reflect the latest status.',
          true, // isError
        );
        return;
      }

      window.location.href = checkout_session_url;

      // requestCreated = response as SpeechToTextEntry;
    },
    [logUserEvent, showSnackMessage],
  );

  const openSubscriptionMgmtPage = useCallback(
    (subscription: SubscriptionType) => {
      logUserEvent({
        event: UserEventTypes.SUBSCRIPTION_OPEN_MGMT_PORTAL,
        subscription,
      });

      window.location.href = subscriptionPortalUrl;
    },
    [logUserEvent, subscriptionPortalUrl],
  );

  const subscriptionBoxMinHeights = useMemo(() => {
    return {
      xs: 100,
      sm: 120,
      md: 180,
      lg: 150,
    };
  }, []);

  return (
    <Box>
      {billingStatus && <BillingStatusCard billingStatus={billingStatus} />}

      {subscriptionStatus && (
        <SubscriptionStatusCard subscriptionStatus={subscriptionStatus} />
      )}

      <CreditsAssignmentWarningCard />

      {freeVoiceClones > 0 && (
        <Box
          onClick={() => {
            navigate(APPS_TAB_PATH.VOICE_CLONE);
          }}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              cursor: 'pointer', // You can change this to other cursor types
            },
          }}
        >
          <Chip
            variant='soft'
            color='success'
            startDecorator={<FlareIcon sx={{ width: 24, height: 24 }} />}
            sx={{
              fontSize: 16,
              backgroundColor: '#FF6500',
              color: 'white',
              marginY: 2,
              paddingX: 3,
              paddingY: 0.5,
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
          >
            {freeVoiceClones} Free Voice Clones
          </Chip>
        </Box>
      )}

      <Box component={'section'}>
        <Stack
          marginTop={3}
          gap={1}
        >
          <Typography level='h4'>Pay as you go</Typography>
          <Typography
            level='title-md'
            sx={{ fontWeight: 'bold', marginTop: 2 }}
          >
            Credit balance
          </Typography>
          <Stack
            direction='row'
            sx={{ gap: 1 }}
            alignItems={'center'}
          >
            <MonetizationOnIcon color='success' />
            {isLoadingCredits ? (
              <CircularProgress />
            ) : (
              <Typography
                level='h2'
                color='neutral'
                sx={{ fontWeight: 'bold' }}
              >
                {convertCreditsToUserText(remainingCredits, 2)}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack>
          <Typography
            level='title-md'
            sx={{ fontWeight: 'bold', marginTop: 4 }}
          >
            Add Credits
          </Typography>
          <Box
            flexDirection={'row'}
            display={'flex'}
            flexWrap={'wrap'}
            marginTop={2}
            gap={2}
            alignItems={'center'}
          >
            {/* {isSpecialUser && (
              <Button
                startDecorator={<AddCardOutlinedIcon />}
                variant='solid'
                color='success'
                onClick={() => createAndOpenCheckoutSession(0.5)}
              >
                $0.50
              </Button>
            )} */}
            <Button
              startDecorator={<AddCardOutlinedIcon />}
              variant='solid'
              color='success'
              onClick={() => createAndOpenCheckoutSession(5)}
            >
              $5
            </Button>
            <Button
              startDecorator={<AddCardOutlinedIcon />}
              variant='solid'
              color='success'
              onClick={() => createAndOpenCheckoutSession(10)}
            >
              $10
            </Button>
            <Button
              startDecorator={<AddCardOutlinedIcon />}
              variant='solid'
              color='success'
              onClick={() => createAndOpenCheckoutSession(20)}
            >
              $20
            </Button>
            <Button
              startDecorator={<AddCardOutlinedIcon />}
              variant='solid'
              color='success'
              onClick={() => createAndOpenCheckoutSession(50)}
            >
              $50
            </Button>
          </Box>
          <Typography
            level='body-sm'
            sx={{ marginTop: 2 }}
          >
            Select an amount above to add credits to your account. Payments are
            processed securely through Stripe. Please note that credits are
            non-refundable. For more information, refer to our{' '}
            <Link
              href={TERMS_OF_SERVICE_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              terms of service
            </Link>
            .
          </Typography>
        </Stack>
      </Box>

      <Link
        component='button'
        onClick={() => navigate(APPS_TAB_PATH.PRICING + '#faq')}
        marginTop={1}
      >
        <Typography
          level='body-sm'
          color='primary'
        >
          Frequently Asked Questions
        </Typography>
      </Link>

      <Divider sx={{ marginY: 4, border: 1.2, borderColor: 'divider' }} />

      <Box
        component='section'
        width={'100%'}
        marginTop={5}
      >
        <Typography level='h4'>Subscriptions</Typography>
        <Typography
          level='title-md'
          color='neutral'
          sx={{ marginTop: 2 }}
        >
          For better cost efficiency beyond pay-as-you-go, choose our
          subscription plans. You can subscribe to multiple plans, and we will
          use your remaining credits first before charging your card.
        </Typography>
        <Grid
          container
          spacing={6}
          marginTop={1}
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Grid
            sm={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <PricingBox
              featureName='Speech to Text'
              ctaTitle={
                isSpeechToTextSubscriptionActive
                  ? 'Manage Subscription'
                  : 'Subscribe'
              }
              subscribed={isSpeechToTextSubscriptionActive}
              backgroundColor='#f1e7dd'
              subscriptionPrice={`${convertCreditsToUserText(
                subscriptionPrices[SubscriptionType.UNLIMITED_SPEECH_TO_TEXT]?.[
                  'credits'
                ] ?? 0,
                2,
              )}`}
              descriptionLines={
                isSpeechToTextSubscriptionActive
                  ? [
                      `${Math.floor(
                        (subscriptionPrices[
                          SubscriptionType.UNLIMITED_SPEECH_TO_TEXT
                        ]?.['speechToTextMinutesLimit'] ?? 0) -
                          (activeSubscriptions[
                            SubscriptionType.UNLIMITED_SPEECH_TO_TEXT
                          ]?.['speechToTextMinutesRemaining'] ?? 0),
                      ).toLocaleString('en-US')} / ${Math.floor(
                        subscriptionPrices[
                          SubscriptionType.UNLIMITED_SPEECH_TO_TEXT
                        ]?.['speechToTextMinutesLimit'] ?? 0,
                      ).toLocaleString('en-US')} minutes used`,
                    ]
                  : [
                      `${Math.floor(
                        (subscriptionPrices[
                          SubscriptionType.UNLIMITED_SPEECH_TO_TEXT
                        ]?.['speechToTextMinutesLimit'] ?? 0) / 60,
                      ).toLocaleString(
                        'en-US',
                      )} hours audio to transcribe per month`,
                    ]
              }
              minHeight={subscriptionBoxMinHeights}
              onCTAClick={() => {
                isSpeechToTextSubscriptionActive
                  ? openSubscriptionMgmtPage(
                      SubscriptionType.UNLIMITED_SPEECH_TO_TEXT,
                    )
                  : createAndOpenSubscriptionSession(
                      SubscriptionType.UNLIMITED_SPEECH_TO_TEXT,
                    );
              }}
            />
          </Grid>
          <Grid
            sm={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <PricingBox
              featureName='Voice Cloning and Text to Speech'
              ctaTitle={
                isVoiceCloneAndTextToSpeechSubscriptionActive
                  ? 'Manage Subscription'
                  : 'Subscribe'
              }
              subscribed={isVoiceCloneAndTextToSpeechSubscriptionActive}
              backgroundColor='#cfe1b9'
              subscriptionPrice={`${convertCreditsToUserText(
                subscriptionPrices[
                  SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                ]?.['credits'] ?? 0,
                2,
              )}`}
              descriptionLines={
                isVoiceCloneAndTextToSpeechSubscriptionActive
                  ? [
                      `${Math.floor(
                        (subscriptionPrices[
                          SubscriptionType
                            .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                        ]?.['voiceCloneLimit'] ?? 0) -
                          (activeSubscriptions[
                            SubscriptionType
                              .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                          ]?.['voiceCloneRemaining'] ?? 0),
                      ).toLocaleString('en-US')} / ${Math.floor(
                        subscriptionPrices[
                          SubscriptionType
                            .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                        ]?.['voiceCloneLimit'] ?? 0,
                      ).toLocaleString('en-US')} voice clonings used`,

                      `${Math.floor(
                        (subscriptionPrices[
                          SubscriptionType
                            .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                        ]?.['textToSpeechLimit'] ?? 0) -
                          (activeSubscriptions[
                            SubscriptionType
                              .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                          ]?.['textToSpeechRemaining'] ?? 0),
                      ).toLocaleString('en-US')} / ${Math.floor(
                        subscriptionPrices[
                          SubscriptionType
                            .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                        ]?.['textToSpeechLimit'] ?? 0,
                      ).toLocaleString(
                        'en-US',
                      )} text to speech characters used`,
                    ]
                  : [
                      `${
                        subscriptionPrices[
                          SubscriptionType
                            .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                        ]?.['voiceCloneLimit'] ?? 0
                      } voice clonings per month`,
                      `${(
                        subscriptionPrices[
                          SubscriptionType
                            .UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                        ]?.['textToSpeechLimit'] ?? 0
                      ).toLocaleString('en-US')} text to speech characters`,
                    ]
              }
              minHeight={subscriptionBoxMinHeights}
              onCTAClick={() => {
                isVoiceCloneAndTextToSpeechSubscriptionActive
                  ? openSubscriptionMgmtPage(
                      SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH,
                    )
                  : createAndOpenSubscriptionSession(
                      SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH,
                    );
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Link
        component='button'
        onClick={() => navigate(APPS_TAB_PATH.PRICING + '#faq')}
        marginTop={3}
        marginBottom={10}
      >
        <Typography
          level='body-sm'
          color='primary'
        >
          Frequently Asked Questions
        </Typography>
      </Link>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant='solid'
        color={snackBarColor}
        invertedColors
        startDecorator={<BubbleChartRoundedIcon />}
        autoHideDuration={5000}
      >
        <Typography level='title-lg'>{snackBarContent}</Typography>
      </Snackbar>
    </Box>
  );
});
BillingOverviewTab.displayName = 'BillingOverviewTab';
export default BillingOverviewTab;
