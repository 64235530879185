import React from "react";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useMediaQuery } from '@mui/material';

import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import Forward10RoundedIcon from '@mui/icons-material/Forward10Rounded';
import Replay10RoundedIcon from '@mui/icons-material/Replay10Rounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import { Box } from "@mui/joy";

interface AudioPlayerProps {
    audioUrl: string;
    // eslint-disable-next-line no-unused-vars
    onCurrentProgress?: (currentMs: number) => void;
    marginTop?: number;
}

export const AudioMediaPlayer = React.memo(({ audioUrl, onCurrentProgress, marginTop }: AudioPlayerProps) => {
    const isMediumOrLargerScreen = useMediaQuery('(min-width:600px)');

    return (
        <Box width={"100%"} sx={{
            marginTop: marginTop,
            borderRadius: 8,
            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
        }}>
            <AudioPlayer
                src={audioUrl}
                // onPlay={() => console.log("onPlay")}
                onListen={(e: Event) => {
                    onCurrentProgress && e.target && onCurrentProgress(Math.floor((e.target as HTMLAudioElement).currentTime * 1000));
                }}
                hasDefaultKeyBindings={false}
                progressJumpSteps={{ backward: 10000, forward: 10000 }}
                progressUpdateInterval={300}
                listenInterval={500}
                customAdditionalControls={[]}
                customVolumeControls={isMediumOrLargerScreen ? undefined : []}
                customIcons={{
                    play: <PlayCircleRoundedIcon sx={{ width: 42, height: 42, color: 'black' }} />,
                    pause: <PauseCircleFilledRoundedIcon sx={{ width: 42, height: 42, color: 'black' }} />,
                    forward: <Forward10RoundedIcon sx={{ width: 28, height: 28, color: 'gray' }} />,
                    rewind: <Replay10RoundedIcon sx={{ width: 28, height: 28, color: 'gray' }} />,
                }}

            />
        </Box>
    );
});
AudioMediaPlayer.displayName = "AudioMediaPlayer";
export default AudioMediaPlayer;