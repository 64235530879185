import { Box, Chip, CircularProgress, Link, Stack, Table } from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { sendGetRequest, sendPostRequest } from '../../requests/sendRequests';
import { getDateString, getTimeString } from '../../utils/timeUtils';
import { convertCreditsToUserText } from '../../utils/creditUtils';
import { logClientError } from '../../logging/useLogUserEvent';
import { SubscriptionHistoryEntry } from '../../types/SubscriptionHistoryEntry';
import { formatAmountWithCurrency } from '../../utils/currencyUtils';
import { getReadableSubscriptionName } from '../../utils/subscriptionUtils';

interface SubscriptionHistoryTableProps {
  // eslint-disable-next-line no-unused-vars
  showSnackMessage?: (message: string, isError: boolean) => void;
}

const SubscriptionHistoryTable = React.memo(
  ({ showSnackMessage }: SubscriptionHistoryTableProps) => {
    const [paymentHistoryEntries, setPaymentHistoryEntries] = useState<
      SubscriptionHistoryEntry[]
    >([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);

    useEffect(() => {
      const queryRemainingCredits = async () => {
        setIsLoadingHistory(true);
        try {
          const response = await sendGetRequest({
            requestPath: '/payment/subscription_history',
            queryParams: {},
          });

          const paymentHistoryEntries = response as SubscriptionHistoryEntry[];
          setPaymentHistoryEntries(paymentHistoryEntries);
        } catch (e: any) {
          logClientError(
            'Failed to load remaining credits in SubscriptionHistoryTable ',
            e,
          );
        } finally {
          setIsLoadingHistory(false);
        }
      };

      queryRemainingCredits();
    }, []);

    const handleViewInvoice = useCallback(
      async (invoiceId: string) => {
        try {
          const response = await sendPostRequest({
            requestPath: '/payment/invoice_url',
            payload: {
              transactionId: invoiceId,
              invoiceType: 'subscription',
            },
          });

          const { invoice_url } = response as {
            invoice_url: string;
          };

          window.open(invoice_url, '_blank', 'noopener,noreferrer');
        } catch (e: any) {
          logClientError('Failed to load invoice url: ', e);
          showSnackMessage?.(
            'Failed to fetch invoice. Please refresh page and try again.',
            true, // is error
          );
        }
      },
      [showSnackMessage],
    );

    const wrapHeaderStyle: React.CSSProperties = useMemo(() => {
      return {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
      };
    }, []);

    if (isLoadingHistory) {
      return (
        <Stack
          width={'100%'}
          direction={'row'}
          justifyContent={'center'}
        >
          <CircularProgress sx={{ width: 120, height: 120 }} />
        </Stack>
      );
    }

    return (
      <Box width={'100%'}>
        <Table
          aria-label='table sizes'
          size='lg'
        >
          <thead>
            <tr>
              <th style={wrapHeaderStyle}>Date</th>
              <th style={wrapHeaderStyle}>Amount Paid</th>
              <th style={wrapHeaderStyle}>Credits Used</th>
              <th style={wrapHeaderStyle}>Subscription</th>
              <th style={wrapHeaderStyle}>Type</th>
              <th style={wrapHeaderStyle}>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {paymentHistoryEntries.map(
              (historyEntry: SubscriptionHistoryEntry) => (
                <tr key={historyEntry.invoiceId}>
                  <td>{`${getDateString(
                    historyEntry.timestamp,
                  )} ${getTimeString(historyEntry.timestamp)}`}</td>
                  <td>
                    {historyEntry.amountPaidCurrency
                      ? formatAmountWithCurrency(
                          historyEntry.amountPaid,
                          historyEntry.amountPaidCurrency,
                        )
                      : 'Check receipt'}
                  </td>
                  <td>
                    {convertCreditsToUserText(historyEntry.creditsConsumed, 2)}
                  </td>
                  <td>
                    {getReadableSubscriptionName(historyEntry.subscriptionName)}
                  </td>
                  <td>
                    <Chip
                      variant='soft'
                      color='success'
                    >
                      {historyEntry.isSubscriptionRenewal
                        ? 'renew'
                        : 'subscribe'}
                    </Chip>
                  </td>
                  <td>
                    <Link
                      component='button'
                      onClick={() => handleViewInvoice(historyEntry.invoiceId)}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      </Box>
    );
  },
);
SubscriptionHistoryTable.displayName = 'SubscriptionHistoryTable';
export default SubscriptionHistoryTable;
