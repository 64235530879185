import { Card, CardContent, Typography } from '@mui/joy';
import React from 'react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';

const SubscriptionStatusCard = React.memo(
  ({ subscriptionStatus }: { subscriptionStatus: string }) => {
    return (
      <Card
        variant='soft'
        color='success'
        invertedColors
        sx={{ width: '100%', marginTop: 4, marginBottom: 4 }}
      >
        <CardContent
          orientation='horizontal'
          sx={{ alignItems: 'center' }}
        >
          {subscriptionStatus === 'success' ? (
            <EmojiEmotionsOutlinedIcon sx={{ width: 48, height: 48 }} />
          ) : (
            <SentimentVeryDissatisfiedOutlinedIcon
              sx={{ width: 48, height: 48 }}
            />
          )}
          <CardContent>
            <Typography
              level='body-lg'
              fontWeight={'bold'}
            >
              {subscriptionStatus === 'success'
                ? 'Woohoo! Your subscription has been successfully activated!'
                : "Uh-oh! Something went awry. Give it another shot a bit later. We'll get it sorted!"}
            </Typography>
            {subscriptionStatus === 'success' && (
              <Typography
                level='body-md'
                marginTop={1}
              >
                Refresh the page to see the updated subscription status.
              </Typography>
            )}
          </CardContent>
        </CardContent>
      </Card>
    );
  },
);
SubscriptionStatusCard.displayName = 'SubscriptionStatusCard';
export default SubscriptionStatusCard;
