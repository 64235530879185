import { Box, Snackbar, Typography } from '@mui/joy';
import React, { useCallback, useState } from 'react';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import CreditsActivitiesLogTable from '../CreditsActivitiesLogTable';

const CreditsActivitiesTab = React.memo(() => {
  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState<
    'success' | 'warning' | 'danger'
  >('danger');

  const showSnackMessage = useCallback((message: string, isError: boolean) => {
    setSnackBarContent(message);
    setSnackbarOpen(true);
    setSnackBarColor(isError ? 'danger' : 'success');
  }, []);

  return (
    <Box>
      <Typography
        level='title-lg'
        marginTop={1}
        fontWeight={'bold'}
      >
        Your credits usage history
      </Typography>

      <Box
        width={'100%'}
        marginTop={4}
        marginBottom={8}
      >
        <CreditsActivitiesLogTable showSnackMessage={showSnackMessage} />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant='solid'
        color={snackBarColor}
        invertedColors
        startDecorator={<BubbleChartRoundedIcon />}
        autoHideDuration={5000}
      >
        <Typography level='title-lg'>{snackBarContent}</Typography>
      </Snackbar>
    </Box>
  );
});
CreditsActivitiesTab.displayName = 'CreditsActivitiesTab';
export default CreditsActivitiesTab;
