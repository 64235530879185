/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Select,
  Option,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Box,
  Typography,
  LinearProgress,
  Input,
  Tooltip,
  IconButton,
  Stack,
  Chip,
} from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { VOICE_CLONE_SUPPORTED_LANGUAGES } from '../../data/languages';
import { sendPostRequest } from '../../requests/sendRequests';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { VoiceCloneEntry } from '../../types/VoiceCloneEntry';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../stores/stores';
import { ProductType } from '../../types/ProductTypes';
import { ProductTier } from '../../types/ProductTier';
import PricePreviewBox from '../shared/PricePreviewBox';
import { convertCreditsToUserText } from '../../utils/creditUtils';
import { logClientError, useLogUserEvent } from '../../logging/useLogUserEvent';
import { UserEventTypes } from '../../logging/UserEventTypes';
import {
  CreditCheckResult,
  useCheckVoiceCloneCredits,
} from '../../hooks/useCreditsCheck';
import { uploadFileToS3WithMultipart } from '../../requests/multipartUpload';

interface NewVoiceCloningRequestModalProps {
  handleClose: ({
    // eslint-disable-next-line no-unused-vars
    createdRequest,
  }: {
    createdRequest?: VoiceCloneEntry;
  }) => void;
  isOpen: boolean;
}

const MIN_AUDIO_DURATION = 60000;
const MAX_AUDIO_DURATION = 300000;

const NewVoiceCloningRequestModal = React.memo(
  ({ handleClose, isOpen }: NewVoiceCloningRequestModalProps) => {
    const [modelName, setModelName] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [audioDuration, setAudioDuration] = useState<number>(0);
    const [validationError, setValidationError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const freeVoiceClones = useSelector(
      (state: StoreRootState) => state.user.freeVoiceClonesRemaining,
    );

    const logUserEvent = useLogUserEvent();

    const checkEnoughCredits = useCheckVoiceCloneCredits();

    const creditsCheckResult = useMemo(() => {
      if (selectedFile && !validationError && !errorMessage) {
        return checkEnoughCredits();
      }

      return CreditCheckResult.INVALID;
    }, [checkEnoughCredits, errorMessage, selectedFile, validationError]);

    // When user gives new inputs, clear validation error
    useEffect(() => {
      setValidationError('');
    }, [modelName, selectedLanguage, selectedFile]);

    const productPrices = useSelector(
      (state: StoreRootState) => state.product.productPrices,
    );

    const estimatedCreditsToUse = useMemo(() => {
      if (audioDuration && !validationError && !errorMessage) {
        return (
          productPrices[ProductType.VOICE_CLONING]?.[ProductTier.BASIC] ?? 0
        );
      }

      return null;
    }, [audioDuration, errorMessage, productPrices, validationError]);

    const handleLanguageChange = useCallback(
      (_event: React.SyntheticEvent | null, newValue: string | null) => {
        setSelectedLanguage(newValue!);
      },
      [],
    );

    const handleModelNameChange = useCallback(
      (event: { target: { value: React.SetStateAction<string> } }) => {
        setModelName(event.target.value);
      },
      [],
    );

    // eslint-disable-next-line no-undef
    const handleFileChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
          const file = event.target.files[0];
          setSelectedFile(file);
        }
      },
      [],
    );

    useEffect(() => {
      if (selectedFile) {
        const audio = new Audio();
        audio.src = URL.createObjectURL(selectedFile);
        audio.addEventListener('loadedmetadata', () => {
          setAudioDuration(Math.floor(audio.duration * 1000));

          logUserEvent({
            event: UserEventTypes.VOICE_CLONE_REQUEST_AUDIO_UPLOADED,
            audioDuration: Math.floor(audio.duration * 1000),
          });
        });
      }
    }, [logUserEvent, selectedFile]);

    const handleModalClose = useCallback(
      (_event: unknown, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

        handleClose({});
      },
      [handleClose],
    );

    const handleSubmit = useCallback(async () => {
      let validationError = '';

      if (!modelName) {
        validationError = 'Please give a name for the voice';
      } else if (!selectedLanguage) {
        validationError = 'Please select the language of the voice';
      } else if (!selectedFile) {
        validationError = 'Please upload a .wav audio file';
      } else if (
        audioDuration < MIN_AUDIO_DURATION ||
        audioDuration > MAX_AUDIO_DURATION
      ) {
        const durationInSeconds = Math.floor(audioDuration / 1000);
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        validationError = `The audio should be at least 1 minute and no longer than 5 minutes. Your uploaded audio is ${minutes} minutes and ${seconds} seconds.`;
      } else if (creditsCheckResult === CreditCheckResult.NOT_ENOUGH_CREDITS) {
        validationError =
          "You don't have enough pay as you go credits or voice cloning number in your subscription for this request. If you think this is an error, please refresh the page and try again.";
      }

      setErrorMessage('');
      setValidationError(validationError);

      if (validationError) {
        return;
      }

      setIsSubmitting(true);

      logUserEvent({
        event: UserEventTypes.VOICE_CLONE_REQUEST_SUBMIT,
        modelName,
        language: selectedLanguage,
        audioDurationInMs: audioDuration ?? 0,
        estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
        fileName: selectedFile?.name ?? '',
      });

      // Upload file to S3
      let audioS3Uri = '';
      try {
        const { fileId } = await uploadFileToS3WithMultipart(
          selectedFile!,
          '/voice-clone/audio_multipart_upload',
        );
        audioS3Uri = fileId ?? '';
      } catch (e: any) {
        logClientError(
          'Audio Upload failed in NewVoiceCloningRequestModal: ',
          e,
        );
        setErrorMessage('Audio Upload failed: ' + e.toString());
        setIsSubmitting(false);

        logUserEvent({
          event: UserEventTypes.VOICE_CLONE_REQUEST_RESPONSE,
          status: 'failed_at_uploading_audio',
          error: e.toString(),
          modelName,
          language: selectedLanguage,
          audioDurationInMs: audioDuration ?? 0,
          estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
          fileName: selectedFile?.name ?? '',
        });
        return;
      }

      // Generate a request record in the database
      let requestCreated: null | VoiceCloneEntry = null;
      try {
        const response = await sendPostRequest({
          requestPath: '/voice-clone/request',
          payload: {
            modelName,
            language: selectedLanguage,
            audioDurationInMs: audioDuration ?? 0,
            audioFileId: audioS3Uri,
          },
        });

        requestCreated = response as VoiceCloneEntry;
      } catch (e: any) {
        logClientError(
          'Failed to create request in NewVoiceCloningRequestModal: ',
          e,
        );
        setErrorMessage('Failed to create request: ' + e.toString());
        setIsSubmitting(false);

        logUserEvent({
          event: UserEventTypes.VOICE_CLONE_REQUEST_RESPONSE,
          status: 'failed_at_creating_request',
          error: e.toString(),
          modelName,
          language: selectedLanguage,
          audioDurationInMs: audioDuration ?? 0,
          estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
        });
        return;
      }

      setIsSubmitting(false);
      handleClose({ createdRequest: requestCreated });

      logUserEvent({
        event: UserEventTypes.VOICE_CLONE_REQUEST_RESPONSE,
        status: 'succeed',
        requestIdCreated: requestCreated?.id ?? '',
        modelName,
        language: selectedLanguage,
        audioDurationInMs: audioDuration ?? 0,
        estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
      });
    }, [
      modelName,
      selectedLanguage,
      selectedFile,
      audioDuration,
      creditsCheckResult,
      logUserEvent,
      estimatedCreditsToUse,
      handleClose,
    ]);

    useEffect(() => {
      // When the modal is opened, clear all states to start a new request
      if (isOpen) {
        setModelName('');
        setSelectedLanguage('');
        setSelectedFile(null);
        setAudioDuration(0);
        setValidationError('');
        setErrorMessage('');
        setIsSubmitting(false);
      }
    }, [isOpen]);

    const [audioUploadTooltipOpen, setAudioUploadTooltipOpen] =
      React.useState<boolean>(false);
    const [languageTooltipOpen, setLanguageTooltipOpen] =
      React.useState<boolean>(false);

    return (
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        disableEscapeKeyDown
      >
        <ModalDialog
          variant='outlined'
          role='alertdialog'
          sx={{
            width: {
              xs: '90%',
              sm: '70%',
              md: '55%',
              lg: '50%',
            },
            maxWidth: 'none',
            minWidth: '240px',
            position: 'absolute',
            top: '50%',
            left: {
              xs: '50%',
              md: '60%',
            },
          }}
        >
          <DialogTitle
            sx={{
              alignSelf: 'center',
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              level='title-lg'
              sx={{ fontWeight: 'bold' }}
            >
              New Voice Cloning Request
            </Typography>
            {freeVoiceClones > 0 && (
              <Chip
                variant='soft'
                color='success'
                sx={{
                  fontSize: 12,
                  backgroundColor: '#1F7A1F',
                  color: 'white',
                }}
              >
                {freeVoiceClones} Free Voice Clones
              </Chip>
            )}
          </DialogTitle>
          <DialogContent sx={{ gap: 4, marginTop: 2 }}>
            <FormControl>
              <FormLabel>
                <Typography
                  level='title-md'
                  sx={{ fontWeight: 'bold' }}
                >
                  Voice Name
                </Typography>
              </FormLabel>
              <Input
                value={modelName}
                placeholder='Provide a name for the voice'
                onChange={handleModelNameChange}
                sx={{ marginTop: 1 }}
              />

              <FormLabel
                sx={{ marginTop: 2, display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  level='title-md'
                  sx={{ fontWeight: 'bold' }}
                >
                  Language of the Voice
                </Typography>
                <Tooltip
                  title="We currently offer voice cloning in English and Chinese only, but don't worry — more languages are on the way! Stay tuned!"
                  placement='top'
                  sx={{ maxWidth: 400 }}
                  open={languageTooltipOpen}
                  leaveTouchDelay={8000}
                  onClose={() => setLanguageTooltipOpen(false)}
                >
                  <IconButton
                    size='sm'
                    sx={{ marginLeft: 1 }}
                    variant='plain'
                    color='neutral'
                    onClick={() => setLanguageTooltipOpen(true)}
                    onMouseEnter={() => setLanguageTooltipOpen(true)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </FormLabel>
              <Select
                id='language-select'
                value={selectedLanguage}
                onChange={handleLanguageChange}
                disabled={isSubmitting}
                sx={{ marginTop: 1 }}
              >
                {Object.entries(VOICE_CLONE_SUPPORTED_LANGUAGES).map(
                  ([key, name]) => (
                    <Option
                      key={key}
                      value={key}
                    >
                      {name}
                    </Option>
                  ),
                )}
              </Select>

              <FormLabel
                sx={{ marginTop: 2, display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  level='title-md'
                  sx={{ fontWeight: 'bold' }}
                >
                  Upload Audio
                </Typography>
                <Tooltip
                  title='Please upload a .wav audio file with clear voice quality and no background noise or accompaniment. The audio should be at least 1 minute and no longer than 5 minutes.'
                  placement='top'
                  sx={{ maxWidth: 400 }}
                  open={audioUploadTooltipOpen}
                  leaveTouchDelay={8000}
                  onClose={() => setAudioUploadTooltipOpen(false)}
                >
                  <IconButton
                    size='sm'
                    sx={{ marginLeft: 1 }}
                    variant='plain'
                    color='neutral'
                    onClick={() => setAudioUploadTooltipOpen(true)}
                    onMouseEnter={() => setAudioUploadTooltipOpen(true)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </FormLabel>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <input
                  accept='audio/wav'
                  type='file'
                  hidden
                  id='file-upload'
                  onChange={handleFileChange}
                  disabled={isSubmitting}
                />
                <label htmlFor='file-upload'>
                  <Button
                    component='span'
                    color='neutral'
                    sx={{
                      whiteSpace: 'nowrap', // Prevents the text from wrapping
                      overflow: 'hidden', // Hides text that overflows the element's box
                      textOverflow: 'ellipsis', // Adds an ellipsis when the text overflows
                    }}
                  >
                    Upload Audio
                  </Button>
                </label>
                {selectedFile && (
                  <Typography
                    level='title-sm'
                    color='neutral'
                    sx={{ marginLeft: 1 }}
                  >
                    {selectedFile.name}
                  </Typography>
                )}
              </Box>
            </FormControl>

            {validationError && (
              <Typography
                level='body-sm'
                color='danger'
              >
                {validationError}
              </Typography>
            )}

            {errorMessage && (
              <Typography
                level='body-sm'
                color='danger'
              >
                {errorMessage}
              </Typography>
            )}

            {estimatedCreditsToUse && (
              <Box marginTop={2}>
                <PricePreviewBox
                  totalCredits={
                    creditsCheckResult !==
                      CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS &&
                    freeVoiceClones === 0
                      ? estimatedCreditsToUse
                      : undefined
                  }
                  description={
                    freeVoiceClones > 0
                      ? '1 free voice cloning will be used after successful cloning.'
                      : creditsCheckResult ===
                        CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS
                      ? `1 voice cloning will be consumed from your Voice Cloning and Text to Speech subscription plan.`
                      : `Credits per cloning: ${convertCreditsToUserText(
                          productPrices[ProductType.VOICE_CLONING]?.[
                            ProductTier.BASIC
                          ] ?? 0,
                        )}.`
                  }
                />
              </Box>
            )}

            {isSubmitting && (
              <Stack gap={1}>
                <LinearProgress />
                <Typography
                  level='body-sm'
                  color='warning'
                >
                  Upload could take minutes, please wait and do not close the
                  dialog.
                </Typography>
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose({});

                logUserEvent({
                  event: UserEventTypes.VOICE_CLONE_REQUEST_CANCELLED,
                  modelName,
                  language: selectedLanguage,
                  audioDurationInMs: audioDuration ?? 0,
                  estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
                });
              }}
              variant='outlined'
              color='neutral'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant='solid'
              color='primary'
              disabled={isSubmitting || validationError.trim().length > 0}
            >
              Submit
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  },
);

NewVoiceCloningRequestModal.displayName = 'NewVoiceCloningRequestModal';
export default NewVoiceCloningRequestModal;
