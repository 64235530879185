import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionType } from '../types/SubscriptionType';
import { ActiveSubscription } from '../types/ActiveSubscription';

interface SubscriptionState {
  aciveSubscriptions: Partial<Record<SubscriptionType, ActiveSubscription>>;
  subscriptionPortalUrl: string;
}

const initialState: SubscriptionState = {
  aciveSubscriptions: {},
  subscriptionPortalUrl: '',
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setActiveScriptions: (state, action) => {
      const activeSubscriptions = action.payload as ActiveSubscription[];

      activeSubscriptions.forEach((subscription) => {
        state.aciveSubscriptions[subscription.subscriptionName] = subscription;
      });
    },

    setSubscriptionPortalUrl: (state, action) => {
      const url = action.payload as string;

      state.subscriptionPortalUrl = url;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveScriptions, setSubscriptionPortalUrl } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
