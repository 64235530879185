import { configureStore } from '@reduxjs/toolkit';

import speechToTextReducer from './speechToTextSlice';
import creditsReducer from './creditsSlice';
import pathBreadcumbReducer from './pathBreadcumbSlice';
import productReducer from './productSlice';
import userReducer from './userSlice';
import loggingReducer from './loggingSlice';
import subscriptionReducer from './subscriptionSlice';

export interface StoreRootState {
  speechToText: ReturnType<typeof speechToTextReducer>;
  pathBreadcumb: ReturnType<typeof pathBreadcumbReducer>;
  credits: ReturnType<typeof creditsReducer>;
  product: ReturnType<typeof productReducer>;
  user: ReturnType<typeof userReducer>;
  logging: ReturnType<typeof loggingReducer>;
  subscription: ReturnType<typeof subscriptionReducer>;
}

export default configureStore({
  reducer: {
    speechToText: speechToTextReducer,
    pathBreadcumb: pathBreadcumbReducer,
    credits: creditsReducer,
    product: productReducer,
    user: userReducer,
    logging: loggingReducer,
    subscription: subscriptionReducer,
  },
});
