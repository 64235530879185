import { Box, Divider, Grid, Typography } from '@mui/joy';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../stores/stores';
import PricingBox from '../components/pricing/PricingBox';
import { convertCreditsToUserText } from '../utils/creditUtils';
import { ProductType } from '../types/ProductTypes';
import { ProductTier } from '../types/ProductTier';
import { APPS_TAB_PATH } from '../data/pageLinks';
import { useLogPageImpression } from '../logging/useLogPageImpression';
import { SubscriptionType } from '../types/SubscriptionType';
import FaqSection from '../components/Faq/FaqSection';

const PricingPageContent = React.memo(() => {
  const productPrices = useSelector(
    (state: StoreRootState) => state.product.productPrices,
  );

  const subscriptionPrices = useSelector(
    (state: StoreRootState) => state.product.subscriptionPrices,
  );

  const navigate = useNavigate();

  useLogPageImpression('pricing_page');

  const subscriptionBoxMinHeights = useMemo(() => {
    return {
      xs: 100,
      sm: 120,
      md: 200,
      lg: 150,
    };
  }, []);

  return (
    <Box width={'100%'}>
      <Box
        component='section'
        width={'100%'}
      >
        <Typography
          level='h4'
          sx={{ marginTop: 2 }}
        >
          Pay as You Go
        </Typography>
        <Typography
          level='title-md'
          color='neutral'
          sx={{ marginTop: 2 }}
        >
          With our pay as you go plan, you load up on credits and use them as
          you need, ensuring you always stay in control of your spending without
          the worry of monthly bills.
        </Typography>
        <Grid
          container
          spacing={4}
          marginTop={1}
          // marginBottom={4}
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Grid
            sm={12}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <PricingBox
              featureName='Speech to Text'
              ctaTitle='Add Credits'
              backgroundColor='#e8eddf'
              descriptionLines={[
                `${convertCreditsToUserText(
                  productPrices[ProductType.SPEECH_TO_TEXT]?.[
                    ProductTier.BASIC
                  ] ?? 0,
                )} / minute`,
                'Charge by minute',
                'Download src or txt file',
              ]}
              onCTAClick={() => {
                navigate(APPS_TAB_PATH.BILLING);
              }}
            />
          </Grid>
          <Grid
            sm={12}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <PricingBox
              featureName='Text to Speech'
              ctaTitle='Add Credits'
              backgroundColor='#e2eafc'
              descriptionLines={[
                `${convertCreditsToUserText(
                  1000 *
                    (productPrices[ProductType.TEXT_TO_SPEECH]?.[
                      ProductTier.BASIC
                    ] ?? 0),
                )} / 1K characters`,
                'Charge based on the number of characters, and round to the nearest $0.001',
                'Download wav or mp3 file',
              ]}
              onCTAClick={() => {
                navigate(APPS_TAB_PATH.BILLING);
              }}
            />
          </Grid>
          <Grid
            sm={12}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <PricingBox
              featureName='Voice Cloning'
              ctaTitle='Add Credits'
              backgroundColor='#f4d06f'
              descriptionLines={[
                `${convertCreditsToUserText(
                  productPrices[ProductType.VOICE_CLONING]?.[
                    ProductTier.BASIC
                  ] ?? 0,
                  2,
                )} to train a voice model`,
                `${convertCreditsToUserText(
                  productPrices[ProductType.TEXT_TO_SPEECH]?.[
                    ProductTier.BASIC
                  ] ?? 0,
                )} / 1K characters when using your trained model for text to speech`,
              ]}
              onCTAClick={() => {
                navigate(APPS_TAB_PATH.BILLING);
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ marginY: 4, border: 1.2, borderColor: 'divider' }} />

      <Box
        component='section'
        width={'100%'}
        marginTop={5}
      >
        <Typography level='h4'>Subscriptions</Typography>
        <Typography
          level='title-md'
          color='neutral'
          sx={{ marginTop: 2 }}
        >
          Opt for our subscription plans to enjoy better cost efficiency. Ideal
          for users who need more than pay-as-you-go! You can subscribe to
          multiple subscription plans.
        </Typography>
        <Grid
          container
          spacing={6}
          marginTop={1}
          marginBottom={4}
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Grid
            sm={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <PricingBox
              featureName='Speech to Text'
              ctaTitle='Subscribe'
              backgroundColor='#f1e7dd'
              subscriptionPrice={`${convertCreditsToUserText(
                subscriptionPrices[SubscriptionType.UNLIMITED_SPEECH_TO_TEXT]?.[
                  'credits'
                ] ?? 0,
                2,
              )}`}
              descriptionLines={[
                `${Math.floor(
                  (subscriptionPrices[
                    SubscriptionType.UNLIMITED_SPEECH_TO_TEXT
                  ]?.['speechToTextMinutesLimit'] ?? 0) / 60,
                ).toLocaleString('en-US')} hours audio to transcribe per month`,
                'Download src or txt file',
              ]}
              minHeight={subscriptionBoxMinHeights}
              onCTAClick={() => {
                navigate(APPS_TAB_PATH.BILLING);
              }}
            />
          </Grid>
          <Grid
            sm={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <PricingBox
              featureName='Voice Cloning and Text to Speech'
              ctaTitle='Subscribe'
              backgroundColor='#cfe1b9'
              subscriptionPrice={`${convertCreditsToUserText(
                subscriptionPrices[
                  SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                ]?.['credits'] ?? 0,
                2,
              )}`}
              descriptionLines={[
                `${
                  subscriptionPrices[
                    SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                  ]?.['voiceCloneLimit'] ?? 0
                } voice clonings per month`,
                `${(
                  subscriptionPrices[
                    SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
                  ]?.['textToSpeechLimit'] ?? 0
                ).toLocaleString('en-US')} text to speech characters`,
              ]}
              minHeight={subscriptionBoxMinHeights}
              onCTAClick={() => {
                navigate(APPS_TAB_PATH.BILLING);
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ marginY: 4, border: 1.2, borderColor: 'divider' }} />

      <Box
        component='section'
        width={'100%'}
        marginTop={5}
      >
        <FaqSection />
      </Box>

      <Typography
        level='h2'
        id='faq'
        sx={{ marginBottom: 10 }}
      ></Typography>
    </Box>
  );
});
PricingPageContent.displayName = 'PricingPageContent';
export default PricingPageContent;
