import FAQAccordion from './FAQAccordion';
import { Box, Typography } from '@mui/joy';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../stores/stores';

const FaqSection = React.memo(() => {
  const faqList = useSelector((state: StoreRootState) => state.product.faqList);

  return (
    <Box>
      <Typography
        level='h4'
        sx={{ marginBottom: 4 }}
      >
        Frequently Asked Questions
      </Typography>

      <FAQAccordion faqData={faqList} />
    </Box>
  );
});
FaqSection.displayName = 'FaqSection';

export default FaqSection;
