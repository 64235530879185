import { Chip, Stack, Tooltip } from '@mui/joy';
import React, { useMemo, useState } from 'react';
import { RequestStatus } from '../../types/RequestStatus';

import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import AutoModeRoundedIcon from '@mui/icons-material/AutoModeRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CreditsUsed from './CreditsUsed';

const StatusRow = React.memo(
  ({
    status,
    errorMsg,
    creditsUsed,
  }: {
    status: RequestStatus;
    errorMsg?: string;
    creditsUsed?: number;
  }) => {
    const icon = useMemo(() => {
      switch (status) {
        case RequestStatus.PENDING:
          return <HourglassBottomRoundedIcon sx={{ width: 20, height: 20 }} />;
        case RequestStatus.PROCESSING:
          return (
            <AutoModeRoundedIcon
              sx={{ width: 20, height: 20, color: 'warning.plainColor' }}
            />
          );
        case RequestStatus.SUCCESS:
          return (
            <CheckCircleRoundedIcon
              sx={{ width: 20, height: 20, color: 'success.plainColor' }}
            />
          );
        case RequestStatus.FAILED:
          return (
            <WarningRoundedIcon
              sx={{ width: 20, height: 20, color: 'danger.plainColor' }}
            />
          );
      }
    }, [status]);

    const tooltipTitle = useMemo(() => {
      switch (status) {
        case RequestStatus.PENDING:
          return 'This request is queued for processing. It could take up to 10 minutes to process the request.';
        case RequestStatus.FAILED:
          return errorMsg;
        default:
          return '';
      }
    }, [errorMsg, status]);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const chip = useMemo(
      () => (
        <Chip
          variant='soft'
          color='neutral'
          startDecorator={icon}
          onClick={() => setTooltipOpen(true)}
          onMouseEnter={() => setTooltipOpen(true)}
        >
          {status}
        </Chip>
      ),
      [icon, status],
    );

    const statusInfo = useMemo(
      () =>
        tooltipTitle ? (
          <Tooltip
            title={tooltipTitle}
            placement='bottom-start'
            sx={{ maxWidth: 500 }}
            variant='soft'
            open={tooltipOpen}
            leaveTouchDelay={8000}
            onClose={() => setTooltipOpen(false)}
          >
            {chip}
          </Tooltip>
        ) : (
          chip
        ),
      [chip, tooltipOpen, tooltipTitle],
    );

    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={2}
      >
        {statusInfo}
        <CreditsUsed creditsUsed={creditsUsed} />
      </Stack>
    );
  },
);

StatusRow.displayName = 'StatusRow';
export default StatusRow;
