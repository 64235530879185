import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import { fetchUserAttributes, signOut } from 'aws-amplify/auth';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { closeSidebar } from '../utils';
import {
  APPS_TAB_PATH,
  LOGOUT_DESTINATION_URL,
  TERMS_OF_SERVICE_URL,
} from '../data/pageLinks';
import { useCallback, useEffect, useState } from 'react';
import { CreditBalanceCard } from './billing/CreditBalanceCard';

import { useLocation, useNavigate } from 'react-router-dom';
import { logClientError } from '../logging/useLogUserEvent';

const Sidebar = React.memo(() => {
  const [userEmail, setUserEmail] = useState('');
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState<APPS_TAB_PATH>(
    APPS_TAB_PATH.HOME_SHORT,
  );

  const navigate = useNavigate();

  useEffect(() => {
    const firstPathPart = location.pathname
      .split('/')
      .find((part) => part !== '');
    if (firstPathPart) {
      const tabPath = `/${firstPathPart}` as APPS_TAB_PATH;
      setSelectedTab(tabPath);
    } else {
      setSelectedTab(APPS_TAB_PATH.HOME);
    }
  }, [location]);

  useEffect(() => {
    const getUserAttrib = async () => {
      const userAttributes = await fetchUserAttributes();
      setUserEmail(userAttributes.email ?? '');
    };

    getUserAttrib();
  }, []);

  const handleListItemClick = useCallback(
    (tabPath: APPS_TAB_PATH) => {
      setSelectedTab(tabPath);
      navigate(tabPath);
    },
    [navigate],
  );

  const handleLogout = useCallback(async () => {
    try {
      await signOut();

      window.location.href = LOGOUT_DESTINATION_URL;
    } catch (error) {
      logClientError('error signing out: ', error);
    }
  }, []);

  return (
    <Sheet
      className='Sidebar'
      sx={{
        position: 'fixed',
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100vh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className='Sidebar-overlay'
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton size='sm'>
          <img
            src='/favicon.ico'
            alt='voice-vector.com Logo'
            onClick={() => navigate(APPS_TAB_PATH.HOME_SHORT)}
          />
        </IconButton>
        <Typography level='title-lg'>voice-vector.com</Typography>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size='sm'
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              onClick={() => handleListItemClick(APPS_TAB_PATH.HOME_SHORT)}
              selected={
                selectedTab === APPS_TAB_PATH.HOME ||
                selectedTab === APPS_TAB_PATH.HOME_SHORT
              }
            >
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level='title-md'>Home</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => handleListItemClick(APPS_TAB_PATH.SPEECH_TO_TEXT)}
              selected={selectedTab === APPS_TAB_PATH.SPEECH_TO_TEXT}
            >
              <TextFieldsRoundedIcon />
              <ListItemContent>
                <Typography level='title-md'>Speech to Text</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => handleListItemClick(APPS_TAB_PATH.TEXT_TO_SPEECH)}
              selected={selectedTab === APPS_TAB_PATH.TEXT_TO_SPEECH}
            >
              <GraphicEqRoundedIcon />
              <ListItemContent>
                <Typography level='title-md'>Text to Speech</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => handleListItemClick(APPS_TAB_PATH.VOICE_CLONE)}
              selected={selectedTab === APPS_TAB_PATH.VOICE_CLONE}
            >
              <AutoAwesomeRoundedIcon />
              <ListItemContent>
                <Typography level='title-md'>Voice Cloning</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => handleListItemClick(APPS_TAB_PATH.BILLING)}
              selected={selectedTab === APPS_TAB_PATH.BILLING}
            >
              <ReceiptLongRoundedIcon />
              <ListItemContent>
                <Typography level='title-md'>Billing</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => handleListItemClick(APPS_TAB_PATH.PRICING)}
              selected={selectedTab === APPS_TAB_PATH.PRICING}
            >
              <AttachMoneyRoundedIcon />
              <ListItemContent>
                <Typography level='title-md'>Pricing</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>

        <List
          size='sm'
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemButton
              component='a'
              href={TERMS_OF_SERVICE_URL}
              target='_blank'
            >
              <PrivacyTipOutlinedIcon />
              <Typography level='title-md'>Terms of Service</Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <CreditBalanceCard />
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <img
          src='/icons/user_profile.png'
          alt='user profile icon'
          style={{ width: 28, height: 28 }}
        />
        <Box sx={{ minWidth: 0, flex: 1, overflow: 'hidden' }}>
          <Typography
            level='body-sm'
            sx={{ wordBreak: 'break-all' }}
          >
            {userEmail}
          </Typography>
        </Box>
        <IconButton
          size='sm'
          variant='plain'
          color='neutral'
          onClick={handleLogout}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
});
Sidebar.displayName = 'Sidebar';

export default Sidebar;
