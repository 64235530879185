import { Card, Chip, CircularProgress, Stack, Typography } from '@mui/joy';
import React, { useMemo } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../stores/stores';
import { convertCreditsToUserText } from '../../utils/creditUtils';
import { useNavigate } from 'react-router-dom';
import { APPS_TAB_PATH } from '../../data/pageLinks';
import { CreditAssignmentStatus } from '../../types/CreditAssignmentStatus';
import WarningIcon from '@mui/icons-material/Warning';
import { SubscriptionType } from '../../types/SubscriptionType';

export const CreditBalanceCard = React.memo(() => {
  const isLoadingCredits = useSelector(
    (state: StoreRootState) => !state.credits.creditsLoaded,
  );
  const remainingCredits = useSelector(
    (state: StoreRootState) => state.credits.remainingCredits,
  );
  const freeCreditsAssignmentStatus = useSelector(
    (state: StoreRootState) => state.credits.freeCreditsAssignmentStatus,
  );

  const activeSubscriptions = useSelector(
    (state: StoreRootState) => state.subscription.aciveSubscriptions,
  );

  const isSpeechToTextSubscriptionActive = useMemo(() => {
    return activeSubscriptions[SubscriptionType.UNLIMITED_SPEECH_TO_TEXT]
      ? true
      : false;
  }, [activeSubscriptions]);

  const isVoiceCloneAndTextToSpeechSubscriptionActive = useMemo(() => {
    return activeSubscriptions[
      SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
    ]
      ? true
      : false;
  }, [activeSubscriptions]);

  const freeVoiceClones = useSelector(
    (state: StoreRootState) => state.user.freeVoiceClonesRemaining,
  );

  const navigate = useNavigate();

  return (
    <Card
      // invertedColors
      variant='soft'
      color='neutral'
      size='sm'
      sx={{ boxShadow: 'none', cursor: 'pointer' }}
      onClick={() => {
        navigate(APPS_TAB_PATH.BILLING);
      }}
    >
      <Stack
        direction={'row'}
        gap={1}
        alignItems={'center'}
      >
        <Typography
          level='title-md'
          sx={{ fontWeight: 'bold' }}
        >
          Credit balance
        </Typography>
        {remainingCredits < 1000 &&
          freeCreditsAssignmentStatus ===
            CreditAssignmentStatus.ASSIGNED_TO_DIFFERENT_USER && (
            <WarningIcon color='warning' />
          )}
      </Stack>
      <Stack
        direction='row'
        sx={{ gap: 1 }}
        alignItems={'center'}
      >
        <MonetizationOnIcon color='success' />
        {isLoadingCredits ? (
          <CircularProgress />
        ) : (
          <Typography
            level='h4'
            color='neutral'
            sx={{ fontWeight: 'bold' }}
          >
            {convertCreditsToUserText(remainingCredits, 2)}
          </Typography>
        )}
      </Stack>
      {(isSpeechToTextSubscriptionActive ||
        isVoiceCloneAndTextToSpeechSubscriptionActive) && (
        <Chip
          variant='soft'
          color='success'
          sx={{ fontSize: 14, backgroundColor: '#1F7A1F', color: 'white' }}
        >
          Active Subscription
        </Chip>
      )}
      {freeVoiceClones > 0 && (
        <Chip
          variant='soft'
          color='success'
          sx={{ fontSize: 14, backgroundColor: '#1F7A1F', color: 'white' }}
        >
          {freeVoiceClones} Free Voice Clones
        </Chip>
      )}
    </Card>
  );
});
CreditBalanceCard.displayName = 'CreditBalanceCard';
