import { Card, CardContent, Typography } from '@mui/joy';
import React from 'react';
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../stores/stores';

const DataDeletionReminder = React.memo(() => {
  const resultRestainDays = useSelector(
    (state: StoreRootState) => state.product.resultRestainDays,
  );
  return (
    <Card
      variant='soft'
      color='warning'
      invertedColors
      sx={{ width: '100%', marginBottom: 4, backgroundColor: '#F6FAB9' }}
    >
      <CardContent
        orientation='horizontal'
        sx={{ alignItems: 'center' }}
      >
        <AutoDeleteOutlinedIcon sx={{ width: 28, height: 28 }} />
        <CardContent>
          <Typography level='body-md'>
            {`Due to resource constraints and privacy, each result will be
            retained for ${resultRestainDays} days before being deleted. Please download your data
            in time to avoid losing it.`}
          </Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
});
DataDeletionReminder.displayName = 'DataDeletionReminder';
export default DataDeletionReminder;
