import React, { useEffect, useRef } from 'react';
import { sendGetRequest, sendPostRequest } from '../requests/sendRequests';
import { useDispatch } from 'react-redux';
import {
  setFreeCreditsAssignmentStatus,
  updateRemainingCredits,
} from '../stores/creditsSlice';
import { ProductTier } from '../types/ProductTier';
import { ProductPrice, SubscriptionPrice } from '../types/ProductPrice';
import {
  setFaqList,
  setProductDemoVideoUrl,
  setProductPrices,
  setResultRestainDays,
  setSubscriptionPrices,
} from '../stores/productSlice';
import { ProductType } from '../types/ProductTypes';
import { Box } from '@mui/joy';
import { getCurrentUser } from 'aws-amplify/auth';
import { logClientError, useLogUserEvent } from '../logging/useLogUserEvent';
import { UserEventTypes } from '../logging/UserEventTypes';
import { useBrowserFingerPrint } from '../logging/useBrowserFingerPrint';
import { CreditAssignmentStatus } from '../types/CreditAssignmentStatus';
import { ActiveSubscription } from '../types/ActiveSubscription';
import {
  setActiveScriptions,
  setSubscriptionPortalUrl,
} from '../stores/subscriptionSlice';
import { FAQ } from '../types/FAQ';
import { updateFreeVoiceClonesRemaining } from '../stores/userSlice';

const DataLoader = React.memo(() => {
  const dispatch = useDispatch();
  const logUserEvent = useLogUserEvent();
  const creditsLoadPerformed = useRef<boolean>(false);
  const productPricesLoadPerformed = useRef<boolean>(false);
  const productDemoVideoUrlLoadPerformed = useRef<boolean>(false);
  const resultRestainLoadPerformed = useRef<boolean>(false);
  const activeSubscriptionLoadPerformed = useRef<boolean>(false);

  const { currentFingerPrint, initializeBrowserFingerPrint } =
    useBrowserFingerPrint();

  console.log('🖕 currentFingerPrint = ', currentFingerPrint);

  // Initialize and  Load user's remaining balance
  useEffect(() => {
    const initAndQueryCredits = async () => {
      let userKey = '';
      let creditAssignmentStatusKey = '';
      try {
        const { username, userId } = await getCurrentUser();
        if (userId || username) {
          userKey = `${userId || username}_credits_checked`;
          creditAssignmentStatusKey = `${
            userId || username
          }_credits_assignment_status`;
        }
      } catch (error) {
        logClientError('initAndQueryCredits: ', error);
      }

      // If there is no logged in user, DO NOT proceed with the operations below
      if (!userKey) {
        return;
      }

      if (creditsLoadPerformed.current) {
        return;
      }

      creditsLoadPerformed.current = true;

      let browserFingerPrint = currentFingerPrint;
      if (!browserFingerPrint) {
        browserFingerPrint = await initializeBrowserFingerPrint();
      }

      // Check local storage for the key to see whether we have checked whether the user has assigned free credits
      const freeCreditsAssignmentChecked = localStorage.getItem(userKey);
      const storedCreditAssignmentStatus = localStorage.getItem(
        creditAssignmentStatusKey,
      );

      if (
        freeCreditsAssignmentChecked !== 'completed' ||
        !storedCreditAssignmentStatus
      ) {
        try {
          // Assign free credits if has not assigned yet
          const response = await sendPostRequest({
            requestPath: '/user/free_credits',
            payload: {
              browserFingerPrint,
            },
          });

          const { creditAssignmentStatus } = response as {
            creditAssignmentStatus: CreditAssignmentStatus;
          };

          dispatch(setFreeCreditsAssignmentStatus(creditAssignmentStatus));

          localStorage.setItem(userKey, 'completed');
          localStorage.setItem(
            creditAssignmentStatusKey,
            creditAssignmentStatus,
          );
        } catch (e: any) {
          logClientError('Failed to initialize free credits: ', e);
        }
      } else {
        dispatch(
          setFreeCreditsAssignmentStatus(
            storedCreditAssignmentStatus as CreditAssignmentStatus,
          ),
        );
      }

      try {
        const [creditsResponse, freeVoiceClonesResponse] = await Promise.all([
          sendGetRequest({
            requestPath: '/user/credits',
            queryParams: {},
          }),
          sendGetRequest({
            requestPath: '/user/free_voice_clones',
            queryParams: {},
          }),
        ]);

        const { remainingCredits } = creditsResponse as {
          remainingCredits: number;
        };
        const { freeVoiceClones } = freeVoiceClonesResponse as {
          freeVoiceClones: number;
        };
        dispatch(updateRemainingCredits(remainingCredits));
        dispatch(updateFreeVoiceClonesRemaining(freeVoiceClones));

        logUserEvent({
          event: UserEventTypes.USER_CREDITS_LOADED,
          credits: remainingCredits,
        });
      } catch (e: any) {
        logClientError('Failed to load remaining credits in DataLoader: ', e);
      }
    };

    initAndQueryCredits();
  }, [
    currentFingerPrint,
    dispatch,
    initializeBrowserFingerPrint,
    logUserEvent,
  ]);

  // Load product demo video urls,  product prices and result restains
  useEffect(() => {
    const queryDemoVideoUrls = async () => {
      if (productDemoVideoUrlLoadPerformed.current) {
        return;
      }

      productDemoVideoUrlLoadPerformed.current = true;

      try {
        const response = await sendGetRequest({
          requestPath: '/product/demo_videos',
          queryParams: {},
        });

        dispatch(
          setProductDemoVideoUrl(response as Record<ProductType, string>),
        );
      } catch (e: any) {
        logClientError(
          'Failed to load product demo video urls in DataLoader: ',
          e,
        );
      }
    };

    const queryProductPrices = async () => {
      if (productPricesLoadPerformed.current) {
        return;
      }

      productPricesLoadPerformed.current = true;

      try {
        const [productPriceResponse, subscriptionResponse, faqListResponse] =
          await Promise.all([
            sendGetRequest({
              requestPath: '/product/prices',
              queryParams: {
                tier: ProductTier.BASIC,
              },
            }),
            sendGetRequest({
              requestPath: '/product/subscriptions',
              queryParams: {},
            }),
            sendGetRequest({
              requestPath: '/product/faq',
              queryParams: {},
            }),
          ]);

        dispatch(setProductPrices(productPriceResponse as ProductPrice[]));
        dispatch(
          setSubscriptionPrices(subscriptionResponse as SubscriptionPrice[]),
        );
        dispatch(setFaqList(faqListResponse as FAQ[]));
      } catch (e: any) {
        logClientError(
          'Failed to load product prices and subscription prices in DataLoader: ',
          e,
        );
      }
    };

    const queryResultRestains = async () => {
      if (resultRestainLoadPerformed.current) {
        return;
      }

      resultRestainLoadPerformed.current = true;

      try {
        const response = await sendGetRequest({
          requestPath: '/product/result_retain',
          queryParams: {},
        });

        dispatch(
          setResultRestainDays(
            (response as { restrained_days: number }).restrained_days,
          ),
        );
      } catch (e: any) {
        logClientError(
          'Failed to load product demo video urls in DataLoader: ',
          e,
        );
      }
    };

    Promise.all([
      queryDemoVideoUrls(),
      queryProductPrices(),
      queryResultRestains(),
    ]);
  }, [dispatch]);

  // Load users' active subscriptions and free voice clones
  useEffect(() => {
    const querySubscriptions = async () => {
      if (activeSubscriptionLoadPerformed.current) {
        return;
      }

      activeSubscriptionLoadPerformed.current = true;

      try {
        const response = await sendGetRequest({
          requestPath: '/user/active_subscriptions',
          queryParams: {},
        });

        const subscriptionResp = response as {
          subscriptions: ActiveSubscription[];
          portal_url: string;
        };

        dispatch(setActiveScriptions(subscriptionResp.subscriptions));
        dispatch(setSubscriptionPortalUrl(subscriptionResp.portal_url));
      } catch (e: any) {
        logClientError(
          'Failed to load product demo video urls in DataLoader: ',
          e,
        );
      }
    };

    querySubscriptions();
  }, [dispatch]);

  return (
    <Box
      height={0}
      width={0}
      display={'none'}
    />
  );
});

DataLoader.displayName = 'DataLoader';
export default DataLoader;
