import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/joy';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APPS_TAB_PATH } from '../../data/pageLinks';

const VoiceCloneActionBanner = React.memo(
  ({
    bannerText,
    bannerIcon,
  }: {
    bannerText: string;
    bannerIcon: React.ReactNode;
  }) => {
    const navigate = useNavigate();

    return (
      <Card
        sx={{ width: '100%' }}
        variant='soft'
        color='neutral'
      >
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {bannerIcon}
            <Typography
              level='body-md'
              color='neutral'
            >
              {bannerText}
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant='soft'
            size='sm'
            onClick={() => {
              navigate(APPS_TAB_PATH.VOICE_CLONE);
            }}
          >
            Clone Voice
          </Button>
        </CardActions>
      </Card>
    );
  },
);
VoiceCloneActionBanner.displayName = 'VoiceCloneActionBanner';
export default VoiceCloneActionBanner;
