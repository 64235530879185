import { APPS_TAB_PATH } from '../data/pageLinks';

export const getRequestUrl = (
  requestServiceSource: string,
  requestId: string,
) => {
  switch (requestServiceSource) {
    case 'speech_rec_request':
      return `${APPS_TAB_PATH.SPEECH_TO_TEXT}/${requestId}`;
    case 'text_to_speech_request':
      return `${APPS_TAB_PATH.TEXT_TO_SPEECH}/${requestId}`;
    case 'voice_clone_training_request':
      return `${APPS_TAB_PATH.VOICE_CLONE}/${requestId}`;
    case 'subscription':
      return `${APPS_TAB_PATH.BILLING}/subscription-history`;
    default:
      return '/';
  }
};
