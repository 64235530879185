import { RequestStatus } from '../types/RequestStatus';
import { SpeechToTextEntry } from '../types/SpeechToTextTypes';

export const speechToTextEntryDetailsMock: SpeechToTextEntry = {
  id: '1',
  title: 'audio_for_test.MP3',
  language: 'en',
  textPreview:
    "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
  textBucketURI: 'mock_result.srt',
  audioBucketURI: 'audio_for_test.MP3',
  audioDurationInMs: 2561000,
  createdAt: 1713676620000,
  updatedAt: 1713676770000,
  status: RequestStatus.SUCCESS,
  creditsUsed: 344,
  errorMessage: '',
};

export const speechToTextEntriesMock: SpeechToTextEntry[] = [
  {
    id: '1',
    title: 'Hello World 1',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.FAILED,
    creditsUsed: 344,
    errorMessage:
      "Error python starting. Error: [Errno 2] No such file or directory: 'a_day_with_poets.mp3'",
  },
  {
    id: '2',
    title: 'Hello World 2',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '3',
    title: 'Hello World 3',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '4',
    title: 'Hello World 4',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '5',
    title: 'Hello World 5',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '6',
    title: 'Hello World 6',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '7',
    title: 'Hello World 7',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '88',
    title: 'Hello World 8',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '8',
    title: 'Hello World 9',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '9',
    title: 'Hello World 10',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '10',
    title: 'Hello World 11',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '11',
    title: 'Hello World 12',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
  {
    id: '12',
    title: 'Hello World 13',
    language: 'en',
    textPreview:
      "I'm always working on several TASTE features simultaneously, typing away at a book manuscript in some form, and there’s never-ending podcast prep basically all the time, so I consider consuming media to be my time for “rest” and I consume some empty calories along the way.",
    textBucketURI: 'https://voicevector-apps.s3.us-east-2.amazonaws.com/hello-world.txt',
    audioBucketURI:
      'https://voice-vector-test-mocks.s3.amazonaws.com/speech-to-text/a_day_with_poets.mp3?AWSAccessKeyId=AKIAV3QCOO2DXWOFTFXS&Signature=FGZBy9zRe0iXTj7mQxq6tPVPHNc%3D&Expires=1719119719',
    audioDurationInMs: 2561000,
    createdAt: 1713676620000,
    updatedAt: 1713676770000,
    status: RequestStatus.SUCCESS,
    creditsUsed: 344,
  },
];
