import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { sendGetRequest } from '../requests/sendRequests';
import { updateRemainingCredits } from '../stores/creditsSlice';
import { logClientError } from '../logging/useLogUserEvent';
import { updateFreeVoiceClonesRemaining } from '../stores/userSlice';

export const useUpdateCredits = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    try {
      const [creditsResponse, freeVoiceClonesResponse] = await Promise.all([
        sendGetRequest({
          requestPath: '/user/credits',
          queryParams: {},
        }),
        sendGetRequest({
          requestPath: '/user/free_voice_clones',
          queryParams: {},
        }),
      ]);

      const { remainingCredits } = creditsResponse as {
        remainingCredits: number;
      };
      const { freeVoiceClones } = freeVoiceClonesResponse as {
        freeVoiceClones: number;
      };

      dispatch(updateFreeVoiceClonesRemaining(freeVoiceClones));
      dispatch(updateRemainingCredits(remainingCredits));
    } catch (e: any) {
      logClientError(
        'Failed to refresh remaining credits in useUpdateCredits: ',
        e,
      );
    }
  }, [dispatch]);
};
