import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/joy';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../stores/stores';
import { ProductType } from '../types/ProductTypes';
import { APPS_TAB_PATH } from '../data/pageLinks';
import ProductDemoCard from '../components/home/ProductDemoCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FlareIcon from '@mui/icons-material/Flare';

import { useLogPageImpression } from '../logging/useLogPageImpression';
import { convertCreditsToUserText } from '../utils/creditUtils';
import { SubscriptionType } from '../types/SubscriptionType';

const HomePageContent = React.memo(() => {
  const demoVideoUrls = useSelector(
    (state: StoreRootState) => state.product.demoVideoUrls,
  );

  const isLoadingCredits = useSelector(
    (state: StoreRootState) => !state.credits.creditsLoaded,
  );

  const remainingCredits = useSelector(
    (state: StoreRootState) => state.credits.remainingCredits,
  );

  const freeVoiceClones = useSelector(
    (state: StoreRootState) => state.user.freeVoiceClonesRemaining,
  );

  const activeSubscriptions = useSelector(
    (state: StoreRootState) => state.subscription.aciveSubscriptions,
  );

  const isSpeechToTextSubscriptionActive = useMemo(() => {
    return activeSubscriptions[SubscriptionType.UNLIMITED_SPEECH_TO_TEXT]
      ? true
      : false;
  }, [activeSubscriptions]);

  const isVoiceCloneAndTextToSpeechSubscriptionActive = useMemo(() => {
    return activeSubscriptions[
      SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
    ]
      ? true
      : false;
  }, [activeSubscriptions]);

  const navigate = useNavigate();

  useLogPageImpression('home_page');

  return (
    <Box width={'100%'}>
      <Box>
        <Stack
          gap={1}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ sm: 'center' }}
        >
          {freeVoiceClones > 0 && (
            <Box
              onClick={() => {
                navigate(APPS_TAB_PATH.VOICE_CLONE);
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  cursor: 'pointer', // You can change this to other cursor types
                },
              }}
            >
              <Chip
                variant='soft'
                color='success'
                startDecorator={<FlareIcon sx={{ width: 24, height: 24 }} />}
                sx={{
                  fontSize: 14,
                  backgroundColor: '#FF6500',
                  color: 'white',
                  marginY: 2,
                  paddingX: 3,
                  paddingY: 0.5,
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                {freeVoiceClones} Free Voice Clones
              </Chip>
            </Box>
          )}
          {(isSpeechToTextSubscriptionActive ||
            isVoiceCloneAndTextToSpeechSubscriptionActive) && (
            <Box
              onClick={() => {
                navigate(APPS_TAB_PATH.BILLING);
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  cursor: 'pointer', // You can change this to other cursor types
                },
              }}
            >
              <Chip
                variant='soft'
                color='success'
                sx={{
                  fontSize: 14,
                  color: 'white',
                  marginY: 2,
                  paddingX: 3,
                  paddingY: 0.5,
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  backgroundColor: '#1F7A1F',
                }}
              >
                Active Subscription
              </Chip>
            </Box>
          )}
        </Stack>

        <Box component={'section'}>
          <Stack
            marginTop={1}
            gap={1}
          >
            <Typography
              level='title-md'
              sx={{ fontSize: 20, fontWeight: 'bold', marginTop: 2 }}
            >
              Credit balance
            </Typography>
            <Stack
              direction='row'
              sx={{ gap: 1 }}
              alignItems={'center'}
            >
              <MonetizationOnIcon color='success' />
              {isLoadingCredits ? (
                <CircularProgress />
              ) : (
                <Typography
                  level='h2'
                  color='neutral'
                  sx={{ fontWeight: 'bold' }}
                >
                  {convertCreditsToUserText(remainingCredits, 2)}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Divider sx={{ marginY: 3 }} />

      <Typography
        level='title-md'
        sx={{ fontSize: 20, fontWeight: 'bold' }}
      >
        Navigation
      </Typography>

      <Grid
        container
        spacing={4}
        marginTop={1}
        marginBottom={4}
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'stretch',
        }}
      >
        <Grid
          sm={12}
          md={4}
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <ProductDemoCard
            featureName='Voice Cloning'
            videoUrl={demoVideoUrls[ProductType.VOICE_CLONING] ?? ''}
            backgroundColor='#f4d06f'
            ctaTitle='Explore'
            description={``}
            onCTAClick={() => {
              navigate(APPS_TAB_PATH.VOICE_CLONE);
            }}
            previewImage='/images/demos/voice_cloning_demo_preview.png'
          />
        </Grid>
        <Grid
          sm={12}
          md={4}
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <ProductDemoCard
            featureName='Text to Speech'
            videoUrl={demoVideoUrls[ProductType.TEXT_TO_SPEECH] ?? ''}
            backgroundColor='#e2eafc'
            ctaTitle='Explore'
            description={``}
            onCTAClick={() => {
              navigate(APPS_TAB_PATH.TEXT_TO_SPEECH);
            }}
            previewImage='/images/demos/text_to_speech_demo_preview.png'
          />
        </Grid>
        <Grid
          sm={12}
          md={4}
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <ProductDemoCard
            featureName='Speech to Text'
            videoUrl={demoVideoUrls[ProductType.SPEECH_TO_TEXT] ?? ''}
            backgroundColor='#e8eddf'
            ctaTitle='Explore'
            description={``}
            onCTAClick={() => {
              navigate(APPS_TAB_PATH.SPEECH_TO_TEXT);
            }}
            previewImage='/images/demos/speech_rec_demo_preview.png'
          />
        </Grid>
      </Grid>
    </Box>
  );
});
HomePageContent.displayName = 'HomePageContent';
export default HomePageContent;
