import { useDispatch, useSelector } from 'react-redux';
import { StoreRootState } from '../stores/stores';
import { useLogUserEvent } from './useLogUserEvent';
import { useEffect, useRef } from 'react';
import { UserEventTypes } from './UserEventTypes';
import { setPageImpressionLogged } from '../stores/loggingSlice';

export const useLogPageImpression = (pageName: string) => {
  const pageImpressionLogged = useSelector(
    (state: StoreRootState) => state.logging.pageImpressionLogged,
  );

  const impressionLoggedInCurrentRenderRef = useRef<boolean>(false);

  const dispatch = useDispatch();

  const logUserEvent = useLogUserEvent();

  useEffect(() => {
    if (
      !pageImpressionLogged[pageName] &&
      !impressionLoggedInCurrentRenderRef.current
    ) {
      impressionLoggedInCurrentRenderRef.current = true;
      dispatch(setPageImpressionLogged(pageName));

      logUserEvent({
        event: UserEventTypes.PAGE_IMPRESSION,
        pageName,
      });
    }
  }, [dispatch, logUserEvent, pageImpressionLogged, pageName]);
};
