import { createSlice } from '@reduxjs/toolkit';

interface PathBreadcumbState {
  pathToBreadcumb: Record<string, string>;
}

const initialState: PathBreadcumbState = {
  pathToBreadcumb: {},
};

export const pathBreadcumbSlice = createSlice({
  name: 'pathBreadcumb',
  initialState,
  reducers: {
    updatePathBreadcumb: (state, action) => {
      const data: Record<string, string> = action.payload;

      Object.entries(data).forEach(([path, breadcumb]) => {
        state.pathToBreadcumb[path] = breadcumb;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePathBreadcumb } = pathBreadcumbSlice.actions;

export default pathBreadcumbSlice.reducer;
