import { Card, CardContent, Typography } from '@mui/joy';
import React from 'react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';

const BillingStatusCard = React.memo(
  ({ billingStatus }: { billingStatus: string }) => {
    return (
      <Card
        variant='soft'
        color='success'
        invertedColors
        sx={{ width: '100%', marginTop: 4, marginBottom: 4 }}
      >
        <CardContent
          orientation='horizontal'
          sx={{ alignItems: 'center' }}
        >
          {billingStatus === 'success' ? (
            <EmojiEmotionsOutlinedIcon sx={{ width: 48, height: 48 }} />
          ) : (
            <SentimentVeryDissatisfiedOutlinedIcon
              sx={{ width: 48, height: 48 }}
            />
          )}
          <CardContent>
            <Typography
              level='body-lg'
              fontWeight={'bold'}
            >
              {billingStatus === 'success'
                ? 'Woohoo! Your new credits have been successfully added!'
                : "Uh-oh! Something went awry. Give it another shot a bit later. We'll get it sorted!"}
            </Typography>
            {billingStatus === 'success' && (
              <Typography
                level='body-md'
                marginTop={1}
              >
                Refresh the page to see the updated credit balance.
              </Typography>
            )}
          </CardContent>
        </CardContent>
      </Card>
    );
  },
);
BillingStatusCard.displayName = 'BillingStatusCard';
export default BillingStatusCard;
