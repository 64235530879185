/* eslint-disable no-unused-vars */

export const HOME_PAGE_URL = 'https://www.voice-vector.com';

export const TERMS_OF_SERVICE_URL = `${HOME_PAGE_URL}/terms`;

export const LOGOUT_DESTINATION_URL = `${HOME_PAGE_URL}/signin`;

export enum APPS_TAB_PATH {
  HOME_SHORT = '/',
  HOME = '/home',
  SPEECH_TO_TEXT = '/speech-to-text',
  TEXT_TO_SPEECH = '/text-to-speech',
  VOICE_CLONE = '/voice-clone',
  BILLING = '/billing',
  PRICING = '/pricing',
}

export const PATH_TO_BREADCRUMB = {
  [APPS_TAB_PATH.HOME_SHORT]: 'Home',
  [APPS_TAB_PATH.HOME]: 'Home',
  [APPS_TAB_PATH.SPEECH_TO_TEXT]: 'Speech to Text',
  [APPS_TAB_PATH.TEXT_TO_SPEECH]: 'Text to Speech',
  [APPS_TAB_PATH.VOICE_CLONE]: 'Voice Cloning',
  [APPS_TAB_PATH.BILLING]: 'Billing',
  [APPS_TAB_PATH.PRICING]: 'Pricing',
};
