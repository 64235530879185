import {
  Box,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Table,
  Tooltip,
  Typography,
} from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { sendPostRequest } from '../../requests/sendRequests';
import { getDateString, getTimeString } from '../../utils/timeUtils';
import {
  convertCreditsToUserText,
  mapServiceSourceToText,
} from '../../utils/creditUtils';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { CreditsActivity } from '../../types/CreditsActivity';
import { useNavigate } from 'react-router-dom';
import { getRequestUrl } from '../../utils/urlUtils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { logClientError } from '../../logging/useLogUserEvent';

interface CreditsActivitiesLogTableProps {
  // eslint-disable-next-line no-unused-vars
  showSnackMessage?: (message: string, isError: boolean) => void;
}

const PAGINATION_SIZE = 20;

interface ListItemsPaginationResponse {
  items: CreditsActivity[];
  lastKey: any;
}

const SubscriptionActivityIcon = React.memo(
  ({ tooltip }: { tooltip: string }) => {
    const [subscriptionTooltipOpen, setSubscriptionTooltipOpen] =
      useState(false);

    return (
      <Tooltip
        title={tooltip}
        sx={{ maxWidth: 500 }}
        variant='soft'
        open={subscriptionTooltipOpen}
        leaveTouchDelay={3000}
        onClose={() => setSubscriptionTooltipOpen(false)}
      >
        <LightbulbIcon
          sx={{ color: '#9E9FA5' }}
          onClick={() => setSubscriptionTooltipOpen(true)}
          onMouseEnter={() => setSubscriptionTooltipOpen(true)}
        />
      </Tooltip>
    );
  },
);
SubscriptionActivityIcon.displayName = 'SubscriptionActivityIcon';

const CreditsActivitiesLogTable = React.memo(
  ({ showSnackMessage }: CreditsActivitiesLogTableProps) => {
    const navigate = useNavigate();

    const [creditsActivityEntries, setCreditsActivityEntries] = useState<
      CreditsActivity[]
    >([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [paginationLastKey, setPaginationLastKey] = useState<any>(null);
    const initialLoadCompletedRef = React.useRef(false);

    // Initial Loading
    useEffect(() => {
      const initialLoad = async () => {
        if (initialLoadCompletedRef.current) {
          return;
        }

        initialLoadCompletedRef.current = true;

        setIsLoadingHistory(true);
        try {
          const response = await sendPostRequest({
            requestPath: '/user/credits_activity_history',
            payload: {
              pageSize: PAGINATION_SIZE,
            },
          });

          const paginationResp = response as ListItemsPaginationResponse;
          setCreditsActivityEntries((prevEntries) => [
            ...paginationResp.items,
            ...prevEntries,
          ]);
          setPaginationLastKey(paginationResp.lastKey);
        } catch (e: any) {
          logClientError('Failed to fetch credits activities: ', e);
          showSnackMessage?.(
            'Failed to fetch your credits activities. Please try again.',
            true,
          );
        } finally {
          setIsLoadingHistory(false);
        }
      };

      initialLoad();
    }, [showSnackMessage]);

    const loadMoreItems = useCallback(async () => {
      if (!paginationLastKey) {
        return;
      }

      try {
        setIsLoadingHistory(true);
        const response = await sendPostRequest({
          requestPath: '/user/credits_activity_history',
          payload: {
            lastKey: paginationLastKey,
            pageSize: PAGINATION_SIZE,
          },
        });

        const paginationResp = response as ListItemsPaginationResponse;

        setCreditsActivityEntries((prevEntries) => [
          ...prevEntries,
          ...paginationResp.items,
        ]);
        setPaginationLastKey(paginationResp.lastKey);
      } catch (e: any) {
        logClientError('Failed to fetch more credits activities: ', e);
        showSnackMessage?.(
          'Failed to fetch more of your credits activities. Please try again.',
          true,
        );
      } finally {
        setIsLoadingHistory(false);
      }
    }, [paginationLastKey, showSnackMessage]);

    const openRequest = useCallback(
      (source: string, requestId: string) => {
        navigate(getRequestUrl(source, requestId));
        window.dispatchEvent(new Event('pushstateInBillingPage')); // without this, we cannot switch to another tab if the request url is going to another tab in the same page (i.e., billing page). This event is listened in BillingPageContent.
      },
      [navigate],
    );

    const wrapHeaderStyle: React.CSSProperties = useMemo(() => {
      return {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
      };
    }, []);

    const isRequestHandledBySubscription = useCallback(
      (activity: CreditsActivity) => {
        return (
          (activity.source === 'speech_rec_request' ||
            activity.source === 'text_to_speech_request' ||
            activity.source === 'voice_clone_training_request') &&
          activity.subscriptionName
        );
      },
      [],
    );

    return (
      <Box width={'100%'}>
        <Table
          aria-label='table sizes'
          size='lg'
        >
          <thead>
            <tr>
              <th style={wrapHeaderStyle}>Date</th>
              <th style={wrapHeaderStyle}>Activity</th>
              <th style={wrapHeaderStyle}>Credits Used</th>
              <th style={wrapHeaderStyle}>Request</th>
            </tr>
          </thead>
          <tbody>
            {creditsActivityEntries.map((activityEntry: CreditsActivity) => (
              <tr key={activityEntry.id}>
                <td>{`${getDateString(activityEntry.timestamp)} ${getTimeString(
                  activityEntry.timestamp,
                )}`}</td>
                <td>
                  <Chip
                    variant='soft'
                    color='success'
                    sx={{
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    {mapServiceSourceToText(activityEntry.source)}
                  </Chip>
                </td>
                <td>
                  {isRequestHandledBySubscription(activityEntry) ? (
                    <SubscriptionActivityIcon tooltip='This request was covered by your subscription. No pay-as-you-go credits were used.' />
                  ) : (
                    convertCreditsToUserText(activityEntry.credits)
                  )}
                </td>
                <td>
                  <Link
                    component='button'
                    onClick={() =>
                      openRequest(activityEntry.source, activityEntry.id)
                    }
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!isLoadingHistory && paginationLastKey && (
          <Box
            display='flex'
            justifyContent='center'
            marginTop={2}
            marginBottom={8}
          >
            <Box
              display='flex'
              flexDirection={'column'}
              alignItems={'center'}
              onClick={loadMoreItems}
            >
              <ExpandMoreIcon sx={{ width: 36, height: 36 }} />
              <Typography
                color='neutral'
                level='title-md'
              >
                Load more
              </Typography>
            </Box>
          </Box>
        )}
        {isLoadingHistory && (
          <Stack
            width={'100%'}
            direction={'row'}
            justifyContent={'center'}
            marginTop={3}
          >
            <CircularProgress sx={{ width: 120, height: 120 }} />
          </Stack>
        )}
      </Box>
    );
  },
);
CreditsActivitiesLogTable.displayName = 'CreditsActivitiesLogTable';
export default CreditsActivitiesLogTable;
