import {
  Box,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
  LinearProgress,
} from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import SpeechToTextEntryCard from '../components/speech_to_text/SpeechToTextEntryCard';
import { SpeechToTextEntry } from '../types/SpeechToTextTypes';
import NewSpeechToTextRequestModal from '../components/speech_to_text/NewSpeechToTextRequestModal';
import { sendPostRequest } from '../requests/sendRequests';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RequestStatus } from '../types/RequestStatus';
import { useLogPageImpression } from '../logging/useLogPageImpression';
import { logClientError, useLogUserEvent } from '../logging/useLogUserEvent';
import { UserEventTypes } from '../logging/UserEventTypes';
import { useUpdateCredits } from '../hooks/useUpdateCredits';
import DataDeletionReminder from '../components/shared/DataDeletionReminder';

const PAGINATION_SIZE = 10;

interface ListItemsPaginationResponse {
  items: SpeechToTextEntry[];
  lastKey: any;
}

const SpeechToTextPageContent = React.memo(() => {
  const [transcriptEnries, setTranscriptEnries] = useState<SpeechToTextEntry[]>(
    [],
  );
  const transcriptEnriesRef =
    React.useRef<SpeechToTextEntry[]>(transcriptEnries);
  useEffect(() => {
    transcriptEnriesRef.current = transcriptEnries;
  }, [transcriptEnries]);

  const [paginationLastKey, setPaginationLastKey] = useState<any>(null);

  const [isInitializingPage, setIsInitializingPage] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const initialLoadCompletedRef = React.useRef(false);
  const isPollingStatusRef = React.useRef(false);

  const [isNewRequestDialogOpen, setIsNewRequestDialogOpen] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState<
    'success' | 'warning' | 'danger'
  >('danger');

  const logUserEvent = useLogUserEvent();

  const updateUserCredits = useUpdateCredits();

  useLogPageImpression('speech_to_text_page');

  /********************************************************************************************************
   * Load the initial list of speech to text requests when page is rendering
   ******************************************************************************************************* */
  useEffect(() => {
    const loadList = async () => {
      if (initialLoadCompletedRef.current) {
        return;
      }

      initialLoadCompletedRef.current = true;

      try {
        const response = await sendPostRequest({
          requestPath: '/speech_recognition/requests_list',
          payload: {
            pageSize: PAGINATION_SIZE,
          },
        });

        const paginationResp = response as ListItemsPaginationResponse;
        setTranscriptEnries((prevEntries) => [
          ...paginationResp.items,
          ...prevEntries,
        ]);
        setPaginationLastKey(paginationResp.lastKey);
      } catch (e: any) {
        logClientError(
          'Failed to fetch list items in SpeechToTextPageContent: ',
          e,
        );
        setSnackBarColor('danger');
        setSnackBarContent(
          'Failed to fetch your requests list. Please try again.',
        );
        setSnackbarOpen(true);
      } finally {
        setIsInitializingPage(false);
      }
    };

    loadList();
  }, []);

  /********************************************************************************************************
   * Poll the latest status of speech to text requests whose status is PENDING or PROCESSING
   ******************************************************************************************************* */
  const pollLatestStatus = useCallback(async () => {
    if (isPollingStatusRef.current) {
      return;
    }

    isPollingStatusRef.current = true;

    try {
      const requestsToCheck = transcriptEnriesRef.current;
      const requestIdsToCheck = requestsToCheck
        .filter(
          (r) =>
            r.status === RequestStatus.PENDING ||
            r.status === RequestStatus.PROCESSING,
        )
        .map((request) => request.id);

      if (requestIdsToCheck.length === 0) {
        return;
      }

      const response = await sendPostRequest({
        requestPath: '/speech_recognition/query_requests',
        payload: {
          request_ids: requestIdsToCheck,
        },
      });
      const requestsResp = response as SpeechToTextEntry[];

      if (requestsResp.length === 0) {
        return;
      }

      let statusUpdated = false;
      let updateCredits = false;
      const newTranscriptEntries = [...transcriptEnriesRef.current];

      const requestsMap = new Map();
      for (const entry of requestsResp) {
        requestsMap.set(entry.id, entry);
      }

      for (let i = 0; i < newTranscriptEntries.length; i++) {
        const entry = newTranscriptEntries[i];
        if (requestsMap.has(entry.id)) {
          const requestEntry = requestsMap.get(entry.id);
          // Check if the status is different and then update
          if (entry.status !== requestEntry.status) {
            newTranscriptEntries[i] = { ...entry, ...requestEntry };
            statusUpdated = true;

            if (requestEntry.status === RequestStatus.SUCCESS) {
              updateCredits = true;
            }
          }
        }
      }

      if (statusUpdated) {
        setTranscriptEnries(newTranscriptEntries);
      }

      if (updateCredits) {
        updateUserCredits();
      }
    } catch (e: any) {
      logClientError(
        'Failed to poll requests latest status in SpeechToTextPageContent: ',
        e,
      );
    } finally {
      isPollingStatusRef.current = false;
    }
  }, [updateUserCredits]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      pollLatestStatus();
    }, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup function to clear the interval when the component unmounts or dependencies change
    return () => clearInterval(intervalId);
  }, [pollLatestStatus]); // Dependencies array, pollLatestStatus should be stable across re-renders

  /********************************************************************************************************
   * User action callbacks
   ******************************************************************************************************* */

  const handleRequestDialogOpen = useCallback(() => {
    setIsNewRequestDialogOpen(true);

    logUserEvent({
      event: UserEventTypes.SPEECH_TO_TEXT_REQUEST_BUTTON_CLICK,
    });
  }, [logUserEvent]);

  const handleRequestDialogClose = useCallback(
    ({ createdRequest }: { createdRequest?: SpeechToTextEntry }) => {
      setIsNewRequestDialogOpen(false);

      if (!createdRequest) {
        return;
      }

      setTranscriptEnries([createdRequest, ...transcriptEnries]);
      setSnackBarColor('success');
      setSnackBarContent('Speech to text request created successfully');
      setSnackbarOpen(true);
    },
    [transcriptEnries],
  );

  const loadMoreItems = useCallback(async () => {
    if (!paginationLastKey) {
      return;
    }

    try {
      setIsLoadingMore(true);
      const response = await sendPostRequest({
        requestPath: '/speech_recognition/requests_list',
        payload: {
          pageSize: PAGINATION_SIZE,
          lastKey: paginationLastKey,
        },
      });

      const paginationResp = response as ListItemsPaginationResponse;
      setTranscriptEnries((prevEntries) => [
        ...prevEntries,
        ...paginationResp.items,
      ]);
      setPaginationLastKey(paginationResp.lastKey);
    } catch (e: any) {
      logClientError(
        'Failed to fetch more items in SpeechToTextPageContent: ',
        e,
      );
      setSnackBarColor('danger');
      setSnackBarContent(
        'Failed to fetch more of your requests. Please try again.',
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadingMore(false);
    }
  }, [paginationLastKey]);

  /********************************************************************************************************
   * UX Components
   ******************************************************************************************************* */

  const loadingPage = useMemo(
    () => (
      <Box
        display='flex'
        justifyContent='center'
        marginTop={20}
      >
        <Box
          display='flex'
          flexDirection={'column'}
          alignItems={'center'}
        >
          <CircularProgress
            color='primary'
            sx={{ '--CircularProgress-size': '60px' }}
          />

          <Typography
            color='neutral'
            level='title-lg'
            marginTop={3}
          >
            Loading ...
          </Typography>
        </Box>
      </Box>
    ),
    [],
  );

  const listPage = useMemo(() => {
    const itemsList = transcriptEnries.map((transcriptEntry) => (
      <Box
        key={transcriptEntry.id}
        marginBottom={4}
        sx={{ width: '100%' }}
      >
        <SpeechToTextEntryCard entry={transcriptEntry} />
      </Box>
    ));

    const loadingMore =
      !isLoadingMore && paginationLastKey ? (
        <Box
          display='flex'
          justifyContent='center'
          marginTop={2}
          marginBottom={12}
        >
          <Box
            display='flex'
            flexDirection={'column'}
            alignItems={'center'}
            onClick={loadMoreItems}
          >
            <ExpandMoreIcon sx={{ width: 36, height: 36 }} />
            <Typography
              color='neutral'
              level='title-md'
            >
              Load more
            </Typography>
          </Box>
        </Box>
      ) : null;

    const loadingMoreProgress = isLoadingMore ? (
      <LinearProgress sx={{ width: '100%', marginTop: 2, marginBottom: 12 }} />
    ) : null;

    return (
      <Box
        display='flex'
        flexDirection={'column'}
        alignItems={'center'}
      >
        {itemsList.length > 0 && <DataDeletionReminder />}
        {itemsList}
        {loadingMore}
        {loadingMoreProgress}
      </Box>
    );
  }, [transcriptEnries, paginationLastKey, isLoadingMore, loadMoreItems]);

  return (
    <Box width={'100%'}>
      <Typography level='h3'>Speech to Text</Typography>

      <Box
        display='flex'
        flexDirection={'row'}
        justifyContent={'center'}
        paddingTop={4}
        textAlign='center'
      >
        <Typography
          level='h4'
          sx={{
            width: {
              xs: '100%', // 100% width on extra-small screens
              sm: '75%', // 75% width on small screens and above
            },
            // maxWidth: '680px',
          }}
          color='neutral'
        >
          Effortlessly Convert Your Pre-Recorded Audio to Text: Upload and Let
          Our Advanced Speech Recognition Do the Rest!
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection={'row'}
        justifyContent={'center'}
        paddingTop={3}
        marginBottom={8}
      >
        <Button
          onClick={handleRequestDialogOpen}
          variant='solid'
          color='primary'
          startDecorator={<BackupOutlinedIcon />}
          sx={{ minWidth: '50%', fontSize: 18 }}
          disabled={isInitializingPage}
        >
          Upload Audio
        </Button>
      </Box>

      {isInitializingPage ? loadingPage : listPage}

      <NewSpeechToTextRequestModal
        isOpen={isNewRequestDialogOpen}
        handleClose={handleRequestDialogClose}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant='solid'
        color={snackBarColor}
        invertedColors
        startDecorator={<BubbleChartRoundedIcon />}
        autoHideDuration={5000}
      >
        <Typography level='title-lg'>{snackBarContent}</Typography>
      </Snackbar>
    </Box>
  );
});
SpeechToTextPageContent.displayName = 'SpeechToTextPageContent';
export default SpeechToTextPageContent;
