import { createSlice } from '@reduxjs/toolkit';

interface LoggingState {
  pageImpressionLogged: Record<string, boolean>;
}

const initialState: LoggingState = {
  pageImpressionLogged: {},
};

export const loggingSlice = createSlice({
  name: 'logging',
  initialState,
  reducers: {
    setPageImpressionLogged: (state, action) => {
      const pageName = action.payload as string;
      state.pageImpressionLogged[pageName] = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageImpressionLogged } = loggingSlice.actions;

export default loggingSlice.reducer;
