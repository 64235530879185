import { createSlice } from '@reduxjs/toolkit';
import { SpeechToTextEntry } from '../types/SpeechToTextTypes';

interface SpeechToTextState {
  speechToTextList: SpeechToTextEntry[];
  speechToTextDetailsMap: Record<string, SpeechToTextEntry>;
  initialLoadCompleted: boolean;
}

const initialState: SpeechToTextState = {
  speechToTextList: [],
  speechToTextDetailsMap: {},
  initialLoadCompleted: false,
};

export const speechToTextSlice = createSlice({
  name: 'speechToText',
  initialState,
  reducers: {
    initializeSpeechToTextList: (_state, _action) => {
      // TODO: not used now
    },
  },
});

// Action creators are generated for each case reducer function
export const { initializeSpeechToTextList } = speechToTextSlice.actions;

export default speechToTextSlice.reducer;
