import {
  Box,
  Typography,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Select,
  Option,
} from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import BillingOverviewTab from '../components/billing/tabs/BillingOverviewTab';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../data/configAndSecrets';
import BillingHistoryTab from '../components/billing/tabs/BillingHistoryTab';
import CreditsActivitiesTab from '../components/billing/tabs/CreditsActivitiesTab';
import { useLogPageImpression } from '../logging/useLogPageImpression';
import SubscriptionHistoryTab from '../components/billing/tabs/SubscriptionHistoryTab';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const BillingPageContent = React.memo(() => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener('pushstateInBillingPage', handleLocationChange);

    return () => {
      window.removeEventListener(
        'pushstateInBillingPage',
        handleLocationChange,
      );
    };
  }, []);

  const tabUrls = useMemo(
    () => [
      '/billing',
      '/billing/payment-history',
      '/billing/subscription-history',
      '/billing/activity',
    ],
    [],
  );

  useLogPageImpression('billing_page');

  const getTabIndex = useCallback(
    (pathname: string) => {
      return tabUrls.indexOf(pathname);
    },
    [tabUrls],
  );

  const [selectedTab, setSelectedTab] = useState(getTabIndex(currentPath));

  const handleTabSwitch = useCallback(
    (tabIndex: number) => {
      setSelectedTab(tabIndex);
      window.history.pushState({}, '', tabUrls[tabIndex]);
      window.dispatchEvent(new Event('pushstateInBillingPage'));
    },
    [tabUrls],
  );

  useEffect(() => {
    const targetIndex = getTabIndex(currentPath);
    setSelectedTab(targetIndex >= 0 ? targetIndex : 0);
  }, [currentPath, getTabIndex]);

  // Check screen size
  const [isMediumOrSmaller, setIsMediumOrSmaller] = useState(
    window.innerWidth <= 600,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMediumOrSmaller(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMediumOrSmaller) {
    return (
      <Box width={'100%'}>
        <Elements stripe={stripePromise}>
          <Typography level='h3'>Billing</Typography>

          <Select
            value={selectedTab}
            onChange={(
              _event: React.SyntheticEvent | null,
              newValue: number | null,
            ) => handleTabSwitch(newValue!)}
            sx={{ marginTop: 2, backgroundColor: 'transparent', width: '100%' }}
          >
            <Option value={0}>Overview</Option>
            <Option value={1}>Payment History</Option>
            <Option value={2}>Subscription History</Option>
            <Option value={3}>Activity Log</Option>
          </Select>

          <Box marginTop={3}>
            {selectedTab === 0 && <BillingOverviewTab />}
            {selectedTab === 1 && <BillingHistoryTab />}
            {selectedTab === 2 && <SubscriptionHistoryTab />}
            {selectedTab === 3 && <CreditsActivitiesTab />}
          </Box>
        </Elements>
      </Box>
    );
  }

  return (
    <Box width={'100%'}>
      <Elements stripe={stripePromise}>
        <Typography level='h3'>Billing</Typography>

        <Tabs
          aria-label='Billing tabs'
          value={selectedTab}
          onChange={(_, value) => handleTabSwitch(value as number)}
          size='md'
          sx={{ marginTop: 2, backgroundColor: 'transparent' }}
        >
          <TabList>
            <Tab
              variant='soft'
              color={selectedTab === 0 ? 'primary' : 'neutral'}
            >
              Overview
            </Tab>
            <Tab
              variant='soft'
              color={selectedTab === 1 ? 'primary' : 'neutral'}
            >
              Payment History
            </Tab>
            <Tab
              variant='soft'
              color={selectedTab === 2 ? 'primary' : 'neutral'}
            >
              Subscription History
            </Tab>
            <Tab
              variant='soft'
              color={selectedTab === 3 ? 'primary' : 'neutral'}
            >
              Activity Log
            </Tab>
          </TabList>
          <TabPanel value={0}>
            <BillingOverviewTab />
          </TabPanel>
          <TabPanel value={1}>
            <BillingHistoryTab />
          </TabPanel>
          <TabPanel value={2}>
            <SubscriptionHistoryTab />
          </TabPanel>
          <TabPanel value={3}>
            <CreditsActivitiesTab />
          </TabPanel>
        </Tabs>
      </Elements>
    </Box>
  );
});
BillingPageContent.displayName = 'BillingPageContent';
export default BillingPageContent;
