import { Stack, Typography } from '@mui/joy';
import React, { useEffect, useRef } from 'react';
import { VoiceModel } from '../../types/VoiceModel';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded';
import { logClientError } from '../../logging/useLogUserEvent';

interface VoiceModelLineProps {
  model: VoiceModel;
  playPreviewVoice: boolean;
  // eslint-disable-next-line no-unused-vars
  onPlayClicked?: (modelId: string) => void;
}

const VoiceModelLine = React.memo(
  ({ model, playPreviewVoice, onPlayClicked }: VoiceModelLineProps) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const loadedRef = useRef(false);

    useEffect(() => {
      if (playPreviewVoice) {
        if (!loadedRef.current) {
          // Only initialize Audio when playPreviewVoice first switches to true
          loadedRef.current = true;
          audioRef.current = new Audio(model.previewAudioURI);
        }

        audioRef.current
          ?.play()
          ?.catch((e) =>
            logClientError('Failed to play audio in VoiceModelLine:', e),
          );
      } else if (audioRef.current) {
        audioRef.current.pause();
      }

      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.src = ''; // Release the audio resources
          audioRef.current = null;
          loadedRef.current = false;
        }
      };
    }, [playPreviewVoice, model.previewAudioURI]);

    const handlePlayClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ): void => {
      e.stopPropagation(); // Stop the event from bubbling up
      if (onPlayClicked) {
        onPlayClicked(model.modelId);
      }
    };

    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={1}
        >
          <Stack
            onClick={handlePlayClick}
            direction={'row'}
            alignItems={'center'}
          >
            {playPreviewVoice ? (
              <PauseCircleRoundedIcon />
            ) : (
              <PlayCircleFilledRoundedIcon />
            )}
          </Stack>
          <Typography level='body-sm'>{model.modelName}</Typography>
        </Stack>
        <Typography level='body-sm'>{` (${model.gender})`}</Typography>
      </Stack>
    );
  },
);
VoiceModelLine.displayName = 'VoiceModelLine';
export default VoiceModelLine;
