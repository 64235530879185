import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
// import { UploadPart } from '../types/UploadPart';

type POST_REQUEST_BODY =
  | null
  | boolean
  | number
  | string
  | POST_REQUEST_BODY[]
  | any
  // | UploadPart
  | {
      [prop: string]: POST_REQUEST_BODY;
    };

// In-memory cache for the token and its expiration
let cachedIdToken: string | null = null;
let tokenExpiration = 0;

const getRequestAuthToken = async () => {
  const currentTime = Date.now();

  // Check if the cached token is valid
  if (cachedIdToken && currentTime < tokenExpiration) {
    return cachedIdToken;
  }

  // Fetch a new session if the token is not cached or has expired
  const session = await fetchAuthSession();
  const { idToken } = session.tokens ?? {};

  if (!idToken) {
    throw new Error('Authorization expired. Please login again.');
  }

  const expirationDate = idToken.payload.exp
    ? idToken.payload.exp - 30 * 60
    : 0; // Expire in 30 minutes ahead of the expected expiration
  tokenExpiration = expirationDate * 1000; // Convert seconds to milliseconds

  cachedIdToken = idToken.toString();
  return cachedIdToken;
};

export const sendGetRequest = async ({
  requestPath,
  queryParams,
}: {
  requestPath: string;
  queryParams?: Record<string, string>;
}) => {
  const idTokenString = await getRequestAuthToken();

  try {
    const restOperation = get({
      apiName: 'VoiceVectorApp',
      path: requestPath,
      options: {
        headers: {
          Authorization: `Bearer ${idTokenString}`,
        },
        queryParams,
      },
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    return json as unknown;
  } catch (error: any) {
    if (error.response && error.response.body) {
      throw new Error(error.response.body.toString());
    } else {
      throw error;
    }
  }
};

export const uploadFileToS3 = async ({
  presignedUrl,
  fileToUpload,
}: {
  presignedUrl: string;
  fileToUpload: File;
}) => {
  const fileUploadResponse = await fetch(presignedUrl, {
    method: 'PUT',
    body: fileToUpload,
    headers: {
      'Content-Type': fileToUpload.type,
    },
  });

  if (!fileUploadResponse.ok) {
    throw new Error('File upload failed');
  }
};

export const sendPostRequest = async ({
  requestPath,
  payload,
}: {
  requestPath: string;
  payload?: POST_REQUEST_BODY;
}) => {
  const idTokenString = await getRequestAuthToken();

  try {
    const restOperation = post({
      apiName: 'VoiceVectorApp',
      path: requestPath,
      options: {
        headers: {
          Authorization: `Bearer ${idTokenString}`,
        },
        body: payload,
      },
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    return json as unknown;
  } catch (error: any) {
    if (error.response && error.response.body) {
      throw new Error(error.response.body.toString());
    } else {
      throw error;
    }
  }
};
