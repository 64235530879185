import { useCallback, useEffect } from 'react';
import { sendPostRequest } from '../requests/sendRequests';
import { UserEventTypes } from './UserEventTypes';
import { useBrowserFingerPrint } from './useBrowserFingerPrint';
import { useUserLocation } from './useUserLocation';
import { UserLocation } from '../types/UserLocation';
import { v4 as uuidv4 } from 'uuid';

const MAX_BUFFER_SIZE = 10;
const LOGGING_INTERVAL_MS = 10000;
let eventQueue: any[] = [];
let browserFingerPrintToLog = '';
let userLocationToLog: UserLocation = {
  country: '',
  region: '',
  city: '',
  lastLocationUpdateTime: 0,
};

const CURRENT_USER_SESSION_ID = uuidv4();

interface LogUserEventOptions {
  logImmediately?: boolean;
  logBrowerserFingerPrint?: boolean;
  logUserLocation?: boolean;
}

const logEventsToServer = () => {
  if (eventQueue.length === 0) return;

  const eventsToLog = [...eventQueue];
  eventQueue = [];

  try {
    sendPostRequest({
      requestPath: '/user/events',
      payload: eventsToLog,
    })
      .then(() => {
        // Request succeeded, no need to do anything
      })
      .catch((e: any) => {
        console.error('Failed to log user events: ', e);
        // Requeue the events if logging fails
        eventQueue = [...eventsToLog, ...eventQueue];
      });
  } catch (e: any) {
    console.error('Failed to send user events: ', e);
    // requeue the events if logging fails
    eventQueue = [...eventsToLog, ...eventQueue];
  }
};

const submitUserEvent = (event: any, options: LogUserEventOptions) => {
  const { logImmediately, logBrowerserFingerPrint, logUserLocation } = options;
  const eventWithMetaData = {
    ...event,
    sessionId: CURRENT_USER_SESSION_ID,
    timestamp: Date.now(),
    browserFingerPrint: logBrowerserFingerPrint
      ? browserFingerPrintToLog
      : undefined,
    userCountry: logUserLocation ? userLocationToLog.country : undefined,
    userRegion: logUserLocation ? userLocationToLog.region : undefined,
    userCity: logUserLocation ? userLocationToLog.city : undefined,
  };
  eventQueue.push(eventWithMetaData);

  if (logImmediately || eventQueue.length >= MAX_BUFFER_SIZE) {
    logEventsToServer();
  }
};

// Function to handle visibility change
const handleVisibilityChange = () => {
  if (document.visibilityState === 'hidden') {
    logEventsToServer();
  }
};

// Set up interval to log events every 10 seconds
setInterval(() => {
  logEventsToServer();
}, LOGGING_INTERVAL_MS);

// Set up event listener for when the tab is closed or becomes a background tab
document.addEventListener('visibilitychange', handleVisibilityChange);

// Set up event listener for when the tab is unloaded, including:
// - when the browser is closed
// - when the tab is refreshed
// - When the user clicks a link that navigates to a different page within the same tab.
// - Actions that cause the page to unload, such as submitting a form that navigates to a new page, or clicking on a link or button that triggers navigation.
window.addEventListener('beforeunload', (_event) => {
  submitUserEvent(
    {
      event: UserEventTypes.APP_PAGE_SESSION_END,
      userAgent: navigator.userAgent,
    },
    {
      logImmediately: true,
      logBrowerserFingerPrint: true,
      logUserLocation: true,
    },
  );
});

export const useLogUserEvent = () => {
  const { currentFingerPrint } = useBrowserFingerPrint();
  const { currentLocation } = useUserLocation();

  useEffect(() => {
    browserFingerPrintToLog = currentFingerPrint;
    userLocationToLog = currentLocation;
  }, [currentFingerPrint, currentLocation]);

  return useCallback((event: any, options?: LogUserEventOptions) => {
    submitUserEvent(event, options ?? {});
  }, []);
};

export const logClientError = (message: string, error: any) => {
  try {
    submitUserEvent(
      {
        event: UserEventTypes.CLIENT_ERROR,
        message,
        error: error ? error.toString() : '',
      },
      {},
    );
  } catch (e: any) {
    console.error('Failed to log client error: ', e);
  }
};
