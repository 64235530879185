import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SpeechToTextEntry } from '../types/SpeechToTextTypes';
import { sendPostRequest } from '../requests/sendRequests';
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/joy';
import { useDispatch } from 'react-redux';
import { updatePathBreadcumb } from '../stores/pathBreadcumbSlice';
import TimeRow from '../components/shared/TimeRow';
import StatusRow from '../components/shared/StatusRow';
import NoResultFoundContent from '../components/shared/NoResultFoundContent';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import DownloadIcon from '@mui/icons-material/Download';

import { speechToTextEntryDetailsMock } from '../mock/SpeechToTextMocks';
import AudioMediaPlayer from '../components/shared/AudioMediaPlayer';
import AudioTranscriptionViewer from '../components/shared/AudioTranscriptionViewer';
import { AudioTranscript } from '../types/AudioTranscript';
import {
  downloadTranscriptsArrayAsSrt,
  downloadTranscriptsArrayAsTxt,
} from '../utils/transcriptUtils';
import { RequestStatus } from '../types/RequestStatus';
import { logClientError } from '../logging/useLogUserEvent';

const SpeechToTextDetailsPage = React.memo(() => {
  const { itemId } = useParams();
  const [itemDetails, setItemDetails] = useState<SpeechToTextEntry | null>(
    null,
  );
  const [itemDetailsLoaded, setItemDetailsLoaded] = useState(false);
  const isFetchingDetailsRef = useRef(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState<
    'success' | 'warning' | 'danger'
  >('danger');

  /********************************************************************************************************
   * Load the details of the speech to text request
   ******************************************************************************************************* */
  const loadItemDetails = useCallback(async () => {
    if (isFetchingDetailsRef.current) {
      return;
    }

    isFetchingDetailsRef.current = true;

    try {
      const response = await sendPostRequest({
        requestPath: '/speech_recognition/query_requests',
        payload: {
          request_ids: [itemId as string],
        },
      });
      const requestsResp = response as SpeechToTextEntry[];

      if (requestsResp.length === 0) {
        return;
      }

      setItemDetails(requestsResp[0]);

      // Dispatch the breadcrumb to show when this page is displayed
      dispatch(
        updatePathBreadcumb({ [location.pathname]: requestsResp[0].title }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logClientError(
        'Failed to poll requests latest status in SpeechToTextDetailsPage: ',
        e,
      );
    } finally {
      setItemDetailsLoaded(true);
      isFetchingDetailsRef.current = false;
    }
  }, [itemId, dispatch, location.pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (false) {
      // TODO: Remove the mock in this if statement
      setItemDetails(speechToTextEntryDetailsMock);
      setItemDetailsLoaded(true);
      dispatch(
        updatePathBreadcumb({
          [location.pathname]: speechToTextEntryDetailsMock.title,
        }),
      );
    } else {
      loadItemDetails();
    }
  }, [loadItemDetails, dispatch, location.pathname]);

  /********************************************************************************************************
   * Load the presigned url for the audio and srt
   ******************************************************************************************************* */

  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [srtUrl, setSrtUrl] = useState<string | null>(null);
  const [generatingUrls, setGeneratingUrls] = useState(false);
  const isGeneratingPresingedUrlRef = useRef(false);

  const [currentAudioPlayTimeInSec, setCurrentAudioPlayTimeInSec] = useState(0);

  const [audioTranscripts, setAudioTranscripts] = useState<
    AudioTranscript[] | null
  >(null);

  useEffect(() => {
    if (!itemDetails) {
      return;
    }

    const genUrlForAudio = async () => {
      const response = await sendPostRequest({
        requestPath: '/speech_recognition/presigned_url_for_file',
        payload: {
          fileName: itemDetails.audioBucketURI,
          expiration: 7200,
        },
      });
      const requestsResp = response as { presignedUrl: string };
      setAudioUrl(requestsResp.presignedUrl);
    };

    const genUrlForSrt = async () => {
      if (!itemDetails.textBucketURI) {
        setSrtUrl(null);
        return null;
      }

      const response = await sendPostRequest({
        requestPath: '/speech_recognition/presigned_url_for_file',
        payload: {
          fileName: itemDetails.textBucketURI,
          expiration: 7200,
        },
      });
      const requestsResp = response as { presignedUrl: string };
      setSrtUrl(requestsResp.presignedUrl);
    };

    const genUrls = async () => {
      if (isGeneratingPresingedUrlRef.current) {
        return;
      }

      isGeneratingPresingedUrlRef.current = true;
      setGeneratingUrls(true);

      try {
        await Promise.all([genUrlForAudio(), genUrlForSrt()]);
        isGeneratingPresingedUrlRef.current = false;
      } catch (e: any) {
        logClientError(
          'Failed to generate presigned urls in SpeechToTextDetailsPage: ',
          e,
        );
        setSnackBarColor('danger');
        setSnackBarContent(
          'Failed to load your audio file or speech to text result. Please refresh page and try again.',
        );
        setSnackbarOpen(true);
      } finally {
        isGeneratingPresingedUrlRef.current = false;
        setGeneratingUrls(false);
      }
    };

    genUrls();
  }, [itemDetails]);

  /********************************************************************************************************
   * Event Handlers
   ******************************************************************************************************* */
  const onAudioPlayProgress = useCallback((currentTimeInMs: number) => {
    setCurrentAudioPlayTimeInSec(Math.floor(currentTimeInMs / 1000));
  }, []);

  const handleDownloadSrtClick = useCallback(() => {
    if (!audioTranscripts) {
      return;
    }

    downloadTranscriptsArrayAsSrt(
      audioTranscripts,
      (itemDetails?.title ?? 'transcript') + '.srt',
    );
  }, [audioTranscripts, itemDetails?.title]);

  const handleDownloadTxtClick = useCallback(() => {
    if (!audioTranscripts) {
      return;
    }

    downloadTranscriptsArrayAsTxt(
      audioTranscripts,
      (itemDetails?.title ?? 'transcript') + '.txt',
    );
  }, [audioTranscripts, itemDetails?.title]);

  /********************************************************************************************************
   * UX Components
   ******************************************************************************************************* */

  const loadingPage = useMemo(
    () => (
      <Box
        display='flex'
        justifyContent='center'
        marginTop={20}
      >
        <Box
          display='flex'
          flexDirection={'column'}
          alignItems={'center'}
        >
          <CircularProgress
            color='primary'
            sx={{ '--CircularProgress-size': '60px' }}
          />

          <Typography
            color='neutral'
            level='title-lg'
            marginTop={3}
          >
            Loading ...
          </Typography>
        </Box>
      </Box>
    ),
    [],
  );

  const detailsPage = useMemo(() => {
    if (itemDetails === null) {
      return <NoResultFoundContent />;
    }

    return (
      <Box
        display='flex'
        flexDirection={'column'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack marginTop={1}>
            <Typography
              level='h4'
              marginBottom={1}
            >
              {itemDetails!.title}
            </Typography>
            <TimeRow
              creationTime={itemDetails!.createdAt}
              audioDuration={itemDetails!.audioDurationInMs}
            />
            <Box marginY={1}>
              <StatusRow
                status={itemDetails!.status}
                creditsUsed={itemDetails!.creditsUsed}
                errorMsg={itemDetails!.errorMessage}
              />
            </Box>
          </Stack>

          {(itemDetails.status === RequestStatus.PENDING ||
            itemDetails.status === RequestStatus.PROCESSING) && (
            <LinearProgress
              variant='soft'
              size='sm'
              color='success'
              sx={{ marginTop: 1 }}
            />
          )}

          {
            // show loading status when generating presigned URLs for audio and srt file
            generatingUrls ? (
              <Box
                display='flex'
                justifyContent='center'
                marginTop={10}
              >
                <CircularProgress
                  color='primary'
                  sx={{ '--CircularProgress-size': '60px' }}
                />
              </Box>
            ) : null
          }

          <Box>
            <Typography
              level='title-md'
              marginTop={4}
              fontWeight={'bold'}
            >
              Audio
            </Typography>
            {audioUrl ? (
              <AudioMediaPlayer
                audioUrl={audioUrl}
                onCurrentProgress={onAudioPlayProgress}
                marginTop={2}
              />
            ) : null}
          </Box>

          <Box>
            <Stack
              direction={'row'}
              alignItems={'center'}
              marginTop={4}
              gap={2}
            >
              <Typography
                level='title-md'
                fontWeight={'bold'}
              >
                Transcript
              </Typography>
              {audioTranscripts && (
                <Button
                  color='neutral'
                  startDecorator={<DownloadIcon />}
                  onClick={handleDownloadSrtClick}
                >
                  srt
                </Button>
              )}
              {audioTranscripts && (
                <Button
                  color='neutral'
                  startDecorator={<DownloadIcon />}
                  onClick={handleDownloadTxtClick}
                >
                  txt
                </Button>
              )}
            </Stack>
            {srtUrl ? (
              <Box
                marginTop={2}
                marginBottom={10}
              >
                <AudioTranscriptionViewer
                  srtUrl={srtUrl}
                  currentAudioSeconds={currentAudioPlayTimeInSec}
                  onTranscriptLoaded={setAudioTranscripts}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    );
  }, [
    itemDetails,
    generatingUrls,
    audioUrl,
    onAudioPlayProgress,
    audioTranscripts,
    handleDownloadSrtClick,
    handleDownloadTxtClick,
    srtUrl,
    currentAudioPlayTimeInSec,
  ]);

  return (
    <Box width={'100%'}>
      {itemDetailsLoaded ? detailsPage : loadingPage}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant='solid'
        color={snackBarColor}
        invertedColors
        startDecorator={<BubbleChartRoundedIcon />}
        autoHideDuration={5000}
      >
        <Typography level='title-lg'>{snackBarContent}</Typography>
      </Snackbar>
    </Box>
  );
});
SpeechToTextDetailsPage.displayName = 'SpeechToTextDetailsPage';
export default SpeechToTextDetailsPage;
