import { Chip } from '@mui/joy';
import React from 'react';
import { convertCreditsToUserText } from '../../utils/creditUtils';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';

const CreditsUsed = React.memo(({ creditsUsed }: { creditsUsed?: number }) => {
  if (creditsUsed !== undefined && creditsUsed !== null) {
    return (
      <Chip
        variant='soft'
        color='neutral'
        startDecorator={
          <PaidRoundedIcon
            sx={{ width: 20, height: 20, color: 'success.plainColor' }}
          />
        }
      >
        {'Credits used: ' + convertCreditsToUserText(creditsUsed)}
      </Chip>
    );
  }

  return null;
});

CreditsUsed.displayName = 'CreditsUsed';
export default CreditsUsed;
