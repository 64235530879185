// https://docs.stripe.com/currencies#zero-decimal
const NO_MINOR_UNIT_CURRENCIES = [
  'bif',
  'clp',
  'djf',
  'gnf',
  'jpy',
  'kmf',
  'krw',
  'mga',
  'pyg',
  'rwf',
  'ugx',
  'vnd',
  'vuv',
  'xaf',
  'xof',
  'xpf',
];

export const formatAmountWithCurrency = (amount: number, currency: string) => {
  const isNoMinorUnit = NO_MINOR_UNIT_CURRENCIES.includes(
    currency.toLowerCase(),
  );

  const divisor = isNoMinorUnit ? 1 : 100;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: isNoMinorUnit ? 0 : 2,
    maximumFractionDigits: isNoMinorUnit ? 0 : 2,
  }).format(amount / divisor);
};
