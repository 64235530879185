import { Box, Typography } from '@mui/joy';
import React from 'react';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

export const NoResultFoundContent = React.memo(() => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 8,
      }}
    >
      <ReportProblemRoundedIcon
        sx={{ marginBottom: 2, width: 32, height: 32 }}
      />
      <Typography
        level='title-md'
        color='neutral'
        sx={{ textAlign: 'center', paddingX: 4 }}
        maxWidth={600}
      >
        No result found. It may have been deleted, or you may not have the
        permission to view it.
      </Typography>
    </Box>
  );
});

NoResultFoundContent.displayName = 'NoResultFoundContent';

export default NoResultFoundContent;
