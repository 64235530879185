import { createSlice } from '@reduxjs/toolkit';
import { CreditAssignmentStatus } from '../types/CreditAssignmentStatus';

interface CreditsStoreState {
  remainingCredits: number;
  creditsLoaded: boolean;
  freeCreditsAssignmentStatus: CreditAssignmentStatus;
}

const initialState: CreditsStoreState = {
  remainingCredits: 0,
  creditsLoaded: false,
  freeCreditsAssignmentStatus: CreditAssignmentStatus.NONE,
};

export const creditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    updateRemainingCredits: (state, action) => {
      state.creditsLoaded = true;
      state.remainingCredits = action.payload;
    },

    setFreeCreditsAssignmentStatus: (state, action) => {
      state.freeCreditsAssignmentStatus =
        action.payload as CreditAssignmentStatus;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateRemainingCredits, setFreeCreditsAssignmentStatus } =
  creditsSlice.actions;

export default creditsSlice.reducer;
