import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { sendPostRequest } from '../requests/sendRequests';
import {
  Box,
  CircularProgress,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/joy';
import { useDispatch } from 'react-redux';
import { updatePathBreadcumb } from '../stores/pathBreadcumbSlice';
import TimeRow from '../components/shared/TimeRow';
import StatusRow from '../components/shared/StatusRow';
import NoResultFoundContent from '../components/shared/NoResultFoundContent';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import DownloadIcon from '@mui/icons-material/Download';

import { speechToTextEntryDetailsMock } from '../mock/SpeechToTextMocks';
import AudioMediaPlayer from '../components/shared/AudioMediaPlayer';
import { RequestStatus } from '../types/RequestStatus';
import { TextToSpeechEntry } from '../types/TextToSpeechEntry';
import VoiceModelNameRow from '../components/shared/VoiceModelNameRow';
import { logClientError } from '../logging/useLogUserEvent';

const TextToSpeechDetailsPage = React.memo(() => {
  const { itemId } = useParams();
  const [itemDetails, setItemDetails] = useState<TextToSpeechEntry | null>(
    null,
  );
  const [itemDetailsLoaded, setItemDetailsLoaded] = useState(false);
  const isFetchingDetailsRef = useRef(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState<
    'success' | 'warning' | 'danger'
  >('danger');

  /********************************************************************************************************
   * Load the details of the text to speech request
   ******************************************************************************************************* */
  const loadItemDetails = useCallback(async () => {
    if (isFetchingDetailsRef.current) {
      return;
    }

    isFetchingDetailsRef.current = true;

    try {
      const response = await sendPostRequest({
        requestPath: '/text_to_speech/query_requests',
        payload: {
          request_ids: [itemId as string],
          full_text: true,
        },
      });
      const requestsResp = response as TextToSpeechEntry[];

      if (requestsResp.length === 0) {
        return;
      }

      setItemDetails(requestsResp[0]);

      // Dispatch the breadcrumb to show when this page is displayed
      dispatch(
        updatePathBreadcumb({
          [location.pathname]: 'from model ' + requestsResp[0].modelName,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logClientError(
        'Failed to poll requests latest status in TextToSpeechDetailsPage: ',
        e,
      );
    } finally {
      setItemDetailsLoaded(true);
      isFetchingDetailsRef.current = false;
    }
  }, [itemId, dispatch, location.pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (false) {
      // TODO: Remove the mock in this if statement
      //   setItemDetails(speechToTextEntryDetailsMock);
      setItemDetailsLoaded(true);
      dispatch(
        updatePathBreadcumb({
          [location.pathname]: speechToTextEntryDetailsMock.title,
        }),
      );
    } else {
      loadItemDetails();
    }
  }, [loadItemDetails, dispatch, location.pathname]);

  /********************************************************************************************************
   * Load the presigned url for the audio and srt
   ******************************************************************************************************* */

  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [generatingUrls, setGeneratingUrls] = useState(false);
  const isGeneratingPresingedUrlRef = useRef(false);

  // eslint-disable-next-line no-unused-vars
  const [, setCurrentAudioPlayTimeInSec] = useState(0);

  useEffect(() => {
    if (!itemDetails?.audioFilePath) {
      return;
    }

    const genUrlForAudio = async () => {
      const response = await sendPostRequest({
        requestPath: '/text_to_speech/presigned_url_for_file',
        payload: {
          audioFilePath: itemDetails.audioFilePath ?? '',
          expiration: 7200,
        },
      });
      const requestsResp = response as { presignedUrl: string };
      setAudioUrl(requestsResp.presignedUrl);
    };

    const genUrls = async () => {
      if (isGeneratingPresingedUrlRef.current) {
        return;
      }

      isGeneratingPresingedUrlRef.current = true;
      setGeneratingUrls(true);

      try {
        await genUrlForAudio();
        isGeneratingPresingedUrlRef.current = false;
      } catch (e: any) {
        logClientError(
          'Failed to generate presigned urls in TextToSpeechDetailsPage: ',
          e,
        );
        setSnackBarColor('danger');
        setSnackBarContent(
          'Failed to load your audio file. Please refresh page and try again.',
        );
        setSnackbarOpen(true);
      } finally {
        isGeneratingPresingedUrlRef.current = false;
        setGeneratingUrls(false);
      }
    };

    genUrls();
  }, [itemDetails]);

  /********************************************************************************************************
   * Event Handlers
   ******************************************************************************************************* */
  const onAudioPlayProgress = useCallback((currentTimeInMs: number) => {
    setCurrentAudioPlayTimeInSec(Math.floor(currentTimeInMs / 1000));
  }, []);

  const handleDownloadAudioClick = useCallback(() => {
    if (!audioUrl) {
      return;
    }

    const segments = itemDetails!.audioFilePath!.split('/');
    const fileName = segments.pop();

    const link = document.createElement('a');
    link.href = audioUrl;
    link.setAttribute('download', `${fileName}`);

    document.body.appendChild(link);
    link.click();
    link.remove(); // Clean up by removing the element after use
  }, [audioUrl, itemDetails]);

  /********************************************************************************************************
   * UX Components
   ******************************************************************************************************* */

  const loadingPage = useMemo(
    () => (
      <Box
        display='flex'
        justifyContent='center'
        marginTop={20}
      >
        <Box
          display='flex'
          flexDirection={'column'}
          alignItems={'center'}
        >
          <CircularProgress
            color='primary'
            sx={{ '--CircularProgress-size': '60px' }}
          />

          <Typography
            color='neutral'
            level='title-lg'
            marginTop={3}
          >
            Loading ...
          </Typography>
        </Box>
      </Box>
    ),
    [],
  );

  const detailsPage = useMemo(() => {
    if (itemDetails === null) {
      return <NoResultFoundContent />;
    }

    return (
      <Box
        display='flex'
        flexDirection={'column'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack marginTop={1}>
            <TimeRow creationTime={itemDetails!.createdAt} />
            <Box marginY={1}>
              <VoiceModelNameRow
                modelName={itemDetails.modelName}
                isFirstPartyModel={itemDetails.isFirstPartyModel}
              />
            </Box>
            <Box marginY={1}>
              <StatusRow
                status={itemDetails!.status}
                creditsUsed={itemDetails!.creditsUsed}
                errorMsg={itemDetails!.errorMessage}
              />
            </Box>
          </Stack>

          {(itemDetails.status === RequestStatus.PENDING ||
            itemDetails.status === RequestStatus.PROCESSING) && (
            <LinearProgress
              variant='soft'
              size='sm'
              color='success'
              sx={{ marginTop: 1 }}
            />
          )}

          {
            // show loading status when generating presigned URLs for audio and srt file
            generatingUrls ? (
              <Box
                display='flex'
                justifyContent='center'
                marginTop={10}
              >
                <CircularProgress
                  color='primary'
                  sx={{ '--CircularProgress-size': '60px' }}
                />
              </Box>
            ) : null
          }

          {audioUrl && (
            <Box marginTop={4}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={2}
              >
                <Typography
                  level='title-md'
                  fontWeight={'bold'}
                >
                  Audio
                </Typography>
                <Box
                  onClick={handleDownloadAudioClick}
                  sx={{
                    display: 'inline-flex', // Makes the box align items like a flex container
                    alignItems: 'center', // Centers the icon vertically
                    justifyContent: 'center', // Centers the icon horizontally
                    width: 32, // Specific width for the clickable area
                    height: 32, // Specific height for the clickable area
                    borderRadius: '50%', // Makes the box circular
                    transition: 'background-color 0.3s, transform 0.3s', // Smooth transition for background and scale
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light grey background on hover
                      cursor: 'pointer', // Changes cursor to indicate it's clickable
                      transform: 'scale(1.1)', // Slightly enlarges the box on hover
                    },
                  }}
                >
                  <DownloadIcon />
                </Box>
              </Stack>
              <AudioMediaPlayer
                audioUrl={audioUrl}
                onCurrentProgress={onAudioPlayProgress}
                marginTop={2}
              />
            </Box>
          )}

          <Box
            marginTop={4}
            marginBottom={8}
          >
            <Typography
              level='title-md'
              fontWeight={'bold'}
            >
              Your Text
            </Typography>
            <Box
              sx={{
                minHeight: 300,
                maxHeight: 600,
                marginTop: 2,
                padding: 3,
                overflowY: 'auto',
                border: '1px solid',
                borderRadius: '8px',
                borderColor: 'divider',
                boxShadow:
                  'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
              }}
            >
              <Typography
                level='title-md'
                marginBottom={2}
                color='neutral'
              >
                {itemDetails!.text}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }, [
    itemDetails,
    generatingUrls,
    audioUrl,
    onAudioPlayProgress,
    handleDownloadAudioClick,
  ]);

  return (
    <Box width={'100%'}>
      {itemDetailsLoaded ? detailsPage : loadingPage}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant='solid'
        color={snackBarColor}
        invertedColors
        startDecorator={<BubbleChartRoundedIcon />}
        autoHideDuration={5000}
      >
        <Typography level='title-lg'>{snackBarContent}</Typography>
      </Snackbar>
    </Box>
  );
});
TextToSpeechDetailsPage.displayName = 'TextToSpeechDetailsPage';
export default TextToSpeechDetailsPage;
