import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/joy';
import React from 'react';

interface ProductDemoCardProps {
  featureName: string;
  videoUrl: string;
  ctaTitle: string;
  onCTAClick: () => void;
  description: string;
  backgroundColor?: string;
  previewImage?: string;
}

const ProductDemoCard = React.memo(
  ({
    featureName,
    videoUrl,
    ctaTitle,
    onCTAClick,
    description,
    backgroundColor,
    previewImage,
  }: ProductDemoCardProps) => {
    return (
      <Card
        variant='soft'
        sx={{
          flexGrow: 1,
          width: '100%',
          backgroundColor: backgroundColor ?? '#ecf8f8',
        }}
      >
        <CardContent>
          <Typography
            level='title-lg'
            fontWeight={'bold'}
            textAlign={'center'}
          >
            {featureName}
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <video
              src={videoUrl}
              controls
              style={{ width: '100%' }}
              poster={previewImage}
            />
          </Box>
          {description && <Divider sx={{ marginY: 2 }} />}
          {description && (
            <Box
              minHeight={{
                xs: 150,
                sm: 120,
                md: 580,
                lg: 360,
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                level='body-md'
                textAlign={'center'}
              >
                {description}
              </Typography>
            </Box>
          )}
          <Divider sx={{ marginY: 2 }} />
          <Button
            onClick={onCTAClick}
            sx={{ backgroundColor: '#7161ef' }}
          >
            {' '}
            {ctaTitle}{' '}
          </Button>
        </CardContent>
      </Card>
    );
  },
);
ProductDemoCard.displayName = 'ProductDemoCard';
export default ProductDemoCard;
