import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/joy';
import { getCurrentUser } from 'aws-amplify/auth';
import { UserEventTypes } from './UserEventTypes';
import { useUserLocation } from './useUserLocation';
import { useBrowserFingerPrint } from './useBrowserFingerPrint';
import { logClientError, useLogUserEvent } from './useLogUserEvent';

const AppLogger = React.memo(() => {
  const appImpressionLoggedRef = useRef<boolean>(false);
  const [logDataInitialized, setlogDataInitialized] = useState(false);

  const { initializeLocation } = useUserLocation();
  const { initializeBrowserFingerPrint } = useBrowserFingerPrint();
  const logUserEvent = useLogUserEvent();

  const initializeLogData = useCallback(async () => {
    if (logDataInitialized) {
      return;
    }

    // Initialize user location and browser fingerprint first so that they can be logged in the next step
    await Promise.all([initializeBrowserFingerPrint(), initializeLocation()]);
    setlogDataInitialized(true);
  }, [initializeBrowserFingerPrint, initializeLocation, logDataInitialized]);

  useEffect(() => {
    initializeLogData();
  }, [initializeLogData]);

  const logAppStarted = useCallback(async () => {
    try {
      const { username, userId } = await getCurrentUser();

      // Only log when user is logged in
      if (!userId && !username) {
        return;
      }

      if (appImpressionLoggedRef.current) {
        return;
      }

      appImpressionLoggedRef.current = true;

      logUserEvent(
        {
          event: UserEventTypes.APP_PAGE_SESSION_START,
          userAgent: navigator.userAgent,
        },
        {
          logImmediately: true,
          logBrowerserFingerPrint: true,
          logUserLocation: true,
        },
      );
    } catch (error) {
      logClientError('Error when logging app started', error);
    }
  }, [logUserEvent]);

  // Initialize and  Load user's remaining balance
  useEffect(() => {
    if (!logDataInitialized) {
      return;
    }

    logAppStarted();
  }, [logAppStarted, logDataInitialized]);

  return (
    <Box
      height={0}
      width={0}
      display={'none'}
    />
  );
});

AppLogger.displayName = 'AppLogger';
export default AppLogger;
