import { Stack, Typography } from '@mui/joy';
import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const PricingBoxDescriptionLine = React.memo(
  ({ description }: { description: string }) => {
    return (
      <Stack
        direction={'row'}
        gap={1.5}
        alignItems={'center'}
      >
        <CheckCircleRoundedIcon color='success' />
        <Typography level='body-md'>{description}</Typography>
      </Stack>
    );
  },
);
PricingBoxDescriptionLine.displayName = 'PricingBoxDescriptionLine';
export default PricingBoxDescriptionLine;
