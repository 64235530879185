import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/joy';
import React, { useMemo } from 'react';
import PricingBoxDescriptionLine from './PricingBoxDescriptionLine';

interface PricingBoxProps {
  featureName: string;
  ctaTitle: string;
  onCTAClick: () => void;
  descriptionLines: string[];
  backgroundColor?: string;
  minHeight?: any;
  subscriptionPrice?: string;
  subscribed?: boolean;
}

const PricingBox = React.memo(
  ({
    featureName,
    ctaTitle,
    onCTAClick,
    descriptionLines,
    backgroundColor,
    minHeight,
    subscriptionPrice,
    subscribed,
  }: PricingBoxProps) => {
    const subscriptionPriceText = useMemo(() => {
      if (!subscriptionPrice) {
        return null;
      }

      return (
        <Stack alignItems={'center'}>
          <Stack
            direction={'row'}
            gap={0.5}
            alignItems={'baseline'}
          >
            <Typography level='h2'>{subscriptionPrice}</Typography>
            <Typography level='body-lg'>{'/month'}</Typography>
          </Stack>
          <Typography level='body-sm'>{'(before tax)'}</Typography>
        </Stack>
      );
    }, [subscriptionPrice]);

    const subscriptionStatusRow = useMemo(() => {
      if (!subscribed) {
        return subscriptionPriceText;
      }

      return (
        <Stack
          direction={'row'}
          gap={1.5}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {subscriptionPriceText}
          <Chip
            variant='soft'
            color='success'
            sx={{
              fontSize: 16,
              backgroundColor: '#1F7A1F',
              color: 'white',
              paddingX: 1.5,
              paddingY: 0.5,
            }}
          >
            Active
          </Chip>
        </Stack>
      );
    }, [subscribed, subscriptionPriceText]);

    return (
      <Card
        variant='soft'
        color='primary'
        invertedColors
        sx={{
          flexGrow: 1,
          width: '100%',
          backgroundColor: backgroundColor ?? '#ecf8f8',
        }}
      >
        <CardContent
          orientation='horizontal'
          sx={{ alignItems: 'center' }}
        >
          <CardContent>
            <Stack
              direction={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              minHeight={48}
            >
              <Typography
                level='title-lg'
                fontWeight={'bold'}
                textAlign={'center'}
              >
                {featureName}
              </Typography>
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            {subscriptionStatusRow}
            <Box
              minHeight={
                minHeight
                  ? minHeight
                  : {
                      xs: 150,
                      sm: 120,
                      md: 360,
                      lg: 240,
                    }
              }
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {descriptionLines.map((line, index) => (
                <Box
                  key={index}
                  marginBottom={index === descriptionLines.length - 1 ? 0 : 2.5}
                >
                  <PricingBoxDescriptionLine description={line} />
                </Box>
              ))}
            </Box>
            <Divider sx={{ marginY: 2 }} />
            <Button onClick={onCTAClick}> {ctaTitle} </Button>
          </CardContent>
        </CardContent>
      </Card>
    );
  },
);
PricingBox.displayName = 'PricingBox';
export default PricingBox;
