import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { sendPostRequest } from '../requests/sendRequests';
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/joy';
import { useDispatch } from 'react-redux';
import { updatePathBreadcumb } from '../stores/pathBreadcumbSlice';
import TimeRow from '../components/shared/TimeRow';
import StatusRow from '../components/shared/StatusRow';
import NoResultFoundContent from '../components/shared/NoResultFoundContent';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import AudioMediaPlayer from '../components/shared/AudioMediaPlayer';
import { VoiceCloneEntry } from '../types/VoiceCloneEntry';
import { RequestStatus } from '../types/RequestStatus';
import { logClientError, useLogUserEvent } from '../logging/useLogUserEvent';
import { UserEventTypes } from '../logging/UserEventTypes';
import NewTextToSpeechRequestModal from '../components/text_to_speech/NewTextToSpeechRequestModal';
import { TextToSpeechEntry } from '../types/TextToSpeechEntry';
import { APPS_TAB_PATH } from '../data/pageLinks';

const VoiceCloneDetailsPage = React.memo(() => {
  const { itemId } = useParams();
  const [itemDetails, setItemDetails] = useState<VoiceCloneEntry | null>(null);
  const [itemDetailsLoaded, setItemDetailsLoaded] = useState(false);
  const isFetchingDetailsRef = useRef(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState<
    'success' | 'warning' | 'danger'
  >('danger');

  const logUserEvent = useLogUserEvent();

  const navigate = useNavigate();

  /********************************************************************************************************
   * Load the details of the voice clone request
   ******************************************************************************************************* */
  const loadItemDetails = useCallback(async () => {
    if (isFetchingDetailsRef.current) {
      return;
    }

    isFetchingDetailsRef.current = true;

    try {
      const response = await sendPostRequest({
        requestPath: '/voice-clone/query_requests',
        payload: {
          request_ids: [itemId as string],
        },
      });
      const requestsResp = response as VoiceCloneEntry[];

      if (requestsResp.length === 0) {
        return;
      }

      setItemDetails(requestsResp[0]);

      // Dispatch the breadcrumb to show when this page is displayed
      dispatch(
        updatePathBreadcumb({ [location.pathname]: requestsResp[0].modelName }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logClientError(
        'Failed to poll requests latest status in VoiceCloneDetailsPage: ',
        e,
      );
    } finally {
      setItemDetailsLoaded(true);
      isFetchingDetailsRef.current = false;
    }
  }, [itemId, dispatch, location.pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (false) {
      //   // TODO: Remove the mock in this if statement
      //   setItemDetails(speechToTextEntryDetailsMock);
      //   setItemDetailsLoaded(true);
      //   dispatch(
      //     updatePathBreadcumb({
      //       [location.pathname]: speechToTextEntryDetailsMock.title,
      //     }),
      //   );
    } else {
      loadItemDetails();
    }
  }, [loadItemDetails, dispatch, location.pathname]);

  /********************************************************************************************************
   * Load the presigned url for the audio and srt
   ******************************************************************************************************* */

  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [generatingUrls, setGeneratingUrls] = useState(false);
  const isGeneratingPresingedUrlRef = useRef(false);

  // eslint-disable-next-line no-unused-vars
  const [, setCurrentAudioPlayTimeInSec] = useState(0);

  useEffect(() => {
    if (!itemDetails) {
      return;
    }

    const genUrl = async () => {
      if (isGeneratingPresingedUrlRef.current) {
        return;
      }

      isGeneratingPresingedUrlRef.current = true;
      setGeneratingUrls(true);

      try {
        const response = await sendPostRequest({
          requestPath: '/voice-clone/presigned_url_for_file',
          payload: {
            audioFileId: itemDetails.audioFileId,
            expiration: 7200,
          },
        });
        const requestsResp = response as { presignedUrl: string };
        setAudioUrl(requestsResp.presignedUrl);
      } catch (e: any) {
        logClientError(
          'Failed to generate presigned urls in VoiceCloneDetailsPage: ',
          e,
        );
        setSnackBarColor('danger');
        setSnackBarContent(
          'Failed to load your audio file. Please refresh page and try again.',
        );
        setSnackbarOpen(true);
      } finally {
        isGeneratingPresingedUrlRef.current = false;
        setGeneratingUrls(false);
      }
    };

    genUrl();
  }, [itemDetails]);

  /********************************************************************************************************
   * Event Handlers
   ******************************************************************************************************* */
  const onAudioPlayProgress = useCallback((currentTimeInMs: number) => {
    setCurrentAudioPlayTimeInSec(Math.floor(currentTimeInMs / 1000));
  }, []);

  const [isNewRequestDialogOpen, setIsNewRequestDialogOpen] = useState(false);

  const handleRequestDialogOpen = useCallback(() => {
    setIsNewRequestDialogOpen(true);

    logUserEvent({
      event: UserEventTypes.TEXT_TO_SPEECH_REQUEST_BUTTON_CLICK,
      surface: 'VoiceCloneDetailsPage',
    });
  }, [logUserEvent]);

  const handleRequestDialogClose = useCallback(
    ({ createdRequest }: { createdRequest?: TextToSpeechEntry }) => {
      setIsNewRequestDialogOpen(false);

      if (!createdRequest) {
        return;
      }

      setSnackBarColor('success');
      setSnackBarContent('Text to speech request created successfully');
      setSnackbarOpen(true);

      navigate(APPS_TAB_PATH.TEXT_TO_SPEECH);
    },
    [navigate],
  );

  /********************************************************************************************************
   * UX Components
   ******************************************************************************************************* */

  const loadingPage = useMemo(
    () => (
      <Box
        display='flex'
        justifyContent='center'
        marginTop={20}
      >
        <Box
          display='flex'
          flexDirection={'column'}
          alignItems={'center'}
        >
          <CircularProgress
            color='primary'
            sx={{ '--CircularProgress-size': '60px' }}
          />

          <Typography
            color='neutral'
            level='title-lg'
            marginTop={3}
          >
            Loading ...
          </Typography>
        </Box>
      </Box>
    ),
    [],
  );

  const detailsPage = useMemo(() => {
    if (itemDetails === null) {
      return <NoResultFoundContent />;
    }

    return (
      <Box
        display='flex'
        flexDirection={'column'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack marginTop={1}>
            <Typography
              level='h4'
              marginBottom={1}
            >
              {itemDetails!.modelName}
            </Typography>
            <TimeRow
              creationTime={itemDetails!.createdAt}
              audioDuration={itemDetails!.audioDurationInMs}
            />
            <Box marginY={1}>
              <StatusRow
                status={itemDetails!.status}
                creditsUsed={itemDetails!.creditsUsed}
                errorMsg={itemDetails!.errorMessage}
              />
            </Box>
          </Stack>

          {(itemDetails.status === RequestStatus.PENDING ||
            itemDetails.status === RequestStatus.PROCESSING) && (
            <LinearProgress
              variant='soft'
              size='sm'
              color='success'
              sx={{ marginTop: 1 }}
            />
          )}

          {
            // show loading status when generating presigned URLs for audio and srt file
            generatingUrls ? (
              <Box
                display='flex'
                justifyContent='center'
                marginTop={2}
              >
                <CircularProgress
                  color='primary'
                  sx={{ '--CircularProgress-size': '60px' }}
                />
              </Box>
            ) : null
          }

          <Box>
            <Typography
              level='title-md'
              fontWeight={'bold'}
              marginY={4}
            >
              Voice to Clone
            </Typography>
            {audioUrl ? (
              <AudioMediaPlayer
                audioUrl={audioUrl}
                onCurrentProgress={onAudioPlayProgress}
                marginTop={2}
              />
            ) : null}
          </Box>

          {itemDetails.status === RequestStatus.SUCCESS && (
            <Button
              color='primary'
              variant='soft'
              startDecorator={<GraphicEqIcon />}
              sx={{
                backgroundColor: '#4B70F5',
                color: 'white',
                marginY: 4,
                width: 'auto',
              }}
              onClick={handleRequestDialogOpen}
            >
              Use voice
            </Button>
          )}

          {itemDetails!.audioAsr && (
            <Box marginBottom={6}>
              <Typography
                level='title-md'
                // marginTop={2}
                marginBottom={2}
                fontWeight={'bold'}
              >
                Transcript
              </Typography>
              <Box
                sx={{
                  minHeight: 300,
                  maxHeight: 600,
                  padding: 3,
                  overflowY: 'auto',
                  border: '1px solid',
                  borderRadius: '8px',
                  borderColor: 'divider',
                  boxShadow:
                    'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
                }}
              >
                <Typography
                  level='title-md'
                  marginBottom={2}
                  color='neutral'
                >
                  {itemDetails!.audioAsr}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }, [
    itemDetails,
    generatingUrls,
    audioUrl,
    onAudioPlayProgress,
    handleRequestDialogOpen,
  ]);

  return (
    <Box width={'100%'}>
      {itemDetailsLoaded ? detailsPage : loadingPage}

      <NewTextToSpeechRequestModal
        isOpen={isNewRequestDialogOpen}
        handleClose={handleRequestDialogClose}
        requestedLanguage={itemDetails?.language ?? undefined}
        requestedModelId={itemDetails?.id ?? undefined}
        requestedVoiceType={itemDetails?.id ? 'self_cloned' : undefined}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant='solid'
        color={snackBarColor}
        invertedColors
        startDecorator={<BubbleChartRoundedIcon />}
        autoHideDuration={5000}
      >
        <Typography level='title-lg'>{snackBarContent}</Typography>
      </Snackbar>
    </Box>
  );
});
VoiceCloneDetailsPage.displayName = 'VoiceCloneDetailsPage';
export default VoiceCloneDetailsPage;
