import { UploadPart } from '../types/UploadPart';
import { sendPostRequest } from './sendRequests';

// eslint-disable-next-line no-unused-vars
enum UploadPhase { // Keep sync with  voice-vector-multifile-upload-speech-rec-audio Lambda function
  // eslint-disable-next-line no-unused-vars
  Initiate = 'initiate',
  // eslint-disable-next-line no-unused-vars
  Complete = 'complete',
}

interface MultipartUploadInitiation {
  uploadId: string;
  objectKey: string;
  fileId?: string;
  presignedUrls: string[];
}

const initiateMultipartUpload = async (
  file: File,
  uploadEndpoint: string,
): Promise<MultipartUploadInitiation> => {
  const partSize = 5 * 1024 * 1024; // 5MB for each part
  const totalParts = Math.ceil(file.size / partSize);

  try {
    const response = await sendPostRequest({
      requestPath: uploadEndpoint,
      payload: {
        uploadPhase: UploadPhase.Initiate,
        filename: file?.name ?? '',
        contentType: file?.type ?? '',
        totalParts,
      },
    });

    return response as MultipartUploadInitiation;
  } catch (e: any) {
    throw new Error('Failed to initiate upload: ' + e.toString());
  }
};

const uploadPart = async (
  file: File,
  partNumber: number,
  presignedUrl: string,
): Promise<UploadPart> => {
  const partSize = 5 * 1024 * 1024; // 5MB
  const start = (partNumber - 1) * partSize;
  const end = Math.min(start + partSize, file.size);
  const chunk = file.slice(start, end);

  const response = await fetch(presignedUrl, {
    method: 'PUT',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    body: chunk,
  });
  if (!response.ok) {
    throw new Error(
      `Upload file failed: ${response.status}, ${response.statusText}}`,
    );
  }
  const etag = response.headers.get('Etag');
  response.headers.forEach((value, key) => {
    console.log(`${key}: ${value}`);
  });
  console.log('etag', etag);
  if (!etag) {
    throw new Error(`Upload file failed with missing etag`);
  }
  return { PartNumber: partNumber, ETag: etag };
};

const completeMultipartUpload = async (
  uploadId: string,
  objectKey: string,
  parts: UploadPart[],
  uploadEndpoint: string,
): Promise<void> => {
  try {
    await sendPostRequest({
      requestPath: uploadEndpoint,
      payload: {
        uploadPhase: UploadPhase.Complete,
        objectKey,
        uploadId,
        parts,
      },
    });
  } catch (e: any) {
    throw new Error('Failed to complete upload: ' + e.toString());
  }
};

export const uploadFileToS3WithMultipart = async (
  file: File,
  uploadEndpoint: string,
): Promise<{
  objectKey: string;
  fileId?: string;
}> => {
  const { uploadId, objectKey, presignedUrls, fileId } =
    await initiateMultipartUpload(file, uploadEndpoint);

  const uploadPromises: Promise<UploadPart>[] = presignedUrls.map(
    (url, index) => uploadPart(file, index + 1, url),
  );

  const parts = await Promise.all(uploadPromises);
  await completeMultipartUpload(uploadId, objectKey, parts, uploadEndpoint);

  return { objectKey, fileId };
};
