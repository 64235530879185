import { Box, Chip, CircularProgress, Link, Stack, Table } from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { sendGetRequest, sendPostRequest } from '../../requests/sendRequests';
import { PaymentHistoryEntry } from '../../types/PaymentHistoryEntry';
import { getDateString, getTimeString } from '../../utils/timeUtils';
import { convertCreditsToUserText } from '../../utils/creditUtils';
import { logClientError } from '../../logging/useLogUserEvent';

interface PaymentHistoryTableProps {
  // eslint-disable-next-line no-unused-vars
  showSnackMessage?: (message: string, isError: boolean) => void;
}

const PaymentHistoryTable = React.memo(
  ({ showSnackMessage }: PaymentHistoryTableProps) => {
    const [paymentHistoryEntries, setPaymentHistoryEntries] = useState<
      PaymentHistoryEntry[]
    >([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);

    useEffect(() => {
      const queryRemainingCredits = async () => {
        setIsLoadingHistory(true);
        try {
          const response = await sendGetRequest({
            requestPath: '/payment/history',
            queryParams: {},
          });

          const paymentHistoryEntries = response as PaymentHistoryEntry[];
          setPaymentHistoryEntries(paymentHistoryEntries);
        } catch (e: any) {
          logClientError(
            'Failed to load remaining credits in PaymentHistoryTable ',
            e,
          );
        } finally {
          setIsLoadingHistory(false);
        }
      };

      queryRemainingCredits();
    }, []);

    const handleViewInvoice = useCallback(
      async (transactionId: string) => {
        try {
          const response = await sendPostRequest({
            requestPath: '/payment/invoice_url',
            payload: {
              transactionId: transactionId,
              invoiceType: 'payment',
            },
          });

          const { invoice_url } = response as {
            invoice_url: string;
          };

          window.open(invoice_url, '_blank', 'noopener,noreferrer');
        } catch (e: any) {
          logClientError('Failed to load invoice url: ', e);
          showSnackMessage?.(
            'Failed to fetch invoice. Please refresh page and try again.',
            true, // is error
          );
        }
      },
      [showSnackMessage],
    );

    const wrapHeaderStyle: React.CSSProperties = useMemo(() => {
      return {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
      };
    }, []);

    if (isLoadingHistory) {
      return (
        <Stack
          width={'100%'}
          direction={'row'}
          justifyContent={'center'}
        >
          <CircularProgress sx={{ width: 120, height: 120 }} />
        </Stack>
      );
    }

    return (
      <Box width={'100%'}>
        <Table
          aria-label='table sizes'
          size='lg'
        >
          <thead>
            <tr>
              <th style={wrapHeaderStyle}>Date</th>
              <th style={wrapHeaderStyle}>Status</th>
              <th style={wrapHeaderStyle}>Amount</th>
              <th style={wrapHeaderStyle}>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {paymentHistoryEntries.map((historyEntry: PaymentHistoryEntry) => (
              <tr key={historyEntry.sourceId}>
                <td>{`${getDateString(historyEntry.timestamp)} ${getTimeString(
                  historyEntry.timestamp,
                )}`}</td>
                <td>
                  <Chip
                    variant='soft'
                    color='success'
                  >
                    Paid
                  </Chip>
                </td>
                <td>{convertCreditsToUserText(historyEntry.credits, 2)}</td>
                <td>
                  <Link
                    component='button'
                    onClick={() => handleViewInvoice(historyEntry.sourceId)}
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    );
  },
);
PaymentHistoryTable.displayName = 'PaymentHistoryTable';
export default PaymentHistoryTable;
