/* eslint-disable react/react-in-jsx-scope */
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';

import Sidebar from './components/Sidebar';
import Header from './components/Header';

import store from './stores/stores';
import { Provider } from 'react-redux';

import '@fontsource/inter';

import { BrowserRouter } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { getCurrentUser } from 'aws-amplify/auth';

import { CookieStorage } from 'aws-amplify/utils';
import NavigationPageFrame from './pages/NavigationPageFrame';
import DataLoader from './data/DataLoader';
import { APP_API_ENDPOINT, AWS_REGION } from './data/configAndSecrets';
import { useEffect } from 'react';
import { LOGOUT_DESTINATION_URL } from './data/pageLinks';
import AppLogger from './logging/AppLogger';
import { logClientError } from './logging/useLogUserEvent';

Amplify.configure(config);

// Saves the tokens in the browser's Cookies. The cookies will persist
// across browser sessions and tabs.
// More important: Cookies can be used by our subdomains such as apps.voice-vector.com
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    domain: '.voice-vector.com',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'lax',
  }),
);

const existingConfig = Amplify.getConfig();
Amplify.configure({
  ...existingConfig,
  API: {
    ...existingConfig.API,
    REST: {
      ...existingConfig.API?.REST,
      VoiceVectorApp: {
        endpoint: APP_API_ENDPOINT,
        region: AWS_REGION,
      },
    },
  },
});

export default function App() {
  useEffect(() => {
    const checkUser = async () => {
      let userLoggedIn = false;
      try {
        const { username, userId } = await getCurrentUser();
        if (userId || username) {
          userLoggedIn = true;
        }
      } catch (error) {
        logClientError('Failed to check if user is logged in in App', error);
      }

      if (!userLoggedIn) {
        window.location.href = LOGOUT_DESTINATION_URL;
      }
    };

    checkUser();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <CssVarsProvider disableTransitionOnChange>
          <CssBaseline />

          <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Header />
            <Sidebar />
            <AppLogger />
            <DataLoader />
            <NavigationPageFrame />
          </Box>
        </CssVarsProvider>
      </BrowserRouter>
    </Provider>
  );
}
