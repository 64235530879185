import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from '@mui/joy';
import React from 'react';
import { SpeechToTextEntry } from '../../types/SpeechToTextTypes';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import TimeRow from '../shared/TimeRow';
import StatusRow from '../shared/StatusRow';
import { useNavigate } from 'react-router-dom';
import { RequestStatus } from '../../types/RequestStatus';
import { APPS_TAB_PATH } from '../../data/pageLinks';

interface SpeechToTextEntryCardProps {
  entry: SpeechToTextEntry;
}

const SpeechToTextEntryCard = React.memo(
  ({ entry }: SpeechToTextEntryCardProps) => {
    const navigate = useNavigate();

    return (
      <Card
        variant='outlined'
        sx={{
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src='/icons/audio_wav_icon.png'
            alt='audio wav icon'
            style={{ width: 32, height: 32 }}
          />
          <ChevronRightRoundedIcon sx={{ marginLeft: 1 }} />
          <img
            src='/icons/text_icon.png'
            alt='text icon'
            style={{ marginLeft: 8, width: 32, height: 32 }}
          />
        </Box>
        <CardContent>
          <Typography
            level='title-lg'
            marginBottom={0.5}
          >
            {entry.title}
          </Typography>
          <TimeRow
            creationTime={entry.createdAt}
            audioDuration={entry.audioDurationInMs}
          />
          <Box marginY={0.5}>
            <StatusRow
              status={entry.status}
              // creditsUsed={entry.creditsUsed} // Intended to not show in card in the list page :)
              errorMsg={entry.errorMessage}
            />
          </Box>
          <Typography
            level='body-sm'
            marginTop={0.5}
          >
            {entry.textPreview ? entry.textPreview + ' ......' : ''}
          </Typography>
        </CardContent>
        <CardActions buttonFlex='0 1 100%'>
          <Button
            variant='outlined'
            color='neutral'
            sx={{ borderRadius: 6 }}
            onClick={() =>
              navigate(`${APPS_TAB_PATH.SPEECH_TO_TEXT}/${entry.id}`)
            }
          >
            View
          </Button>
          {/* <Button
            variant='outlined'
            color='neutral'
            sx={{ borderRadius: 6 }}
          >
            Delete
          </Button> */}
        </CardActions>
        {entry.status === RequestStatus.PROCESSING && (
          <LinearProgress
            variant='soft'
            size='sm'
            color='neutral'
          />
        )}
      </Card>
    );
  },
);
SpeechToTextEntryCard.displayName = 'SpeechToTextEntryCard';
export default SpeechToTextEntryCard;
