/* eslint-disable no-unused-vars */

export enum UserEventTypes {
  // 🔔 Whole app related events
  APP_PAGE_SESSION_START = 'app_page_session_start',
  APP_PAGE_SESSION_END = 'app_page_session_end',

  // 🔔 Page Events
  PAGE_IMPRESSION = 'page_impression',

  // 🔔 Speech to Text
  SPEECH_TO_TEXT_REQUEST_BUTTON_CLICK = 'speech_to_text_request_button_click',
  SPEECH_TO_TEXT_REQUEST_AUDIO_UPLOADED = 'speech_to_text_request_audio_uploaded',
  SPEECH_TO_TEXT_REQUEST_SUBMIT = 'speech_to_text_request_submit',
  SPEECH_TO_TEXT_REQUEST_CANCELLED = 'speech_to_text_request_cancelled',
  SPEECH_TO_TEXT_REQUEST_RESPONSE = 'speech_to_text_request_response',

  // 🔔 Text to Speech
  TEXT_TO_SPEECH_REQUEST_BUTTON_CLICK = 'text_to_speech_request_button_click',
  TEXT_TO_SPEECH_REQUEST_SUBMIT = 'text_to_speech_request_submit',
  TEXT_TO_SPEECH_REQUEST_CANCELLED = 'text_to_speech_request_cancelled',
  TEXT_TO_SPEECH_REQUEST_RESPONSE = 'text_to_speech_request_response',

  // 🔔 Voice Clone
  VOICE_CLONE_REQUEST_BUTTON_CLICK = 'voice_clone_request_button_click',
  VOICE_CLONE_REQUEST_AUDIO_UPLOADED = 'voice_clone_request_audio_uploaded',
  VOICE_CLONE_REQUEST_SUBMIT = 'voice_clone_request_submit',
  VOICE_CLONE_REQUEST_CANCELLED = 'voice_clone_request_cancelled',
  VOICE_CLONE_REQUEST_RESPONSE = 'voice_clone_request_response',

  // 🔔 Credits Related
  USER_CREDITS_LOADED = 'user_credits_loaded',
  ADD_CREDITS_SESSION_START = 'add_credits_session_start',

  // 🔔 Subscription Related
  SUBSCRIPTION_SESSION_START = 'subscription_session_start',
  SUBSCRIPTION_OPEN_MGMT_PORTAL = 'subscription_open_mgmt_portal',

  // 🔔 Special type: log client error
  CLIENT_ERROR = 'client_error',
}
