import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import {
  Route,
  Link as RouterLink,
  Routes,
  useLocation,
} from 'react-router-dom';
import { APPS_TAB_PATH, PATH_TO_BREADCRUMB } from '../data/pageLinks';
import React, { useCallback, useEffect } from 'react';
import SpeechToTextPageContent from './SpeechToTextPageContent';
import TextToSpeechPageContent from './TextToSpeechPageContent';
import VoiceClonePageContent from './VoiceClonePageContent';
import BillingPageContent from './BillingPageContent';
import SpeechToTextDetailsPage from './SpeechToTextDetailsPage';
import { useDispatch, useSelector } from 'react-redux';
import { StoreRootState } from '../stores/stores';
import { updatePathBreadcumb } from '../stores/pathBreadcumbSlice';
import VoiceCloneDetailsPage from './VoiceCloneDetailsPage';
import TextToSpeechDetailsPage from './TextToSpeechDetailsPage';
import PricingPageContent from './PricingPageContent';
import HomePageContent from './HomePageContent';

const NavigationPageFrame = React.memo(() => {
  const location = useLocation();
  const pathToBreadcumb = useSelector(
    (state: StoreRootState) => state.pathBreadcumb.pathToBreadcumb,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updatePathBreadcumb(PATH_TO_BREADCRUMB));
  }, [dispatch]);

  const limitBredcrumbText = useCallback((text: string) => {
    if (text.length > 28) {
      return text.substring(0, 28) + '...';
    }
    return text;
  }, []);

  const generateBreadcrumbs = useCallback(() => {
    const pathnames = (
      location.pathname === APPS_TAB_PATH.HOME_SHORT
        ? APPS_TAB_PATH.HOME
        : location.pathname
    )
      ?.split('/')
      .filter((x) => x);

    let pathAccum = '';
    const breadcrumbLinks = pathnames.map((value, index) => {
      pathAccum += `/${value}`;
      const isLast = index === pathnames.length - 1;

      if (isLast) {
        return (
          <Typography
            key={pathAccum}
            color='primary'
          >
            {limitBredcrumbText(pathToBreadcumb[pathAccum] || value)}
          </Typography>
        );
      }

      return (
        <Link
          key={pathAccum}
          component={RouterLink}
          to={pathAccum}
          underline='none'
          color='primary'
        >
          {limitBredcrumbText(pathToBreadcumb[pathAccum] || value)}
        </Link>
      );
    });

    // Prepend the home icon link
    breadcrumbLinks.unshift(
      <Link
        key='home'
        component={RouterLink}
        to='/'
        underline='none'
        color='primary'
        aria-label='Home'
      >
        <HomeRoundedIcon />
      </Link>,
    );

    return breadcrumbLinks;
  }, [location.pathname, pathToBreadcumb, limitBredcrumbText]);

  return (
    <Box
      component='main'
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: 'calc(12px + var(--Header-height))',
          sm: 'calc(12px + var(--Header-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100vh',
        gap: 1,
        marginLeft: { xs: 0, md: 'var(--Sidebar-width)' },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size='lg'
          aria-label='breadcrumbs'
          separator={<ChevronRightRoundedIcon fontSize='medium' />}
          sx={{ pl: 0 }}
        >
          {generateBreadcrumbs()}
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Routes>
          <Route
            path={APPS_TAB_PATH.HOME_SHORT}
            element={<HomePageContent />}
          />
          <Route
            path={APPS_TAB_PATH.HOME}
            element={<HomePageContent />}
          />
          <Route
            path={APPS_TAB_PATH.SPEECH_TO_TEXT}
            element={<SpeechToTextPageContent />}
          />
          <Route
            path={`${APPS_TAB_PATH.SPEECH_TO_TEXT}/:itemId`}
            element={<SpeechToTextDetailsPage />}
          />
          <Route
            path={APPS_TAB_PATH.TEXT_TO_SPEECH}
            element={<TextToSpeechPageContent />}
          />
          <Route
            path={`${APPS_TAB_PATH.TEXT_TO_SPEECH}/:itemId`}
            element={<TextToSpeechDetailsPage />}
          />
          <Route
            path={APPS_TAB_PATH.VOICE_CLONE}
            element={<VoiceClonePageContent />}
          />
          <Route
            path={`${APPS_TAB_PATH.VOICE_CLONE}/:itemId`}
            element={<VoiceCloneDetailsPage />}
          />
          <Route
            path={APPS_TAB_PATH.BILLING}
            element={<BillingPageContent />}
          />
          <Route
            path={`${APPS_TAB_PATH.BILLING}/payment-history`}
            element={<BillingPageContent />}
          />
          <Route
            path={`${APPS_TAB_PATH.BILLING}/subscription-history`}
            element={<BillingPageContent />}
          />
          <Route
            path={`${APPS_TAB_PATH.BILLING}/activity`}
            element={<BillingPageContent />}
          />
          <Route
            path={APPS_TAB_PATH.PRICING}
            element={<PricingPageContent />}
          />
        </Routes>
      </Box>
    </Box>
  );
});

NavigationPageFrame.displayName = 'NavigationPageFrame';
export default NavigationPageFrame;
