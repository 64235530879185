import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/joy';
import { FAQ } from '../../types/FAQ';
import React from 'react';

interface FAQAccordionProps {
  faqData: FAQ[];
}

const FAQAccordion = React.memo(({ faqData }: FAQAccordionProps) => {
  const [currentExpandedIndex, setCurrentExpandedIndex] = React.useState<
    number | null
  >(null);

  return (
    <Stack
      direction={'column'}
      gap={2}
      width={'100%'}
      marginBottom={4}
    >
      <AccordionGroup sx={{ width: '100%' }}>
        {faqData.map((faq, index) => (
          <Accordion
            key={faq.question}
            sx={{ paddingY: 1 }}
            expanded={index === currentExpandedIndex}
            onChange={(_event, expanded) => {
              setCurrentExpandedIndex(expanded ? index : null);
            }}
          >
            <AccordionSummary>
              <Typography
                level='title-sm'
                sx={{ fontSize: 20 }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingY: 1 }}>
              <Typography
                level='body-lg'
                sx={{ whiteSpace: 'pre-line' }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionGroup>
    </Stack>
  );
});

FAQAccordion.displayName = 'FAQAccordion';
export default FAQAccordion;
