import * as React from 'react';
import Select from '@mui/joy/Select';
import Option, { optionClasses } from '@mui/joy/Option';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListItemDecorator, {
  listItemDecoratorClasses,
} from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import { SPEECH_TO_TEXT_SUPPORTED_LANGUAGES } from '../../data/languages';

const LanguageSelector = React.memo(
  ({
    selectedLanguage,
    languages,
    onLanguageChange,
    disabled,
  }: {
    selectedLanguage: string;
    languages: string[];
    onLanguageChange: (
      _event: React.SyntheticEvent | null,
      _newValue: string | null,
    ) => void;
    disabled: boolean;
  }) => {
    const optionGroups = {
      'With most human-like voices': ['en', 'zh'],
      'With standard voices': languages
        .filter((l) => l !== 'en' && l !== 'zh')
        .sort(),
    };
    const colors = {
      'With standard voices': 'neutral',
      'With most human-like voices': 'success',
    } as const;
    return (
      <Select
        placeholder='Choose language'
        slotProps={{
          listbox: {
            component: 'div',
            sx: {
              maxHeight: 240,
              overflow: 'auto',
              '--List-padding': '0px',
              '--ListItem-radius': '0px',
            },
          },
        }}
        value={selectedLanguage}
        onChange={onLanguageChange}
        disabled={disabled}
      >
        {Object.entries(optionGroups).map(
          ([groupName, groupLanguages], index) => (
            <React.Fragment key={groupName}>
              {index !== 0 && <ListDivider role='none' />}
              <List
                aria-labelledby={`select-group-${groupName}`}
                sx={{ '--ListItemDecorator-size': '28px' }}
              >
                <ListItem
                  id={`select-group-${groupName}`}
                  sticky
                >
                  <Typography
                    level='body-xs'
                    textTransform='uppercase'
                  >
                    {groupName} ({groupLanguages.length})
                  </Typography>
                </ListItem>
                {groupLanguages.map((lang) => (
                  <Option
                    key={lang}
                    value={lang}
                    label={
                      <React.Fragment>
                        <Chip
                          size='sm'
                          color={colors[groupName as keyof typeof optionGroups]}
                          sx={{ borderRadius: 'xs', mr: 1 }}
                        >
                          {groupName}
                        </Chip>{' '}
                        {SPEECH_TO_TEXT_SUPPORTED_LANGUAGES[lang]}
                      </React.Fragment>
                    }
                    sx={{
                      [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                        {
                          opacity: 1,
                        },
                    }}
                  >
                    <ListItemDecorator sx={{ opacity: 0 }}>
                      <Check />
                    </ListItemDecorator>
                    {SPEECH_TO_TEXT_SUPPORTED_LANGUAGES[lang]}
                  </Option>
                ))}
              </List>
            </React.Fragment>
          ),
        )}
      </Select>
    );
  },
);
LanguageSelector.displayName = 'LanguageSelector';
export default LanguageSelector;
