/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { StoreRootState } from '../stores/stores';
import { useCallback, useMemo } from 'react';
import { SubscriptionType } from '../types/SubscriptionType';
import {
  calculateCreditsToUseForSpeechToText,
  calculateCreditsToUseForTextToSpeech,
} from '../utils/creditUtils';
import { ProductType } from '../types/ProductTypes';
import { ProductTier } from '../types/ProductTier';

export enum CreditCheckResult {
  INVALID = 'INVALID',
  NOT_ENOUGH_CREDITS = 'NOT_ENOUGH_CREDITS',
  ENOUGH_CREDITS = 'ENOUGH_CREDITS', // enough credits in the pay as you go credits
  ENOUGH_SUBSCRIPTION_CREDITS = 'ENOUGH_SUBSCRIPTION_CREDITS', // could use the subscription to cover the request
}

export const useCheckSpeechToTextCredits = () => {
  const activeSubscriptions = useSelector(
    (state: StoreRootState) => state.subscription.aciveSubscriptions,
  );

  const isSpeechToTextSubscriptionActive = useMemo(() => {
    return activeSubscriptions[SubscriptionType.UNLIMITED_SPEECH_TO_TEXT]
      ? true
      : false;
  }, [activeSubscriptions]);

  const speechToTextSubscriptionRemainingMinutes = useMemo(() => {
    return (
      activeSubscriptions[SubscriptionType.UNLIMITED_SPEECH_TO_TEXT]?.[
        'speechToTextMinutesRemaining'
      ] ?? 0
    );
  }, [activeSubscriptions]);

  const productPrices = useSelector(
    (state: StoreRootState) => state.product.productPrices,
  );

  const remainingCredits = useSelector(
    (state: StoreRootState) => state.credits.remainingCredits,
  );

  return useCallback(
    (audioDurationInMs: number): CreditCheckResult => {
      const durationMinutes = audioDurationInMs / 60000.0; // There are 60,000 milliseconds in a minute
      const roundedMinutes = Math.ceil(durationMinutes);

      const canUseSubscription =
        isSpeechToTextSubscriptionActive &&
        roundedMinutes <= speechToTextSubscriptionRemainingMinutes;
      if (canUseSubscription) {
        return CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS;
      }

      const creditsToUse = calculateCreditsToUseForSpeechToText(
        audioDurationInMs,
        productPrices[ProductType.SPEECH_TO_TEXT]?.[ProductTier.BASIC] ?? 0,
      );
      return creditsToUse <= remainingCredits
        ? CreditCheckResult.ENOUGH_CREDITS
        : CreditCheckResult.NOT_ENOUGH_CREDITS;
    },
    [
      isSpeechToTextSubscriptionActive,
      productPrices,
      remainingCredits,
      speechToTextSubscriptionRemainingMinutes,
    ],
  );
};

export const useCheckTextToSpeechCredits = () => {
  const activeSubscriptions = useSelector(
    (state: StoreRootState) => state.subscription.aciveSubscriptions,
  );

  const isVoiceCloneAndTextToSpeechSubscriptionActive = useMemo(() => {
    return activeSubscriptions[
      SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
    ]
      ? true
      : false;
  }, [activeSubscriptions]);

  const textToSpeechSubscriptionRemainingCharacters = useMemo(() => {
    return (
      activeSubscriptions[
        SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
      ]?.['textToSpeechRemaining'] ?? 0
    );
  }, [activeSubscriptions]);

  const productPrices = useSelector(
    (state: StoreRootState) => state.product.productPrices,
  );

  const remainingCredits = useSelector(
    (state: StoreRootState) => state.credits.remainingCredits,
  );

  return useCallback(
    (textLength: number): CreditCheckResult => {
      const canUseSubscription =
        isVoiceCloneAndTextToSpeechSubscriptionActive &&
        textLength <= textToSpeechSubscriptionRemainingCharacters;
      if (canUseSubscription) {
        return CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS;
      }

      const creditsToUse = calculateCreditsToUseForTextToSpeech(
        textLength,
        productPrices[ProductType.TEXT_TO_SPEECH]?.[ProductTier.BASIC] ?? 0,
      );
      return creditsToUse <= remainingCredits
        ? CreditCheckResult.ENOUGH_CREDITS
        : CreditCheckResult.NOT_ENOUGH_CREDITS;
    },
    [
      isVoiceCloneAndTextToSpeechSubscriptionActive,
      productPrices,
      remainingCredits,
      textToSpeechSubscriptionRemainingCharacters,
    ],
  );
};

export const useCheckVoiceCloneCredits = () => {
  const activeSubscriptions = useSelector(
    (state: StoreRootState) => state.subscription.aciveSubscriptions,
  );

  const isVoiceCloneAndTextToSpeechSubscriptionActive = useMemo(() => {
    return activeSubscriptions[
      SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
    ]
      ? true
      : false;
  }, [activeSubscriptions]);

  const voiceCloneSubscriptionRemainingNumber = useMemo(() => {
    return (
      activeSubscriptions[
        SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH
      ]?.['voiceCloneRemaining'] ?? 0
    );
  }, [activeSubscriptions]);

  const productPrices = useSelector(
    (state: StoreRootState) => state.product.productPrices,
  );

  const remainingCredits = useSelector(
    (state: StoreRootState) => state.credits.remainingCredits,
  );

  const freeVoiceClones = useSelector(
    (state: StoreRootState) => state.user.freeVoiceClonesRemaining,
  );

  return useCallback((): CreditCheckResult => {
    if (freeVoiceClones > 0) {
      return CreditCheckResult.ENOUGH_CREDITS;
    }

    const canUseSubscription =
      isVoiceCloneAndTextToSpeechSubscriptionActive &&
      voiceCloneSubscriptionRemainingNumber > 0;
    if (canUseSubscription) {
      return CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS;
    }

    const creditsToUse =
      productPrices[ProductType.VOICE_CLONING]?.[ProductTier.BASIC] ?? 0;
    return creditsToUse <= remainingCredits
      ? CreditCheckResult.ENOUGH_CREDITS
      : CreditCheckResult.NOT_ENOUGH_CREDITS;
  }, [
    freeVoiceClones,
    isVoiceCloneAndTextToSpeechSubscriptionActive,
    productPrices,
    remainingCredits,
    voiceCloneSubscriptionRemainingNumber,
  ]);
};
