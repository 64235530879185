import { Card, CardContent, Typography } from '@mui/joy';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../stores/stores';
import { CreditAssignmentStatus } from '../../types/CreditAssignmentStatus';
import WarningIcon from '@mui/icons-material/Warning';

const CreditsAssignmentWarningCard = React.memo(() => {
  const remainingCredits = useSelector(
    (state: StoreRootState) => state.credits.remainingCredits,
  );
  const freeCreditsAssignmentStatus = useSelector(
    (state: StoreRootState) => state.credits.freeCreditsAssignmentStatus,
  );

  if (
    remainingCredits >= 1000 ||
    freeCreditsAssignmentStatus !==
      CreditAssignmentStatus.ASSIGNED_TO_DIFFERENT_USER
  ) {
    return null;
  }

  return (
    <Card
      variant='soft'
      invertedColors
      sx={{ width: '100%', marginTop: 4, marginBottom: 4 }}
    >
      <CardContent
        orientation='horizontal'
        sx={{ alignItems: 'center' }}
      >
        <WarningIcon
          color='warning'
          sx={{ width: 32, height: 32 }}
        />
        <CardContent>
          <Typography
            level='body-md'
            fontWeight={'bold'}
          >
            No Free Credits Available
          </Typography>
          <Typography
            level='body-sm'
            marginTop={1}
          >
            It looks like you have already been assigned free credits to another
            account. Free credits are a one-time treat!
          </Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
});
CreditsAssignmentWarningCard.displayName = 'CreditsAssignmentWarningCard';
export default CreditsAssignmentWarningCard;
