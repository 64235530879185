import { Card, CardContent, Typography } from '@mui/joy';
import React from 'react';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { convertCreditsToUserText } from '../../utils/creditUtils';

interface PricePreviewBoxProps {
  totalCredits?: number;
  description: string;
}

const PricePreviewBox = React.memo(
  ({ totalCredits, description }: PricePreviewBoxProps) => {
    return (
      <Card
        variant='soft'
        color='success'
        invertedColors
        sx={{ width: '100%' }}
      >
        <CardContent
          orientation='horizontal'
          sx={{ alignItems: 'center' }}
        >
          <MonetizationOnRoundedIcon sx={{ width: 36, height: 36 }} />
          <CardContent>
            {totalCredits && (
              <Typography
                level='body-sm'
                fontWeight={'bold'}
              >
                {`Estimated credits to use: ${convertCreditsToUserText(
                  totalCredits,
                )}`}
              </Typography>
            )}
            <Typography
              level='body-sm'
              marginTop={1}
            >
              {description}
            </Typography>
          </CardContent>
        </CardContent>
      </Card>
    );
  },
);
PricePreviewBox.displayName = 'PricePreviewBox';
export default PricePreviewBox;
