import { Stack, Typography, TypographySystem } from '@mui/joy';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

const VoiceModelNameRow = React.memo(
  ({
    modelName,
    isFirstPartyModel,
    fontLevel,
  }: {
    modelName: string;
    isFirstPartyModel: boolean;
    fontLevel?: keyof TypographySystem;
  }) => {
    const textFont = fontLevel ? fontLevel : 'body-xs';

    return (
      <Stack
        direction='row'
        alignItems={'center'}
      >
        <Typography
          level={textFont}
          color='neutral'
        >
          Voice Model:
        </Typography>
        <Typography
          level={textFont}
          color='neutral'
          sx={{ marginLeft: 0.8 }}
        >
          {modelName}
        </Typography>
        <CircleIcon sx={{ width: 4, height: 4, marginLeft: 0.8 }} />
        <Typography
          level={textFont}
          color='neutral'
          sx={{ marginLeft: 0.8 }}
        >
          {isFirstPartyModel
            ? 'from voice-vector.com'
            : 'from your cloned voice'}
        </Typography>
      </Stack>
    );
  },
);
VoiceModelNameRow.displayName = 'VoiceModelNameRow';
export default VoiceModelNameRow;
