import { Box, Snackbar, Stack, Typography } from '@mui/joy';
import React, { useCallback, useState } from 'react';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import SubscriptionHistoryTable from '../SubscriptionHistoryTable';

const SubscriptionHistoryTab = React.memo(() => {
  const [snackBarContent, setSnackBarContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState<
    'success' | 'warning' | 'danger'
  >('danger');

  const showSnackMessage = useCallback((message: string, isError: boolean) => {
    setSnackBarContent(message);
    setSnackbarOpen(true);
    setSnackBarColor(isError ? 'danger' : 'success');
  }, []);

  return (
    <Box>
      <Typography
        level='title-lg'
        marginTop={1}
        fontWeight={'bold'}
      >
        Your subscription history
      </Typography>

      <Stack
        direction='row'
        sx={{ gap: 1 }}
        alignItems={'center'}
        marginTop={2}
      >
        <HistoryOutlinedIcon sx={{ widht: 24, height: 24 }} />
        <Typography level='title-md'>
          Only the latest 50 subscription payments are kept and displayed
        </Typography>
      </Stack>

      <Box
        width={'100%'}
        marginTop={4}
        marginBottom={8}
      >
        <SubscriptionHistoryTable showSnackMessage={showSnackMessage} />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant='solid'
        color={snackBarColor}
        invertedColors
        startDecorator={<BubbleChartRoundedIcon />}
        autoHideDuration={5000}
      >
        <Typography level='title-lg'>{snackBarContent}</Typography>
      </Snackbar>
    </Box>
  );
});
SubscriptionHistoryTab.displayName = 'SubscriptionHistoryTab';
export default SubscriptionHistoryTab;
