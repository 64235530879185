import { SubscriptionType } from '../types/SubscriptionType';

export const getReadableSubscriptionName = (subscription: SubscriptionType) => {
  switch (subscription) {
    case SubscriptionType.UNLIMITED_VOICE_CLONE_AND_TEXT_TO_SPEECH:
      return 'Voice cloning and Text to Speech';
    case SubscriptionType.UNLIMITED_SPEECH_TO_TEXT:
      return 'Speech to Text';
  }
};
