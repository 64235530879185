import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { StoreRootState } from '../stores/stores';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { updateBrowerFingerPrint } from '../stores/userSlice';

export const useBrowserFingerPrint = () => {
  const currentFingerPrint = useSelector(
    (state: StoreRootState) => state.user.browserFingerPrint,
  );

  const dispatch = useDispatch();

  const initializeBrowserFingerPrint = useCallback(async () => {
    const fpPromise = await FingerprintJS.load();
    const result = await fpPromise.get();
    const visitorId = result.visitorId;

    dispatch(updateBrowerFingerPrint(visitorId));
    return visitorId;
  }, [dispatch]);

  return {
    currentFingerPrint,
    initializeBrowserFingerPrint,
  };
};
