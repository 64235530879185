import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StoreRootState } from '../stores/stores';
import { UserLocation } from '../types/UserLocation';
import { sendGetRequest } from '../requests/sendRequests';
import { updateLocation } from '../stores/userSlice';

const DEVICE_LOCATION_KEY = 'device_location_info';
const LOCATION_EXPIRE_TIME_MS = 1000 * 60 * 60 * 24 * 7;

export const useUserLocation = () => {
  const currentLocation = useSelector(
    (state: StoreRootState) => state.user.location,
  );

  const dispatch = useDispatch();

  const locationNeedsToBeUpdated = useCallback(
    (location: UserLocation) =>
      !location.lastLocationUpdateTime ||
      !location.country ||
      Date.now() - location.lastLocationUpdateTime > LOCATION_EXPIRE_TIME_MS,
    [],
  );

  const initializeLocation = useCallback(async () => {
    let needsToUpdateLocation = true;
    let storedLocation: UserLocation = {
      country: '',
      region: '',
      city: '',
      lastLocationUpdateTime: 0,
    };
    const storedLocationStr = localStorage.getItem(DEVICE_LOCATION_KEY);
    if (storedLocationStr) {
      try {
        storedLocation = JSON.parse(storedLocationStr) as UserLocation;

        if (storedLocation && !locationNeedsToBeUpdated(storedLocation)) {
          needsToUpdateLocation = false;
        }
      } catch (e: any) {
        console.error('Failed to parse stored location in local storage: ', e);
      }
    }

    if (needsToUpdateLocation) {
      try {
        const response = await sendGetRequest({
          requestPath: '/user/region',
          queryParams: {},
        });

        storedLocation = response as UserLocation;
        localStorage.setItem(
          DEVICE_LOCATION_KEY,
          JSON.stringify(storedLocation),
        );
      } catch (e: any) {
        console.error('Failed to get user location information: ', e);
      }
    }

    dispatch(updateLocation(storedLocation));
  }, [dispatch, locationNeedsToBeUpdated]);

  return {
    currentLocation,
    initializeLocation,
  };
};
