import { createSlice } from '@reduxjs/toolkit';
import { ProductTier } from '../types/ProductTier';
import { ProductType } from '../types/ProductTypes';
import { ProductPrice, SubscriptionPrice } from '../types/ProductPrice';
import { SubscriptionType } from '../types/SubscriptionType';
import { FAQ } from '../types/FAQ';

interface ProductStoreState {
  productPrices: Partial<
    Record<ProductType, Partial<Record<ProductTier, number>>>
  >;
  subscriptionPrices: Partial<Record<SubscriptionType, SubscriptionPrice>>;
  demoVideoUrls: Partial<Record<ProductType, string>>;
  resultRestainDays: number;
  faqList: FAQ[];
}

const initialState: ProductStoreState = {
  productPrices: {},
  subscriptionPrices: {},
  demoVideoUrls: {},
  resultRestainDays: 14,
  faqList: [],
};

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductPrices: (state, action) => {
      const productPricesToSet = action.payload as ProductPrice[];
      productPricesToSet.forEach((price) => {
        if (!state.productPrices[price.productName]) {
          state.productPrices[price.productName] = {};
        }
        state.productPrices[price.productName]![price.tier] = price.credits;
      });
    },

    setSubscriptionPrices: (state, action) => {
      const subscriptionPricesToSet = action.payload as SubscriptionPrice[];
      subscriptionPricesToSet.forEach((price) => {
        state.subscriptionPrices[price.subscription] = price;
      });
    },

    updateProductPrice: (state, action) => {
      const { productName, tier, credits } = action.payload as ProductPrice;
      if (!state.productPrices[productName]) {
        state.productPrices[productName] = {};
      }
      state.productPrices[productName]![tier] = credits;
    },

    setProductDemoVideoUrl: (state, action) => {
      const videoUrls = action.payload as Record<ProductType, string>;
      Object.entries(videoUrls).forEach(([key, value]) => {
        state.demoVideoUrls[key as ProductType] = value;
      });
    },

    setResultRestainDays: (state, action) => {
      state.resultRestainDays = action.payload as number;
    },

    setFaqList: (state, action) => {
      state.faqList = action.payload as FAQ[];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProductPrices,
  setSubscriptionPrices,
  updateProductPrice,
  setProductDemoVideoUrl,
  setResultRestainDays,
  setFaqList,
} = productSlice.actions;

export default productSlice.reducer;
