import { Stack, Typography, TypographySystem } from '@mui/joy';
import React from 'react';
import {
  getAudioDurationString,
  getDateString,
  getTimeString,
} from '../../utils/timeUtils';
import CircleIcon from '@mui/icons-material/Circle';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';

const TimeRow = React.memo(
  ({
    creationTime,
    audioDuration,
    fontLevel,
  }: {
    creationTime: number;
    audioDuration?: number;
    fontLevel?: keyof TypographySystem;
  }) => {
    const textFont = fontLevel ? fontLevel : 'body-xs';

    return (
      <Stack
        direction='row'
        alignItems={'center'}
      >
        <Typography
          level={textFont}
          color='neutral'
        >
          {getTimeString(creationTime)}
        </Typography>
        <CircleIcon sx={{ width: 4, height: 4, marginLeft: 0.8 }} />
        <Typography
          level={textFont}
          color='neutral'
          sx={{ marginLeft: 0.8 }}
        >
          {getDateString(creationTime)}
        </Typography>
        {audioDuration && (
          <MusicNoteRoundedIcon
            sx={{ width: 16, height: 16, marginLeft: 1.5 }}
          />
        )}
        {audioDuration && (
          <Typography
            level={textFont}
            color='neutral'
            sx={{ marginLeft: 0.2 }}
          >
            {getAudioDurationString(audioDuration)}
          </Typography>
        )}
      </Stack>
    );
  },
);
TimeRow.displayName = 'TimeRow';
export default TimeRow;
