// 1 credit equals to 1/1^6 dollar
export const convertCreditsToUserText = (credits: number, digits?: number) => {
  const formattedCredits = (credits / 1000 / 1000).toFixed(digits ?? 3);
  const trimmedCredits = parseFloat(formattedCredits).toString();
  return `$${trimmedCredits}`;
};

export const mapServiceSourceToText = (serviceSource: string) => {
  switch (serviceSource) {
    case 'speech_rec_request':
      return 'speech to text';
    case 'text_to_speech_request':
      return 'text to speech';
    case 'voice_clone_training_request':
      return 'voice cloning';
    case 'subscription':
      return 'pay subscription';
    default:
      return 'Unknown';
  }
};

export const calculateCreditsToUseForSpeechToText = (
  audioDurationInMs: number,
  creditsPerMinute: number,
): number => {
  const durationMinutes = audioDurationInMs / 60000.0; // There are 60,000 milliseconds in a minute
  const roundedMinutes = Math.ceil(durationMinutes);
  return Math.ceil(roundedMinutes * creditsPerMinute);
};

export const calculateCreditsToUseForTextToSpeech = (
  textLength: number,
  creditsPerCharacter: number,
): number => {
  const creditsToUse = textLength * creditsPerCharacter;
  return Math.ceil(creditsToUse / 1000) * 1000;
};
