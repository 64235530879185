/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Select,
  Option,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Box,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
  Textarea,
  CircularProgress,
} from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { sendGetRequest, sendPostRequest } from '../../requests/sendRequests';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextToSpeechEntry } from '../../types/TextToSpeechEntry';
import { VoiceModel } from '../../types/VoiceModel';
import VoiceCloneActionBanner from '../voice_cloning/VoiceCloneActionBanner';
import VoiceOverOffRoundedIcon from '@mui/icons-material/VoiceOverOffRounded';
import VoiceModelLine from './VoiceModelLine';
import LanguageSelector from './LanguageSelector';
import {
  calculateCreditsToUseForTextToSpeech,
  convertCreditsToUserText,
} from '../../utils/creditUtils';
import { ProductType } from '../../types/ProductTypes';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../stores/stores';
import { ProductTier } from '../../types/ProductTier';
import PricePreviewBox from '../shared/PricePreviewBox';
import { logClientError, useLogUserEvent } from '../../logging/useLogUserEvent';
import { UserEventTypes } from '../../logging/UserEventTypes';
import {
  CreditCheckResult,
  useCheckTextToSpeechCredits,
} from '../../hooks/useCreditsCheck';

interface NewTextToSpeechRequestModalProps {
  handleClose: ({
    // eslint-disable-next-line no-unused-vars
    createdRequest,
  }: {
    createdRequest?: TextToSpeechEntry;
  }) => void;
  isOpen: boolean;
  requestedLanguage?: string;
  requestedModelId?: string;
  requestedVoiceType?: string;
}

const MIN_TEXT_LENGTH = 20;
const MAX_TEXT_LENGTH = 8000;

const NewTextToSpeechRequestModal = React.memo(
  ({
    handleClose,
    isOpen,
    requestedLanguage,
    requestedModelId,
    requestedVoiceType,
  }: NewTextToSpeechRequestModalProps) => {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedModelId, setSelectedModelId] = useState<string>('');
    const [requestText, setRequestText] = useState<string>('');
    const [languages, setLanguages] = useState<string[]>([]);
    // eslint-disable-next-line no-unused-vars
    // const [_isFetchingLanguages, setIsFetchingLanguages] = useState(false);
    const [voiceType, setVoiceType] = useState<string>('first_party'); // self_cloned or first_party
    const [voiceModels, setVoiceModels] = useState<VoiceModel[]>([]);
    const [isFetchingModels, setIsFetchingModels] = useState(false);

    const firstPartyLanguageModelsRef = React.useRef<
      Record<string, VoiceModel[]>
    >({});
    const selfClonedLanguageModelsRef = React.useRef<
      Record<string, VoiceModel[]>
    >({});

    const modelIdToModelMapForCurrentLanguageRef = React.useRef<
      Record<string, VoiceModel>
    >({});

    const [validationError, setValidationError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const [languageTooltipOpen, setLanguageTooltipOpen] =
      React.useState<boolean>(false);
    const [textTooltipOpen, setTextTooltipOpen] =
      React.useState<boolean>(false);

    const logUserEvent = useLogUserEvent();

    // When user gives new inputs, clear validation error
    useEffect(() => {
      setValidationError('');
    }, [selectedLanguage, selectedModelId, requestText, voiceType]);

    const productPrices = useSelector(
      (state: StoreRootState) => state.product.productPrices,
    );

    const checkEnoughCredits = useCheckTextToSpeechCredits();

    // Fetch eligible languages
    useEffect(() => {
      const fetchLanguages = async () => {
        // setIsFetchingLanguages(true);
        const response = await sendGetRequest({
          requestPath: '/text_to_speech/languages',
          queryParams: {},
        });

        const eligibleLanguages = response as string[];
        setLanguages(eligibleLanguages);
        // setIsFetchingLanguages(false);
      };

      fetchLanguages();
    }, []);

    useEffect(() => {
      // When the modal is opened, clear all states to start a new request
      if (isOpen) {
        setSelectedLanguage(requestedLanguage ?? '');
        setSelectedModelId(requestedModelId ?? '');
        setVoiceType(requestedVoiceType ?? 'first_party');
        setRequestText('');

        if (!requestedModelId) {
          setVoiceModels([]);
          firstPartyLanguageModelsRef.current = {};
          selfClonedLanguageModelsRef.current = {};
          modelIdToModelMapForCurrentLanguageRef.current = {};
        }

        setLanguageTooltipOpen(false);
        setTextTooltipOpen(false);
        setErrorMessage('');
        setValidationError('');
        setErrorMessage('');
        setIsSubmitting(false);
      }
    }, [isOpen, requestedLanguage, requestedModelId, requestedVoiceType]);

    const estimatedCreditsToUse = useMemo(() => {
      if (requestText.trim().length && !validationError && !errorMessage) {
        return calculateCreditsToUseForTextToSpeech(
          requestText.trim().length,
          productPrices[ProductType.TEXT_TO_SPEECH]?.[ProductTier.BASIC] ?? 0,
        );
      }

      return null;
    }, [errorMessage, productPrices, requestText, validationError]);

    const creditsCheckResult = useMemo(() => {
      if (requestText.trim().length && !validationError && !errorMessage) {
        return checkEnoughCredits(requestText.trim().length);
      }

      return CreditCheckResult.INVALID;
    }, [checkEnoughCredits, errorMessage, requestText, validationError]);

    const handleRequestTextChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRequestText(event.target.value);
      },
      [],
    );

    const handleLanguageChange = useCallback(
      (_event: React.SyntheticEvent | null, newValue: string | null) => {
        if (newValue && newValue !== selectedLanguage) {
          setSelectedLanguage(newValue);
        }
      },
      [selectedLanguage],
    );

    const handleVoiceTypeChange = useCallback(
      (_event: React.SyntheticEvent | null, newValue: string | null) => {
        if (newValue && newValue !== voiceType) {
          setVoiceType(newValue);
        }
      },
      [voiceType],
    );

    const handleModelChange = useCallback(
      (_event: React.SyntheticEvent | null, newValue: string | null) => {
        if (newValue && newValue !== selectedModelId) {
          setSelectedModelId(newValue);
        }
      },
      [selectedModelId],
    );

    const updateVoiceModels = useCallback(async () => {
      const modelsRef =
        voiceType === 'self_cloned'
          ? selfClonedLanguageModelsRef
          : firstPartyLanguageModelsRef;
      let updatedVoiceModels = modelsRef.current[selectedLanguage];

      if (!updatedVoiceModels) {
        setIsFetchingModels(true);

        const response = await sendPostRequest({
          requestPath: '/text_to_speech/voice_models',
          payload: {
            language: selectedLanguage,
            modelSource: voiceType,
          },
        });
        updatedVoiceModels = response as VoiceModel[];
        updatedVoiceModels.sort((a, b) => {
          // First sort by gender
          const genderComparison = a.gender.localeCompare(b.gender);
          if (genderComparison !== 0) {
            return genderComparison;
          }
          // If genders are the same, sort by modelName
          return a.modelName.localeCompare(b.modelName);
        });
        modelsRef.current[selectedLanguage] = updatedVoiceModels;

        setIsFetchingModels(false);
      }

      modelIdToModelMapForCurrentLanguageRef.current = {};
      updatedVoiceModels.forEach((model) => {
        modelIdToModelMapForCurrentLanguageRef.current[model.modelId] = model;
      });

      setVoiceModels(updatedVoiceModels);

      if (
        requestedModelId &&
        requestedModelId in modelIdToModelMapForCurrentLanguageRef.current
      ) {
        setSelectedModelId(requestedModelId);
      } else if (voiceType === 'first_party') {
        if ('James' in modelIdToModelMapForCurrentLanguageRef.current) {
          setSelectedModelId('James');
        } else if ('雨婷' in modelIdToModelMapForCurrentLanguageRef.current) {
          setSelectedModelId('雨婷');
        } else {
          setSelectedModelId(
            updatedVoiceModels.length > 0 ? updatedVoiceModels[0].modelId : '',
          );
        }
      } else {
        setSelectedModelId(
          updatedVoiceModels.length > 0 ? updatedVoiceModels[0].modelId : '',
        );
      }
    }, [requestedModelId, selectedLanguage, voiceType]);

    // Whenever selected language is changed, or voice type is changed,
    // We need to refresh the eligible voice models
    useEffect(() => {
      if (selectedLanguage && voiceType) {
        updateVoiceModels();
      }
    }, [selectedLanguage, updateVoiceModels, voiceType]);

    const handleModalClose = useCallback(
      (_event: unknown, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

        handleClose({});
      },
      [handleClose],
    );

    const handleSubmit = useCallback(async () => {
      let validationError = '';
      const textToSpeak = requestText.trim();

      if (!selectedLanguage) {
        validationError = 'Please select the language of the text';
      } else if (!selectedModelId) {
        validationError = 'Please select the voice';
      } else if (!textToSpeak) {
        validationError = 'Please enter the text for text to speech task';
      } else if (textToSpeak.length < MIN_TEXT_LENGTH) {
        validationError = `The length of the text should be at least ${MIN_TEXT_LENGTH} characters`;
      } else if (textToSpeak.length > MAX_TEXT_LENGTH) {
        validationError = `The length of the text should be no longer than ${MAX_TEXT_LENGTH} characters`;
      } else if (creditsCheckResult === CreditCheckResult.NOT_ENOUGH_CREDITS) {
        validationError =
          "You don't have enough pay as you go credits or text to speech characters in your subscription for this request. If you think this is an error, please refresh the page and try again.";
      }

      setErrorMessage('');
      setValidationError(validationError);

      if (validationError) {
        return;
      }

      setIsSubmitting(true);

      logUserEvent({
        event: UserEventTypes.TEXT_TO_SPEECH_REQUEST_SUBMIT,
        modelId: selectedModelId,
        language: selectedLanguage,
        isFirstPartyModel: voiceType === 'first_party',
        textLength: textToSpeak.length,
        estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
      });

      // Generate a request record in the database
      let requestCreated: null | TextToSpeechEntry = null;
      try {
        const response = await sendPostRequest({
          requestPath: '/text_to_speech/request',
          payload: {
            modelId: selectedModelId,
            language: selectedLanguage,
            isFirstPartyModel: voiceType === 'first_party',
            text: textToSpeak,
          },
        });

        requestCreated = response as TextToSpeechEntry;
      } catch (e: any) {
        logClientError(
          'Failed to create request in NewTextToSpeechRequestModal: ',
          e,
        );
        setErrorMessage(
          'Failed to create text to speach request. Please try again.',
        );
        setIsSubmitting(false);

        logUserEvent({
          event: UserEventTypes.TEXT_TO_SPEECH_REQUEST_RESPONSE,
          status: 'failed_at_creating_request',
          error: e.toString(),
          modelId: selectedModelId,
          language: selectedLanguage,
          isFirstPartyModel: voiceType === 'first_party',
          textLength: textToSpeak.length,
          estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
        });
        return;
      }

      setIsSubmitting(false);
      handleClose({ createdRequest: requestCreated });

      logUserEvent({
        event: UserEventTypes.TEXT_TO_SPEECH_REQUEST_RESPONSE,
        status: 'succeed',
        requestIdCreated: requestCreated?.id ?? '',
        modelId: selectedModelId,
        language: selectedLanguage,
        isFirstPartyModel: voiceType === 'first_party',
        textLength: textToSpeak.length,
        estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
      });
    }, [
      requestText,
      selectedLanguage,
      selectedModelId,
      creditsCheckResult,
      logUserEvent,
      voiceType,
      estimatedCreditsToUse,
      handleClose,
    ]);

    const [voiceModelIdBeingPlayed, setVoiceModelIdBeingPlayed] = useState('');
    const [
      audioIsPlayingForVoiceModelIdBeingPlayed,
      setAudioIsPlayingForVoiceModelIdBeingPlayed,
    ] = useState(false);

    const handleVoiceModelAudioPlayClicked = useCallback(
      (modelId: string) => {
        if (modelId === voiceModelIdBeingPlayed) {
          setAudioIsPlayingForVoiceModelIdBeingPlayed((v) => !v);
        } else {
          setVoiceModelIdBeingPlayed(modelId);
          setAudioIsPlayingForVoiceModelIdBeingPlayed(true);
        }
      },
      [voiceModelIdBeingPlayed],
    );

    return (
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        disableEscapeKeyDown
      >
        <ModalDialog
          variant='outlined'
          role='alertdialog'
          sx={{
            width: {
              xs: '90%',
              sm: '70%',
              md: '55%',
              lg: '50%',
            },
            maxWidth: 'none',
            minWidth: '240px',
            position: 'absolute',
            top: '50%',
            left: {
              xs: '50%',
              md: '60%',
            },
          }}
        >
          <DialogTitle sx={{ alignSelf: 'center' }}>
            <Typography
              level='title-lg'
              sx={{ fontWeight: 'bold' }}
            >
              New Text to Speech Request
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ gap: 1 }}>
            <FormControl>
              <FormLabel
                sx={{ marginTop: 2, display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  level='title-md'
                  sx={{ fontWeight: 'bold' }}
                >
                  Text
                </Typography>
                <Tooltip
                  title={`Please enter the text for text to speech task. The length of the text should be between ${MIN_TEXT_LENGTH} and ${MAX_TEXT_LENGTH} characters.`}
                  placement='bottom-start'
                  sx={{
                    maxWidth: {
                      xs: 250,
                      sm: 400,
                    },
                  }}
                  open={textTooltipOpen}
                  leaveTouchDelay={8000}
                  onClose={() => setTextTooltipOpen(false)}
                >
                  <IconButton
                    size='sm'
                    variant='plain'
                    color='neutral'
                    onClick={() => setTextTooltipOpen(true)}
                    onMouseEnter={() => setTextTooltipOpen(true)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </FormLabel>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Textarea
                  value={requestText}
                  onChange={handleRequestTextChange}
                  minRows={3}
                  maxRows={6}
                  variant='outlined'
                  sx={{ width: '100%' }}
                  disabled={isSubmitting}
                />
                <Box
                  textAlign={'right'}
                  marginTop={1}
                >
                  <Typography
                    level='body-sm'
                    color='neutral'
                  >
                    {requestText.trim().length + ' / ' + MAX_TEXT_LENGTH}
                  </Typography>
                </Box>
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  level='title-md'
                  sx={{ fontWeight: 'bold' }}
                >
                  Language
                </Typography>
                <Tooltip
                  title='Please select the main language of the text. English and Chinese offer the most human-like and emotionally engaging voices. Other languages currently have standard voice quality, but we are working to enhance them. Stay tuned!'
                  placement='top'
                  sx={{
                    maxWidth: {
                      xs: 250,
                      sm: 400,
                    },
                  }}
                  open={languageTooltipOpen}
                  leaveTouchDelay={8000}
                  onClose={() => setLanguageTooltipOpen(false)}
                >
                  <IconButton
                    size='sm'
                    variant='plain'
                    color='neutral'
                    onClick={() => setLanguageTooltipOpen(true)}
                    onMouseEnter={() => setLanguageTooltipOpen(true)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </FormLabel>
              <LanguageSelector
                selectedLanguage={selectedLanguage}
                languages={languages}
                onLanguageChange={handleLanguageChange}
                disabled={isSubmitting || !!requestedLanguage}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Typography
                  level='title-md'
                  sx={{ fontWeight: 'bold', marginTop: 1 }}
                >
                  Voice Type
                </Typography>
              </FormLabel>
              <Select
                id='voice-type-select'
                value={voiceType}
                onChange={handleVoiceTypeChange}
                disabled={isSubmitting || !!requestedVoiceType}
                sx={{ marginTop: 1 }}
              >
                <Option
                  key='first_party'
                  value='first_party'
                >
                  voice-vector.com provided voices
                </Option>
                <Option
                  key='self_cloned'
                  value='self_cloned'
                >
                  Your cloned voices
                </Option>
              </Select>

              {selectedLanguage && voiceModels.length > 0 && (
                <Box marginTop={2}>
                  <FormLabel>
                    <Typography
                      level='title-md'
                      sx={{ fontWeight: 'bold' }}
                    >
                      Voice Name
                    </Typography>
                  </FormLabel>
                  {isFetchingModels ? (
                    <CircularProgress />
                  ) : (
                    <Select
                      id='voice-id-select'
                      value={selectedModelId}
                      onChange={handleModelChange}
                      disabled={
                        isSubmitting || isFetchingModels || !!requestedModelId
                      }
                      sx={{ marginTop: 1 }}
                    >
                      {Object.entries(voiceModels).map(([key, model]) => (
                        <Option
                          key={key}
                          value={model.modelId}
                        >
                          <VoiceModelLine
                            model={model}
                            playPreviewVoice={
                              voiceModelIdBeingPlayed === model.modelId &&
                              audioIsPlayingForVoiceModelIdBeingPlayed
                            }
                            onPlayClicked={handleVoiceModelAudioPlayClicked}
                          />
                        </Option>
                      ))}
                    </Select>
                  )}
                </Box>
              )}
            </FormControl>

            {selectedLanguage &&
              voiceType === 'self_cloned' &&
              voiceModels.length === 0 &&
              !isFetchingModels && (
                <VoiceCloneActionBanner
                  bannerIcon={
                    <VoiceOverOffRoundedIcon sx={{ width: 36, height: 36 }} />
                  }
                  bannerText="You don't have any cloned voice for the selected language yet. Try to clone one first."
                />
              )}

            {validationError && (
              <Typography
                level='body-sm'
                color='danger'
              >
                {validationError}
              </Typography>
            )}

            {errorMessage && (
              <Typography
                level='body-sm'
                color='danger'
              >
                {errorMessage}
              </Typography>
            )}

            {estimatedCreditsToUse && (
              <Box marginTop={2}>
                <PricePreviewBox
                  totalCredits={
                    creditsCheckResult !==
                    CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS
                      ? estimatedCreditsToUse
                      : undefined
                  }
                  description={
                    creditsCheckResult ===
                    CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS
                      ? `${
                          requestText.trim().length
                        } characters will be consumed from your Voice Cloning and Text to Speech subscription plan.`
                      : `Text length: ${
                          requestText.trim().length
                        }, Credits per character: ${convertCreditsToUserText(
                          productPrices[ProductType.TEXT_TO_SPEECH]?.[
                            ProductTier.BASIC
                          ] ?? 0,
                          6,
                        )}. Total credits round to the nearest $0.001`
                  }
                />
              </Box>
            )}

            {isSubmitting && <LinearProgress />}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose({});

                logUserEvent({
                  event: UserEventTypes.TEXT_TO_SPEECH_REQUEST_CANCELLED,
                  modelId: selectedModelId,
                  language: selectedLanguage,
                  isFirstPartyModel: voiceType === 'first_party',
                  textLength: requestText.trim().length,
                  estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
                });
              }}
              variant='outlined'
              color='neutral'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant='solid'
              color='primary'
              disabled={isSubmitting || validationError.trim().length > 0}
            >
              Submit
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  },
);

NewTextToSpeechRequestModal.displayName = 'NewTextToSpeechRequestModal';
export default NewTextToSpeechRequestModal;
