import React, { useCallback, useEffect, useRef, useState } from "react";

import { useAudioTranscripts } from "../../hooks/useAudioTranscripts";
import {
  Box,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import { getAudioDurationString } from "../../utils/timeUtils";
import { AudioTranscript } from "../../types/AudioTranscript";

interface AudioTranscriptionViewerProps {
  srtUrl: string;
  currentAudioSeconds: number;
  // eslint-disable-next-line no-unused-vars
  onTranscriptLoaded?: (audioTranscripts: AudioTranscript[]) => void;
}

export const AudioTranscriptionViewer = React.memo(
  ({ srtUrl, currentAudioSeconds, onTranscriptLoaded }: AudioTranscriptionViewerProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const refs = useRef<(HTMLLIElement | null)[]>([]);

    /********************************************************************************************************
     * Generate transcript map from time in second to index of transcript
     ******************************************************************************************************* */
    const {
      srcInitialized,
      audioTranscripts,
      initializeAudioTranscripts,
      getTranscriptIndexForSecond,
    } = useAudioTranscripts();

    useEffect(() => {
      if (!srtUrl) {
        return;
      }

      initializeAudioTranscripts(srtUrl);
    }, [initializeAudioTranscripts, srtUrl]);

    useEffect(() => {
      if (srcInitialized) {
        onTranscriptLoaded && onTranscriptLoaded(audioTranscripts);
      }
    }, [srcInitialized, onTranscriptLoaded, audioTranscripts]);

    const scrollToActiveIndex = useCallback((index: number) => {
      if (index >= 0 && index < refs.current.length && refs.current[index]) {
        refs.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, []);

    useEffect(() => {
      const index = getTranscriptIndexForSecond(currentAudioSeconds);
      if (index >= 0) {
        setActiveIndex(index);
        scrollToActiveIndex(index);
      }
    }, [currentAudioSeconds, getTranscriptIndexForSecond, scrollToActiveIndex]);

    if (!srcInitialized) {
      return (
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection={"column"} alignItems={"center"}>
            <CircularProgress color="primary" sx={{ "--CircularProgress-size": "40px" }} />

            <Typography color="neutral" level="title-md" marginTop={3}>
              Loading Transcripts...
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          height: 600,
          overflowY: "auto",
          border: "1px solid",
          borderRadius: "8px",
          borderColor: "divider",
          boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        }}
      >
        <List>
          {audioTranscripts.map((transcript, index) => (
            <ListItem key={index} ref={(el) => (refs.current[index] = el)}>
              <ListItemDecorator>
                <Chip> {getAudioDurationString(transcript.startTime)}</Chip>
              </ListItemDecorator>
              <ListItemContent
                sx={{
                  marginLeft: 2,
                  width: "75%",
                  backgroundColor: index === activeIndex ? "#bfdbf7" : "transparent",
                  color: index === activeIndex ? "#031d44" : "gray",
                  transition: "background-color 0.3s ease",
                  borderRadius: "8px",
                  padding: "8px",
                }}
              >
                {transcript.text}
              </ListItemContent>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }
);
AudioTranscriptionViewer.displayName = "AudioTranscriptionViewer";
export default AudioTranscriptionViewer;
