import { createSlice } from '@reduxjs/toolkit';
import { UserLocation } from '../types/UserLocation';

interface UserInfoState {
  location: UserLocation;
  browserFingerPrint: string;
  freeVoiceClonesRemaining: number;
}

const initialState: UserInfoState = {
  location: {
    country: '',
    region: '',
    city: '',
    lastLocationUpdateTime: 0,
  },
  browserFingerPrint: '',
  freeVoiceClonesRemaining: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateLocation: (state, action) => {
      state.location = action.payload as UserLocation;
    },

    updateBrowerFingerPrint: (state, action) => {
      state.browserFingerPrint = action.payload as string;
    },

    updateFreeVoiceClonesRemaining: (state, action) => {
      state.freeVoiceClonesRemaining = action.payload as number;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLocation,
  updateBrowerFingerPrint,
  updateFreeVoiceClonesRemaining,
} = userSlice.actions;

export default userSlice.reducer;
