import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from '@mui/joy';
import React from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import TimeRow from '../shared/TimeRow';
import StatusRow from '../shared/StatusRow';
import { useNavigate } from 'react-router-dom';
import { RequestStatus } from '../../types/RequestStatus';
import { VoiceCloneEntry } from '../../types/VoiceCloneEntry';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { APPS_TAB_PATH } from '../../data/pageLinks';

interface VoiceCloneModelEntryCardProps {
  entry: VoiceCloneEntry;
}

const MAX_CHARACTERS_FOR_TEXT_PREVIEW = 200;

const VoiceCloneModelEntryCard = React.memo(
  ({ entry }: VoiceCloneModelEntryCardProps) => {
    const navigate = useNavigate();

    return (
      <Card
        variant='outlined'
        sx={{
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src='/icons/audio_wav_icon.png'
            alt='audio wav icon'
            style={{ width: 32, height: 32 }}
          />
          <ChevronRightRoundedIcon sx={{ marginLeft: 1 }} />
          <FileCopyOutlinedIcon sx={{ marginLeft: 1 }} />
        </Box>
        <CardContent>
          <Typography
            level='title-lg'
            marginBottom={0.5}
          >
            {entry.modelName}
          </Typography>
          <TimeRow
            creationTime={entry.createdAt}
            audioDuration={entry.audioDurationInMs}
          />
          <Box marginY={0.5}>
            <StatusRow
              status={entry.status}
              // creditsUsed={entry.creditsUsed} // Intended to not show in card in the list page :)
              errorMsg={entry.errorMessage}
            />
          </Box>
          <Typography
            level='body-sm'
            marginTop={0.5}
          >
            {entry.audioAsr
              ? entry.audioAsr.slice(0, MAX_CHARACTERS_FOR_TEXT_PREVIEW) +
                ' ......'
              : ''}
          </Typography>
        </CardContent>
        <CardActions buttonFlex='0 1 100%'>
          <Button
            variant='outlined'
            color='neutral'
            sx={{ borderRadius: 6 }}
            onClick={() => navigate(`${APPS_TAB_PATH.VOICE_CLONE}/${entry.id}`)}
          >
            View
          </Button>
          {/* <Button
              variant='outlined'
              color='neutral'
              sx={{ borderRadius: 6 }}
            >
              Delete
            </Button> */}
        </CardActions>
        {entry.status === RequestStatus.PROCESSING && (
          <LinearProgress
            variant='soft'
            size='sm'
            color='neutral'
          />
        )}
      </Card>
    );
  },
);
VoiceCloneModelEntryCard.displayName = 'VoiceCloneModelEntryCard';
export default VoiceCloneModelEntryCard;
