export const getTimeString = (timestamp: number): string => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed

  return `${formattedHours}:${formattedMinutes}${ampm}`;
};

export const getDateString = (timestamp: number): string => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getAudioDurationString = (durationInMs: number): string => {
  // Calculate the number of hours
  const hours = Math.floor(durationInMs / (1000 * 60 * 60));

  // Calculate the remaining minutes
  const minutes = Math.floor((durationInMs % (1000 * 60 * 60)) / (1000 * 60));

  // Calculate the remaining seconds
  const seconds = Math.floor((durationInMs % (1000 * 60)) / 1000);

  // Format the hours, minutes, and seconds with leading zeros
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  // Return the formatted duration
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
