import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { toggleSidebar } from '../utils';
import { Box, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { APPS_TAB_PATH } from '../data/pageLinks';

export default function Header() {
  const navigate = useNavigate();

  return (
    <Sheet
      sx={{
        display: { xs: 'flex', md: 'none' },
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 'var(--Header-height)',
        zIndex: 9995,
        p: 2,
        gap: 1,
        borderBottom: '1px solid',
        borderColor: 'background.level1',
        boxShadow: 'sm',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px',
            [theme.breakpoints.up('md')]: {
              '--Header-height': '0px',
            },
          },
        })}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton
          onClick={() => toggleSidebar()}
          variant='outlined'
          color='neutral'
          size='sm'
        >
          <MenuIcon />
        </IconButton>
        <img
          src='/favicon.ico'
          alt='voice-vector.com Logo'
          style={{ marginLeft: 8, width: 30, height: 30 }}
          onClick={() => navigate(APPS_TAB_PATH.HOME_SHORT)}
        />
        <Typography
          level='title-lg'
          sx={{ fontWeight: 'medium' }}
        >
          voice-vector.com
        </Typography>
      </Box>
    </Sheet>
  );
}
