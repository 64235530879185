import { useCallback, useState } from 'react';
import { AudioTranscript } from '../types/AudioTranscript';
import { fetchAndParseSrtFile } from '../utils/transcriptUtils';

export const useAudioTranscripts = () => {
  const [srcInitialized, setSrcInitialized] = useState(false);
  const [audioTranscripts, setAudioTranscripts] = useState<AudioTranscript[]>([]);

  // map from audio second to the index of the audio transcript in the audioTranscripts array
  const [audioTranscriptsSecMap, setAudioTranscriptsSecMap] = useState<Record<number, number>>({});

  const initializeAudioTranscripts = useCallback(async (srtUrl: string) => {
    setSrcInitialized(false);

    const loadedTranscripts = await fetchAndParseSrtFile(srtUrl);

    const transcriptsSecMap: Record<number, number> = {};
    for (let i = 0; i < loadedTranscripts.length; i++) {
      const endTime = Math.floor(loadedTranscripts[i].endTime / 1000);
      for (let j = Math.floor(loadedTranscripts[i].startTime / 1000); j <= endTime; j++) {
        transcriptsSecMap[j] = i;
      }
    }

    setAudioTranscripts(loadedTranscripts);
    setAudioTranscriptsSecMap(transcriptsSecMap);
    setSrcInitialized(true);
  }, []);

  const getTranscriptIndexForSecond = useCallback(
    (second: number) => {
      if (second in audioTranscriptsSecMap) {
        return audioTranscriptsSecMap[second];
      } else {
        return -1;
      }
    },
    [audioTranscriptsSecMap],
  );

  return {
    srcInitialized,
    audioTranscripts,
    initializeAudioTranscripts,
    getTranscriptIndexForSecond,
  };
};
