/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Select,
  Option,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Box,
  Typography,
  LinearProgress,
  Stack,
} from '@mui/joy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SPEECH_TO_TEXT_SUPPORTED_LANGUAGES } from '../../data/languages';
import { sendPostRequest } from '../../requests/sendRequests';
import { SpeechToTextEntry } from '../../types/SpeechToTextTypes';
import PricePreviewBox from '../shared/PricePreviewBox';
import {
  calculateCreditsToUseForSpeechToText,
  convertCreditsToUserText,
} from '../../utils/creditUtils';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../stores/stores';
import { ProductType } from '../../types/ProductTypes';
import { ProductTier } from '../../types/ProductTier';
import { UserEventTypes } from '../../logging/UserEventTypes';
import { logClientError, useLogUserEvent } from '../../logging/useLogUserEvent';
import {
  CreditCheckResult,
  useCheckSpeechToTextCredits,
} from '../../hooks/useCreditsCheck';
import { uploadFileToS3WithMultipart } from '../../requests/multipartUpload';

interface NewSpeechToTextRequestModalProps {
  handleClose: ({
    // eslint-disable-next-line no-unused-vars
    createdRequest,
  }: {
    createdRequest?: SpeechToTextEntry;
  }) => void;
  isOpen: boolean;
}

const NewSpeechToTextRequestModal = React.memo(
  ({ handleClose, isOpen }: NewSpeechToTextRequestModalProps) => {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [audioDuration, setAudioDuration] = useState<number | null>(null);
    const [validationError, setValidationError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const logUserEvent = useLogUserEvent();

    const checkEnoughCredits = useCheckSpeechToTextCredits();

    // When user gives new inputs, clear validation error
    useEffect(() => {
      setValidationError('');
    }, [selectedLanguage, selectedFile]);

    const productPrices = useSelector(
      (state: StoreRootState) => state.product.productPrices,
    );

    const estimatedCreditsToUse = useMemo(() => {
      if (audioDuration && !validationError && !errorMessage) {
        return calculateCreditsToUseForSpeechToText(
          audioDuration,
          productPrices[ProductType.SPEECH_TO_TEXT]?.[ProductTier.BASIC] ?? 0,
        );
      }

      return null;
    }, [audioDuration, errorMessage, productPrices, validationError]);

    const creditsCheckResult = useMemo(() => {
      if (audioDuration && !validationError && !errorMessage) {
        return checkEnoughCredits(audioDuration ?? 0);
      }

      return CreditCheckResult.INVALID;
    }, [audioDuration, checkEnoughCredits, errorMessage, validationError]);

    const handleLanguageChange = useCallback(
      (_event: React.SyntheticEvent | null, newValue: string | null) => {
        setSelectedLanguage(newValue!);
      },
      [],
    );

    // const handleTitleChange = useCallback((event: { target: { value: React.SetStateAction<string>; }; }) => {
    //     setTitle(event.target.value);
    // }, []);

    // eslint-disable-next-line no-undef
    const handleFileChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
          const file = event.target.files[0];
          setSelectedFile(file);
        }
      },
      [],
    );

    useEffect(() => {
      if (selectedFile) {
        const audio = new Audio();
        audio.src = URL.createObjectURL(selectedFile);
        audio.addEventListener('loadedmetadata', () => {
          setAudioDuration(Math.floor(audio.duration * 1000));

          logUserEvent({
            event: UserEventTypes.SPEECH_TO_TEXT_REQUEST_AUDIO_UPLOADED,
            audioDuration: Math.floor(audio.duration * 1000),
          });
        });
      }
    }, [logUserEvent, selectedFile]);

    const handleModalClose = useCallback(
      (_event: unknown, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

        handleClose({});
      },
      [handleClose],
    );

    const handleSubmit = useCallback(async () => {
      let validationError = '';

      if (!selectedLanguage) {
        validationError = 'Please select the language in the audio';
      } else if (!selectedFile) {
        validationError = 'Please upload an file';
      } else if (creditsCheckResult === CreditCheckResult.NOT_ENOUGH_CREDITS) {
        validationError =
          "You don't have enough pay as you go credits or subscription minutes for this request. If you think this is an error, please refresh the page and try again.";
      }

      setErrorMessage('');
      setValidationError(validationError);

      if (validationError) {
        return;
      }

      setIsSubmitting(true);

      logUserEvent({
        event: UserEventTypes.SPEECH_TO_TEXT_REQUEST_SUBMIT,
        title: selectedFile?.name ?? '',
        language: selectedLanguage,
        audioDurationInMs: audioDuration ?? 0,
        estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
      });

      // Upload file to S3
      let audioS3Uri = '';
      try {
        const { objectKey } = await uploadFileToS3WithMultipart(
          selectedFile!,
          '/speech_recognition/audio_multipart_upload',
        );
        audioS3Uri = objectKey;
      } catch (e: any) {
        logClientError(
          'Audio Upload failed in NewSpeechToTextRequestModal: ',
          e,
        );
        setErrorMessage(e.toString());
        setIsSubmitting(false);

        logUserEvent({
          event: UserEventTypes.SPEECH_TO_TEXT_REQUEST_RESPONSE,
          status: 'failed_at_uploading_audio',
          error: e.toString(),
          title: selectedFile?.name ?? '',
          language: selectedLanguage,
          audioDurationInMs: audioDuration ?? 0,
          estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
        });
        return;
      }

      // Generate a request record in the database
      let requestCreated: null | SpeechToTextEntry = null;
      try {
        const response = await sendPostRequest({
          requestPath: '/speech_recognition/request',
          payload: {
            title: selectedFile?.name ?? '',
            language: selectedLanguage,
            audioDurationInMs: audioDuration ?? 0,
            audioBucketURI: audioS3Uri,
          },
        });

        requestCreated = response as SpeechToTextEntry;
      } catch (e: any) {
        logClientError(
          'Failed to create request in NewSpeechToTextRequestModal: ',
          e,
        );
        setErrorMessage('Failed to create request: ' + e.toString());
        setIsSubmitting(false);

        logUserEvent({
          event: UserEventTypes.SPEECH_TO_TEXT_REQUEST_RESPONSE,
          status: 'failed_at_creating_request',
          error: e.toString(),
          title: selectedFile?.name ?? '',
          language: selectedLanguage,
          audioDurationInMs: audioDuration ?? 0,
          estimatedCreditsToUse: estimatedCreditsToUse ?? 0,
        });
        return;
      }

      setIsSubmitting(false);
      handleClose({ createdRequest: requestCreated });

      logUserEvent({
        event: UserEventTypes.SPEECH_TO_TEXT_REQUEST_RESPONSE,
        status: 'succeed',
        requestIdCreated: requestCreated?.id ?? '',
        title: selectedFile?.name ?? '',
        language: selectedLanguage,
        audioDurationInMs: audioDuration ?? 0,
      });
    }, [
      selectedLanguage,
      selectedFile,
      creditsCheckResult,
      logUserEvent,
      audioDuration,
      estimatedCreditsToUse,
      handleClose,
    ]);

    useEffect(() => {
      // When the modal is opened, clear all states to start a new upload
      if (isOpen) {
        setSelectedLanguage('');
        setSelectedFile(null);
        setAudioDuration(null);
        setValidationError('');
        setErrorMessage('');
        setIsSubmitting(false);
      }
    }, [isOpen]);

    return (
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        disableEscapeKeyDown
      >
        <ModalDialog
          variant='outlined'
          role='alertdialog'
          sx={{
            width: {
              xs: '90%',
              sm: '70%',
              md: '55%',
              lg: '50%',
            },
            maxWidth: 'none',
            minWidth: '240px',
            position: 'absolute',
            top: '50%',
            left: {
              xs: '50%',
              md: '60%',
            },
          }}
        >
          <DialogTitle sx={{ alignSelf: 'center' }}>
            <Typography
              level='title-lg'
              sx={{ fontWeight: 'bold' }}
            >
              New Speech to Text Request
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ gap: 4, marginTop: 2 }}>
            <FormControl>
              <FormLabel htmlFor='language-select'>
                <Typography
                  level='title-md'
                  sx={{ fontWeight: 'bold' }}
                >
                  Main Language of Audio
                </Typography>
              </FormLabel>
              <Select
                id='language-select'
                value={selectedLanguage}
                onChange={handleLanguageChange}
                disabled={isSubmitting}
                sx={{ marginTop: 1 }}
              >
                {Object.entries(SPEECH_TO_TEXT_SUPPORTED_LANGUAGES).map(
                  ([key, name]) => (
                    <Option
                      key={key}
                      value={key}
                    >
                      {name}
                    </Option>
                  ),
                )}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <input
                accept='audio/mpeg, audio/wav, audio/flac'
                type='file'
                hidden
                id='file-upload'
                onChange={handleFileChange}
                disabled={isSubmitting}
              />
              <label htmlFor='file-upload'>
                <Button
                  component='span'
                  color='neutral'
                  sx={{
                    whiteSpace: 'nowrap', // Prevents the text from wrapping
                    overflow: 'hidden', // Hides text that overflows the element's box
                    textOverflow: 'ellipsis', // Adds an ellipsis when the text overflows
                  }}
                >
                  Upload Audio
                </Button>
              </label>
              {selectedFile && (
                <Typography
                  level='title-sm'
                  color='neutral'
                >
                  {selectedFile.name}
                </Typography>
              )}
            </Box>

            {validationError && (
              <Typography
                level='body-sm'
                color='danger'
              >
                {validationError}
              </Typography>
            )}

            {errorMessage && (
              <Typography
                level='body-sm'
                color='danger'
              >
                {errorMessage}
              </Typography>
            )}

            {estimatedCreditsToUse && (
              <PricePreviewBox
                totalCredits={
                  creditsCheckResult !==
                  CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS
                    ? estimatedCreditsToUse
                    : undefined
                }
                description={
                  creditsCheckResult ===
                  CreditCheckResult.ENOUGH_SUBSCRIPTION_CREDITS
                    ? `${Math.ceil(
                        (audioDuration ?? 0) / 60000.0,
                      )} minutes will be consumed from your Speech to Text subscription plan.`
                    : `Audio duration: ${Math.ceil(
                        (audioDuration ?? 0) / 60000.0,
                      )} minutes, Credits per minute: ${convertCreditsToUserText(
                        productPrices[ProductType.SPEECH_TO_TEXT]?.[
                          ProductTier.BASIC
                        ] ?? 0,
                      )}`
                }
              />
            )}

            {isSubmitting && (
              <Stack gap={1}>
                <LinearProgress />
                <Typography
                  level='body-sm'
                  color='warning'
                >
                  Upload could take minutes, please wait and do not close the
                  dialog.
                </Typography>
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose({});
                logUserEvent({
                  event: UserEventTypes.SPEECH_TO_TEXT_REQUEST_CANCELLED,
                  title: selectedFile?.name ?? '',
                  language: selectedLanguage,
                  audioDurationInMs: audioDuration ?? 0,
                });
              }}
              variant='outlined'
              color='neutral'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant='solid'
              color='primary'
              disabled={isSubmitting || validationError.trim().length > 0}
            >
              Submit
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  },
);

NewSpeechToTextRequestModal.displayName = 'NewSpeechToTextRequestModal';
export default NewSpeechToTextRequestModal;
