import { AudioTranscript } from '../types/AudioTranscript';

const parseSrtTimeStringToMs = (timeStr: string): number => {
  const [h, m, s, ms] = timeStr.split(/[:,]/).map(parseFloat);
  return h * 3600000 + m * 60000 + s * 1000 + ms;
};

function parseMsToSrtTimeString(ms: number) {
  const date = new Date(ms);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');

  return `${hours}:${minutes}:${seconds},${milliseconds}`;
}

const parseSRTContent = (srtContent: string): AudioTranscript[] => {
  const regex =
    /(\d+)\s+(\d{2}:\d{2}:\d{2},\d{3}) --> (\d{2}:\d{2}:\d{2},\d{3})\s+([\s\S]*?)(?=\n\n|\n\d|\n$)/g;
  const transcripts: AudioTranscript[] = [];
  let match;

  while ((match = regex.exec(srtContent)) !== null) {
    const startTime = parseSrtTimeStringToMs(match[2]);
    const endTime = parseSrtTimeStringToMs(match[3]);
    const text = match[4].replace(/\n/g, ' ').trim();
    transcripts.push({ startTime, endTime, text });
  }

  return transcripts;
};

export const fetchAndParseSrtFile = async (url: string): Promise<AudioTranscript[]> => {
  const response = await fetch(url);
  const srtContent = await response.text();

  return parseSRTContent(srtContent);
};

const convertTranscriptsArrayToSrtData = (transcripts: AudioTranscript[]) => {
  return transcripts
    .map((transcript: AudioTranscript, index: number) => {
      const startTime = parseMsToSrtTimeString(transcript.startTime);
      const endTime = parseMsToSrtTimeString(transcript.endTime);

      return `${index + 1}\n${startTime} --> ${endTime}\n${transcript.text}\n`;
    })
    .join('\n');
};

const convertTranscriptsArrayToTxtData = (transcripts: AudioTranscript[]) => {
  return transcripts.map((item) => item.text).join('\n\n');
};

const downloadBlobData = (blob: Blob, fileName: string) => {
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadTranscriptsArrayAsSrt = (transcripts: AudioTranscript[], fileName: string) => {
  const srtContent = convertTranscriptsArrayToSrtData(transcripts);
  const blob = new Blob([srtContent], { type: 'text/plain;charset=utf-8' });
  downloadBlobData(blob, fileName);
};

export const downloadTranscriptsArrayAsTxt = (transcripts: AudioTranscript[], fileName: string) => {
  const srtContent = convertTranscriptsArrayToTxtData(transcripts);
  const blob = new Blob([srtContent], { type: 'text/plain;charset=utf-8' });
  downloadBlobData(blob, fileName);
};
